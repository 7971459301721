@import "../base/_utils.pcss";

.footer {
  .footer-primary {
    background-color: var(--blackFooter);
    padding: 50px 40px;
    font-size: 14px;

    @media (--up-to-large-viewport) {
      position: relative;
      padding: 32px 40px;

      @media (--medium-and-small-viewport) {
        padding: 32px 16px 46px;
      }
    }
  }

  .footer-secondary {
    background-color: var(--blackFooter);
    padding: 14px;
    font-size: 12px;
    text-align: center;
    color: var(--text-white-transparent);

    .copyright {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.4px;
    }
  }

  .row {
    margin: 0 -15px;
  }

  .block {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .footer-primary .footer-logo {
    text-align: center;
    margin: 0;
    padding: 0 15px;


    svg {
      max-width: 100%;
    }

    @media (--up-to-large-viewport) {
      position: absolute;
      top: 40px;
      left: 0;
      max-width: none;
      
      svg {
        width: 105px;
      }
    }


    @media (--medium-and-small-viewport) {
      top: 25px;
    }
  }

  nav {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  ul.footer-nav {
    @media (--large-viewport) {
      display: flex;
      justify-content: flex-start !important;
      align-items: center;
      padding-right: 30px;
      margin-right: 0;

      &:last-of-type {
        padding-right: 0;
        padding-left: 30px;
        justify-content: end !important;
      }
      li {
        flex: 1;
        max-width: 33.3333%;
        margin: 0;
      }
    }

    @media (--up-to-large-viewport) {
      margin-top: 105px;
      flex: 0 0 50%;
      max-width: 50%;

      @media (--medium-and-small-viewport) {
        margin-top: 95px;
      }
    }

    @media (--normal-viewport) {
      align-items: center;
      margin-top: 92px;

      &:first-of-type {
        padding-right: 8px;
      }
      &:last-of-type {
        padding-left: 8px;
      }
    }

    li {
      @media (--medium-and-small-viewport) {
        margin-bottom: 24px;
      }

      @media (--normal-viewport) {
        flex: 1;
        max-width: none;
      }
    }

    .location-item {
      color: #fff;

      @media (--normal-and-large-viewport) {
        flex: 0 0 180px;
        max-width: 52%;
      }

      @media (--normal-viewport) {
        .location-switch {
          padding: 16px 49px 16px 16px;

          .icon {
            right: 16px;
          }
        }
      }
    }
    .location-switch {
      position: relative;
      display: inline-block;
      padding-right: 33px;
      -webkit-tap-highlight-color: transparent;

      a, .icon {
        color: inherit;
        transition: color 0.2s ease-in-out;
      }

      &:hover {
        a, .icon {
          color: color(var(--white) lightness(-50%));
        }
      }
    }
  }

  nav {
    ul,
    li {
      @mixin reset-spacing;
      list-style: none;
    }
  }

  a {
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;

    &:link, &:visited {
      color: var(--white);
      text-decoration: none;
    }

    &:focus {
      outline-offset: 5px;
    }

    &:hover {
      transition: all 0.25s;
      color: color(var(--white) lightness(-50%));
    }
  }

  .footer-primary .location-list__body {
    width: 100%;
    margin: 0;

    li {
      a {
        color: var(--white);
        transition: all 0.25s;

        &:hover {
          color: color(var(--white) lightness(-50%));
        }
      }
    }
  }

  .footer-primary .location-list__header {
    .close .icon {
      top: 50%;
      color: var(--white);
    }
    .close:hover .icon {
      color: color(var(--white) lightness(-50%));
    }
  }

  .emission-block {
    display: flex;
    position: relative;
    color: rgba(255,255,255,.8);
    padding: 40px 0;
    margin-top: 30px;
    margin-bottom: -40px;
    font-size: 10px;
    line-height: 20px;
    border-top: 1px solid rgba(248, 247, 244, 0.2);
    border-bottom: 1px solid rgba(248, 247, 244, 0.2);

    h4 {
      font-size: 14px;
      font-style: italic;
      text-transform: none;
      line-height: 22px;
      letter-spacing: initial;

      @media (--medium-and-small-viewport) {
        margin-bottom: 35px;
      }
    }

    & > div {
      margin: 0;
      padding: 0 15px;
    }

    &__title {
      flex: 0 0 100%;
      max-width: 100%;

      @media (--normal-and-large-viewport) {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
    }

    &__body {
      transform: translate(0);
      column-count: 2;
      grid-column-gap: 20px;
      column-gap: 20px;

      flex: 0 0 100%;
      max-width: 100%;


      p {
        margin-bottom: 35px;
      }

      @media (--normal-and-large-viewport) {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      
      @media (--medium-and-small-viewport) {
        column-count: 1;
      }
    }
  }

    &.footer--location-active {
      .emission-block {
        display: none;
      }
    }

}

body.has-enquire-button {
  @media (--medium-and-small-viewport) {
    .footer .footer-secondary {
      padding-bottom: 74px;
    }
  }
}
