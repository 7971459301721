:root {
  /* General sizes */
  --baseSize: 20px;
  --gridGutter: 20px;
  --gridGutterMobile: 10px;
  --pillarBox: 1024px;

  /* Layout sizes, settings */
  --headerHeight: 65px;
  --footerHeight: 204px;
  --zHigh: 1000;

  /* lockups */
  --lockup-s-l-62: 11px;
  --lockup-s-l-50: 16px;
  --lockup-s-p-50: 28px;
  --lockup-m-p-50: 26px;
  --lockup-l-p-50: 24px;
  --lockup-l-p-55: 30px;
  --lockup-l-p-83: 58px;
  --lockup-p-s-83: 56px;
  --lockup-q-p-83: 62px;
  --lockup-s-xl-74: 62px;
  --lockup-xl-p-38: 15px;
}
