.reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  top: auto;
  left: -10000px;
  overflow: hidden;

  html[dir='rtl'] & {
    left: auto;
    right: -10000px;
  }
}
