.engine-stats {
  padding-top: 40px;
  padding-bottom: 120px;
  font-family: Optima;
  background-color: var(--darkGrey);
  transition: .3s;

  @media (--large-viewport) {
    padding-top: 210px;
    padding-bottom: 200px;
  }

  &__subhead {
    display: block;
    margin-bottom: 10px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.85rem;
    color: var(--offWhite);

    @media (--large-viewport) {
      margin-bottom: 32px;
    }

    &-star {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      padding: 5px 6px;
      line-height: 1rem;
      border-radius: 50%;
      background-color: var(--black);
      transform: translateY(-5px);
    }
  }

  &__content {
    margin-bottom: 60px;
    font-size: 1.375rem;
    line-height: 2rem;
    color: var(--offWhite);

    @media (--large-viewport) {
      font-size: 2.25rem;
      line-height: 3.15rem;
    }
  }

  &__num {
    font-size: 3.625rem;
    line-height: 3.625rem;
    color: var(--offWhite);
    transition: .3s;

    @media (--large-viewport) {
      font-size: 5rem;
      line-height: 5rem;
    }

    &-cont {
      padding-bottom: 65px;
      padding-left: 40px;
      border-left: 1px solid rgba(248, 247, 244, .2);

      @media (--normal-and-large-viewport) {
        padding: 10px 0 0 30px;
        margin-left: -30px;
      }
    }

    &-title {
      margin-bottom: 10px;
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
      line-height: 1rem;
      color: var(--offWhite);
    }

    &-unit {
      font-weight: normal;
      color: rgba(249, 249, 249, .5);
    }
  }

  &__cta {
    display: inline-flex;
    padding: 20px 44px;
    margin-top: 60px;
    text-decoration: none;
    background: rgba(248, 247, 244, .05);
    border-left: 1px solid var(--offWhite);

    @media (--large-viewport) {
      display: flex;
      max-width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
    }

    &-cont {
      @media (--large-viewport) {
        display: flex;
        align-items: flex-end;
        height: 100%;
      }
    }

    &-icon {
      display: block;
      width: 12px;
      height: 12px;
      margin-left: 10px;
      order: 2;

      @media (--large-viewport) {
        display: inline-block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-left: 0;
        margin-right: 12px;
        border-radius: 50%;
        background-color: var(--white10);
        order: 0;
      }

      & svg {
        width: 12px;
        height: 12px;
        fill: var(--white);

        @media (--large-viewport) {
          margin: 6px;
        }
      }
    }

    &-txt {
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 1rem;
      font-weight: 600;
      color: var(--white);

      @media (--large-viewport) {
        display: flex;
        align-items: center;
        line-height: 100%;
      }
    }
  }

  &--hidden &__num {
    opacity: 0;
  }
}

.engine-stats__numbers > div {
  &:first-child .engine-stats__num-cont {
    @media (--normal-and-large-viewport) {
      border: none;
    }
  }

  &:last-child .engine-stats__num-cont {
    height: calc(100% - 7px);
    padding-bottom: 0;

    @media (--normal-and-large-viewport) {
      height: auto;
    }
  }
}
