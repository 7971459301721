:root {
  --transition: 0.2s;
}

.icon-link {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0;
  margin: 18px 0;
  font-family: Optima; /* font-family: GTAmerica; */
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  background: transparent;

  &__icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: var(--black);
    transition: var(--transition);

    & svg {
      width: 12px;
      height: 12px;
      margin: 6px;
      transition: var(--transition);
      fill: var(--white);
    }
  }

  &:hover &__icon {
    background-color: rgba(22, 26, 33, 0.2);

    & svg {
      fill: var(--black);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    line-height: 100%;
  }

  &--gray {
    color: var(--offWhite);

    &:hover {
      color: var(--offWhite);
    }
  }

  &--gray &__icon {
    background: var(--white10);
  }

  &--gray:hover &__icon {
    background: var(--white10);

    & svg {
      fill: var(--offWhite);
    }
  }

  &.hidden {
    display: none;
  }

}

.icon-link-right {
  display: inline-block;
  &__box {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    font-family: Optima; /* font-family: GTAmerica; */
  }
  &__icon {
    width: 14px;
    height: 14px;
    line-height: 1.5;
    margin-left: 6.85px;

    & svg {
      height: 100%;
    }
  }

  &--light {
    color: var(--offWhite);
  }

  &--light &__icon svg {
    fill: var(--white);
  }

  &--dark {
    color: var(--darkGrey);
  }

  &--dark &__icon svg {
    fill: var(--darkGrey);
  }
}
