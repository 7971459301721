.news-list-block {
  color: var(--darkGrey);
  padding: 60px 0;

  @media (--medium-and-small-viewport) {
    padding: 40px 0;
  }
  
  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.8;
    margin-bottom: 16px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 8px;
    }
  }
  &__heading {
    font-size: 80px;
    line-height: 1;
    letter-spacing: 0.4px;
    margin-bottom: 56px;
    @media (--medium-and-small-viewport) {
      font-size: 36px;
      line-height: 1.2;
      letter-spacing: 0.4px;
      margin-bottom: 32px;
    }
  }

  .news-item {
    margin-bottom: 55px;
    @media (--large-viewport) {
      margin-bottom: 120px;
    }

    &__link {
      display: block;
      height: 77vw;
      margin-bottom: 20px;
      background: var(--darkGrey05);
      @media (--normal-and-large-viewport) {
        height: 20vw;
        margin-bottom: 40px;
      }
      @media (--large-viewport) {
        height: 20vw;
        margin-bottom: 32px;
      }
    }
    &__img-box {
      display: block;
      height: 100%;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__heading {
      display: block;
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 26px;
      color: inherit;
      @media (--large-viewport) {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 24px;
      }
    }
    &__read-more {
      display: inline-block;
      .read-more {
        &-content {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--darkGrey);
          font-size: 14px;
          line-height: 18px;
        }
        &-icon {
          width: 14px;
          height: 14px;
          display: inline-block;
          margin-left: 8px;
          & svg {
            height: 100%;
            fill: var(--darkGrey);
          }
        }
      }
    }
    &__date {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.86;
      margin-bottom: 9px;
      @media (--medium-and-small-viewport) {
        margin-bottom: 8px;
      }
    }

    &.hero {
      .news-item__link {
        height: 77vw;
        @media (--normal-and-large-viewport) {
          height: 50vw;
          margin-bottom: 40px;
        }
      }
      .news-item__heading {
        font-size: 36px;
        line-height: 1.8;
        @media (--medium-and-small-viewport) {
          font-size: 22px;
          line-height: 1.8;
        }
      }
    }

  }

  .placeholder {
    .news-item__link, 
    .news-item__date,
    .news-item__heading,
    .news-item__read-more {
      background: var(--darkGrey05);
    }
    .news-item__date {
      width: 25%;
    }
    .news-item__read-more {
      width: 40%;
    }
  }

  .preloader {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

    .spinner {
      opacity: 0.5;
      width: 60px;
      height: 60px;
      animation: spin 1s linear infinite;
    }
  }


}



