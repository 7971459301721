.model-configurator {
  padding-top: 80px;
  padding-bottom: 80px;
  color: var(--offWhite1);
  background: radial-gradient(at 55% 72%,#9fa2a9,#212325);

  @media (--large-viewport) {
    position: relative;
    background: radial-gradient(at 70% 54%,#9fa2a9,#212325);
    background-size: 100% 100%;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 160px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        var(--darkGrey) 100%
      );
    }
  }

  &__cont {
    position: relative;

    @media (--large-viewport) {
      margin-top: 50px;
    }

    &-column {
      @media (--large-viewport) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.875rem;

    @media (--large-viewport) {
      margin-bottom: 32px;
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: 600;
      line-height: 1.875rem;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 1.75rem;
    line-height: 2rem;

    @media (--large-viewport) {
      margin-bottom: 20px;
      font-size: 2.25rem;
      line-height: 3.125rem;
      letter-spacing: .4px;
    }
  }

  &__btn {
    padding: 0;
    opacity: .5;
    font-family: Optima;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.875rem;
    color: var(--offWhite1);
    cursor: pointer;
    border: none;
    background: transparent;
    transition: .3s;

    &.hidden {
      display: none;
    }

    &.active {
      opacity: 1;
    }

    @media (--up-to-large-viewport) {
      flex: 0 0 auto;
      height: 1.875rem;
      margin-right: 30px;
    }

    @media (--large-viewport) {
      display: block;
      margin-bottom: 12px;
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 2.5rem;
    }

    &-cont {
      position: relative;

      @media (--up-to-large-viewport) {
        padding-top: 25px;
        padding-bottom: 35px;
      }

      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        margin-left: -16px;
      }

      @media (--normal-viewport) {
        width: calc(100% + 80px);
        margin-left: -40px;
      }

      @media (--large-viewport) {
        background-image: none;
      }
    }
  }

  &__scroller {

    &-cont {
      transition: .3s;
    }

    @media (--up-to-large-viewport) {
      display: flex;
      flex-wrap: nowrap;
      height: 300%;
      overflow: auto;

      &:after {
        content: '';
        min-width: 1px;
      }

      &-cont {
        position: relative;
        height: 1.875rem;
        overflow: hidden;
      }
    }

    @media (--medium-and-small-viewport) {
      padding-left: 16px;
    }

    @media (--normal-viewport) {
      padding-left: 40px;
    }
  }

  &--hidden &__scroller-cont {
    opacity: 0;
  }

  &__picture {
    position: absolute;
    height: 100%;
    top: 0;
    opacity: 0;
    transition: .6s;

    &.active {
      opacity: 1;
    }

    @media (--medium-and-small-viewport) {
      width: calc(100% - 32px);
      left: 16px;
    }

    @media (--normal-viewport) {
      width: calc(100% - 80px);
      left: 40px;
    }

    @media (--large-viewport) {
      overflow: visible;
      width: 100%;
      left: 0;
    }

    &-cont {
      position: relative;
      height: 0;
      padding-top: 50%;

      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        margin-left: -16px;
      }

      @media (--normal-viewport) {
        width: calc(100% + 80px);
        margin-left: -40px;
      }

      @media (--large-viewport) {
        top: 50%;
        transform: translateY(-50%);
        background-image: none;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 10px 5px rgba(0, 0, 0, .3));
    object-fit: contain;
  }

  &__point-cta {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    border: none;
    background: transparent;

    @media (--large-viewport) {
      left: 65%;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    &-icon {
      display: block;
      width: 56px;
      height: 56px;
      padding: 21px;
      margin-right: 15px;
      border-radius: 50%;
      background-color: var(--black10);
      backdrop-filter: blur(20px);
      transition: background 0.2s;

      & svg {
        width: 100%;
        height: auto;
        fill: var(--offWhite);
      }
    }

    &:hover &-icon, &:focus &-icon {
      background-color: var(--black20);
    }

    &-txt {
      font-family: Optima;
      font-size: 14px;
      line-height: 20px;
      color: var(--offWhite);

      @media (--large-viewport) {
        font-family: Optima; /* font-family: GTAmerica; */
        font-weight: 700;
      }
    }

    &.interior {
      top: -10%;
      left: 45%;

      @media (--normal-viewport) {
        top: 0%
      }

      @media (--large-viewport) {
        top: 0%;
        left: 65%;
      }
    }

    &.createYourOwn {
      top: 80%;
      left: 5%;

      @media (--large-viewport) {
        top: 21%;
        /* bottom: initial; */
      }

      .model-configurator__point-cta-icon {
        margin-left: 15px;
        margin-right: 0;
        background-color: var(--black70);
      }

      &:hover .model-configurator__point-cta-icon, &:focus .model-configurator__point-cta-icon {
        background-color: var(--black90);
      }
    }

    &.specification {
      bottom: -5%;
      right: 5%;
    }

  }

  & .no-outline {
    outline: 0;
  }
}
