.model-carousel {
  height: 50vh;
  transition: .3s;

  @media (--normal-and-large-viewport) {
    height: 90vh;
  }

  &__slide {
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: var(--darkGrey);

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .4;
      background-color: var(--black);
      transition: .3s;
    }

    &--active::after,
    &:hover::after {
      opacity: 0;
      transition: .3s;
    }
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .6s;

    &.active {
      opacity: 1;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
