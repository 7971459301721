
.touch nav.primary .mobile-search .input-search {
  font-size: 2.666rem;
}

nav.primary {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 75px 20px 0;
  z-index: 1000;
  opacity: 0;
  color: inherit;
  pointer-events: none;
  background: var(--black-pearl);
  transition: transform .3s .25s cubic-bezier(0, .7, .3, 1), opacity .3s 0s linear;  
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  @media (--nav-desktop-min-width) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: var(--headerHeight);
    padding: 0 20px;
    overflow: hidden;
    background: none;
  }

  @media (min-width: 1300px) {
    padding: 0 40px;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
    transition: transform .3s .25s cubic-bezier(0, .7, .3, 1), opacity .3s .35s linear;

    & > div,
    ul,
    li {
      pointer-events: none;
    }
  }

  > .parallax-container {
    width: 100%;
    overflow: visible;

    @media (--nav-desktop-min-width) {
      display: flex;
    }
  }

  &::before {
    position: fixed;
    content: '';
    display: none;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background: linear-gradient(to bottom, rgba(22, 23, 24, 1) 60%, rgba(22, 23, 24, 0) 100%);
  }

  .left-menu,
  .right-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    color: inherit;
    transition: all .5s cubic-bezier(0, .7, .3, 1), opacity .4s linear, color 0s 0s;
    -webkit-tap-highlight-color: color(var(--black-pearl) a(0%));
    touch-action: manipulation;
    -webkit-touch-callout: none;

    &.right-menu {
      justify-content: flex-end;
    }

    @media (--nav-desktop-min-width) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &.hidden {
      display: none;
    }

  }

  ul {
    position: relative;
    display: block;
    flex-direction: column;
    width: 100%;
    top: 0;
    padding: 0;
    margin: 0;
    opacity: 0;
    color: inherit;
    list-style: none;
    transform: translateX(0);
    transition: all .4s cubic-bezier(.7, 0, 1, .3), opacity .4s linear, color 0s 0s;

    @media (--nav-desktop-min-width) {
      display: flex;
      flex-direction: row;
      width: auto;
      transform: translateX(-60px);

      li a:hover::after {
        display: block;
      }
    }

    > li {
      max-height: 140px;
      margin: 0;
      color: inherit;
      transform: translateY(0);
      transition: transform .5s cubic-bezier(0, .7, .3, 1), max-height .5s cubic-bezier(0, .7, .3, 1);
      will-change: transform, height;

      &.search {
        a.close-sub-nav {
          display: none;
          }
        }
        
      @media (--nav-tablet-max-width) {
        &.search {
          a.close-sub-nav {
            display: none;
            font-size: 1.333rem;
            text-transform: uppercase;
            padding: 0 0 0 24px;
            line-height: 24px;
            margin-bottom: 10px;

            &:before {
              display: none;
            }

          }
        }
      }



      &.active {
        a {          

          svg {
            opacity: 0.2;
          }

          @media (--nav-desktop-min-width) {
            color: rgba(255, 255, 255, .2);
          }

          &::before {
            @media (--nav-desktop-min-width) {
              opacity: 1;
              transition: opacity .2s .2s;
            }
          }

          &::after {
            @media (--nav-desktop-min-width) {
              opacity: 0 !important;
              transition: opacity .2s;
            }
          }
        }
      }

      a {
        position: relative;
        display: inline-block;
        padding: 10px 4px;
        font-size: 32px;
        line-height: normal;
        text-decoration: none;
        color: var(--white);
        letter-spacing: .06em;
        cursor: pointer;
        transition: color .2s, opacity .3s;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (min-width: 1300px) {
          font-size: 16px;
        }

        @media (--nav-desktop-min-width) {
          font-size: 13px;
          text-transform: uppercase;
          white-space: nowrap;
          pointer-events: all;
        }

        @media (--nav-tablet-max-width) {
          letter-spacing: .0325rem;
          text-transform: none;
          padding: 16px 0;
        }

        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 100%;
          left: -1px;
          margin-top: 2px;
          opacity: 0;
          transition: opacity .2s .2s;
          border-bottom: 2px solid rgba(255, 255, 255, .4);
        }

        &::before {
          position: absolute;
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          top: 50%;
          left: calc(50% - 12px);
          opacity: 0;
          background-repeat: no-repeat;
          background-size: contain;
          transform: translateY(-50%);
          transition: opacity .3s;

          .expose_nav & {
            background-color: initial;
            background-image: url('../images/nav/close-icon.svg');
          }
        }

        &:hover::after {
          @media (pointer: fine) {
            opacity: 1;
            transition: opacity 0.2s;
          }
          @media (--nav-desktop-min-width) {
            opacity: 1;
          }
        }

        &.search-icon {
          position: relative;
          display: none;
          width: 28px;
          top: 8px;
          padding-top: 18px;
          margin-bottom: -.5em;

          @media (min-width: 1300px) {
            top: 12px;
            padding-top: 18px;
          }

          &:before {
            top: 7px;
            left: -1px;
          }

          &:after {
            width: 28px;
          }

          @media (--nav-desktop-min-width) {
            display: block;
            top: 10px;
            padding-top: 16px;
          }

          svg {
            position: absolute;
            top: -2px;

            path,
            ellipse {
              stroke: var(--white);
            }
          }
        }

        .header--dark:not(.left_nav):not(.right_nav)  & {
          @media (--nav-desktop-min-width) {
            color: var(--black);
            &::after {
              border-bottom: 2px solid rgba(0, 0, 0, .4);
            }
          }
        }

        .header--dark:not(.expose_nav)  & {
          @media (--nav-tablet-max-width) {
            color: var(--black);
          }
        }

      }
    }

    &.left {
      margin: 0;

      li {
        @media (--nav-desktop-min-width) {
          margin: 0 8px 0 0;
        }
        @media (min-width: 1300px) {
          margin: 0 16px 0 0;
        }
      }
    }

    &.right {
      transform: translateX(0);

      @media (--nav-desktop-min-width) {
        transform: translateX(60px);

        li {
          margin: 0 0 0 8px;
        }
      }
      @media (min-width: 1300px) {
        li {
          margin: 0 0 0 16px;
        }
      }
    }
  }

  .mobile-search {
    @media (--nav-desktop-min-width) {
      display: none;
    }
  }

  .search__form {
      padding: 9px 0;
  }

  .input-search {
    flex: 1;
    padding: 0 30px 0 0;
    overflow: hidden;
    font-size: 2.666rem;
    text-transform: none;
    text-rendering: geometricPrecision;
    color: var(--white);
    width: 100%;

    &::placeholder {
      color: var(--white);
    }

    .touch &,
    .touch &::placeholder {
      font-size: 2.666rem;
      padding-top: 0;
    }
  }

  &.searching {

    @media (--nav-tablet-max-width) {
      li:not(.search, .result) {
        transform: translateY(-50px);
        max-height: 0px;
      }

      li a:not(.search, .result, .close-sub-nav) {
        opacity: 0;
        pointer-events: none;
      }

      .search a.close-sub-nav {
        display: inline-block;
        opacity: 1;

        &:hover::after {
          display: none;
        }
      }

      .search__form {
        padding: 9px 0;
      }

      .search__results {
        ul {
          float: none;
          margin-bottom: 20px;

          li {
            float: none;
            max-height: none;

            a {
              font-size: 24px;
              padding: 0;
            }

          }

          li.search {
            a.close-sub-nav {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

body.left_nav {
  @media (--nav-desktop-min-width) {
    nav.primary {
      ul.right:not(:hover) {
        opacity: 0;
        transform: translateX(60px);
        transition: all .4s cubic-bezier(.7, 0, 1, .3), opacity 0.4s linear;

        li a {
          pointer-events: none;
        }
      }
    }

    header.right+nav.primary {
      ul.right {
        transform: translateX(0);
        opacity: 1;
        transition: transform .3s 0.25s cubic-bezier(0, .7, .3, 1), opacity 0.3s 0.25s linear;

        li a {
          pointer-events: all;
        }
      }
    }
  }
}

body.right_nav {
  @media (--nav-desktop-min-width) {
    nav.primary {
      ul.left:not(:hover) {
        opacity: 0;
        transform: translateX(-60px);
        transition: all .4s cubic-bezier(.7, 0, 1, .3), opacity 0.4s linear;
      }

    }

    header.left + nav.primary {
      ul.left {
        transform: translateX(0);
        opacity: 1;
        transition: transform .3s 0.25s cubic-bezier(0, .7, .3, 1), opacity 0.3s 0.25s linear;

        li a {
          pointer-events: all;
        }
      }
    }
  }
}

body.expose_nav nav.primary {
  display: flex;
  opacity: 1;
  pointer-events: all;

  &:not(.hidden) {
    @media (--nav-tablet-max-width) {
      overflow-y: scroll;

      &::before {
        display: block;
        opacity: 1;
      }
    }
  }

  ul {
    transform: translateX(0);
    opacity: 1;
    transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity 0.3s 0.2s linear;

    @media (--nav-tablet-max-width) {
      display: block;
      float: left;
      transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity 0.5s linear;
    }

  }

  &.hidden {
    @media (--nav-tablet-max-width) {
      opacity: 0;
      pointer-events: none;

      ul {
        transform: translateX(-50%);
        transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity 0.25s linear;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.modelBlackText nav.primary {
  @media (--nav-tablet-max-width) {
    ul li {
      a {
        color: var(--black-pearl);
        
        &::after {
          border-color: rgba(22, 23, 24, .4);
        }

        &.search-icon svg {
          ellipse,
          path {
            stroke: var(--black-pearl);
          }
        }
      }

      &.active a {
        color: rgba(22, 23, 24, .4);

        &::before {
          background-repeat: no-repeat;

          .expose_nav & {
            background-color: initial;
            background-image: url('../images/nav/close-icon-black.svg');
          }
        }
      }
    }
  }
}

a.close-sub-nav {
  position: relative;
  display: none;
  padding-left: 26px;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
  letter-spacing: .06em;

  svg {
    position: absolute;
    display: block;
    width: 20px;
    top: 0;
    left: -4px;
  }

  @media (--nav-tablet-max-width) {
    display: none;
    z-index: 9;
    pointer-events: all;
  }
}

.sub-nav.active a.close-sub-nav {
  @media (--nav-tablet-max-width) {
    display: inline-block;
  }
}