
@media (min-width: 1140px) {
    .sub-navigation > nav ul a .sub-nav-title {
        font-size: 46.5px;
    }
}

a.close-sub-nav {
    font-size: 16px;
}
.sub-navigation .models .active-model {
    text-decoration: none;

    & .active-model__name {
        font-size: 24px;
    }
}
.sub-navigation .models .models-controls .models-indicators button {
    margin: 0;
    border: 0;
}
.sub-navigation .models .model-view-link {
    font-size: 12px;
    text-decoration: none;
}
.sub-navigation .models .models-controls .models-indicators button::before {
    box-sizing: content-box;
}
.sub-navigation>nav ul a .description {
    position: absolute;
    width: calc(100% - 40px);
    z-index: var(--zi-header-sub-nav-desc);
    opacity: 0;
    font-size: 26px;
    line-height: 38px;
    pointer-events: none;
    transition: opacity .25s;
}
.sub-navigation .models nav.model-nav ul li a {
    line-height: 1;
}