html {
  text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  font: inherit;
  font-size: 100%;
  border: 0;
  box-sizing: border-box;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

a {
  background-color: transparent;
  text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

ol,
ul {
  list-style: none;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  border: 0;
  appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
  box-sizing: border-box;
}

[type='search'] {
  appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  appearance: button;
}

[hidden] {
  display: none;
}

[data-trigger='click'] {
  cursor: pointer;
}
