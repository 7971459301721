:root {
  --images-opacity: 0.6;
  --complementary-images-opacity: 0.4; /* calc(1 - var(--images-opacity)) -> calc not working with decimals in IE */
}

@define-mixin reset-spacing {
  margin: 0;
  padding: 0;
}

.darken-img::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: var(--complementary-images-opacity);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--zi-darken-img-pseudo);
}

.opacity-img {
  opacity: var(--images-opacity);
}

html.firefox {
  body.expose_nav {
    overflow: auto;
  }
}

.cookies {
  .row--centred {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pillarbox {
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12));

    @media (--medium-and-small-viewport){
      padding: 0 calc(99.9% / 12 - (10px - 10px / 12));
    }

  }

  .text--center {
    text-align: center;
  }

  .col--6-12 {
    max-width: calc(100% * 6/12 - (20px - 20px * 6/12));
    width: calc(100% * 6/12 - (20px - 20px * 6/12));

    @media (--medium-and-small-viewport){
      max-width: calc(100% * 1/1 - (10px - 10px * 1/1));
      width: calc(100% * 1/1 - (10px - 10px * 1/1));
    }
  }

  .cookies__cta {
    .icon {
      width: 12px;
      height: 12px;
    }
  }
}
