
:root {
  --overlayDelay: .3s;
  --overlayZi: 1003;
}

body.ol-open {
    overflow: hidden!important;
}

.scrollbar-measure {
  position: absolute;
  width: 100px;
  height: 100px;
  overflow: scroll;
  transform: translateX(-100vw);
}

[ol-container] {
  display: none;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  transition: opacity .375s var(--ease-in-sine);
  opacity: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: var(--overlayZi);
  @util position(fixed, 0, null, null, 0);
  will-change: opacity;

  .ie & {
    background: rgba(255, 255, 255, 0.9);
  }
  .firefox & {
    background: rgba(255, 255, 255, 0.9);
  }

  .ol-open & {
    display: block;
  }

  &.active {
    opacity: 1;
  }

  @media (--normal-and-large-viewport) {
    height: 100vh;
  }

  .outer {
    height: 100%;
    overflow-y: auto;
    transform: translate3d(100%, 0, 0);
    transition: transform .375s var(--ease-in-sine);
    will-change: transform;

    @media (--normal-and-large-viewport) {
      height: 100vh;
      overflow-y: hidden;
    }

    .ie & {
      transform: translateX(-100%);
    }

    @media (--normal-viewport) {
      transition-duration: .225s;
    }

    @media (--small-viewport) {
      transition-duration: .185s;
    }
  }

  &.active {
    opacity: 1;

    .outer {
      transform: translate3d(0, 0, 0);
      transition: transform .375s var(--ease-out-sine);

      .ie & {
        transform: translateX(0);
      }
      @media (--normal-viewport) {
        transition-duration: .275s;
      }
      @media (--small-viewport) {
        transition-duration: .225s;
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: row;
  }

  .pane {
    position: absolute;
    bottom: 5%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 5;

    &.mobile {
      display: flex;
    }

    @media (--normal-and-large-viewport) {
      display: flex;
      position: static;
      width: 136px;

      &.mobile {
        display: none;
      }
    }
  }
  
  .close-icon {
    cursor: pointer;
    display: block;
    width: 48px;
    height: 48px;
    padding: 18px 18px;
    border-radius: 50%;
    backdrop-filter: blur(20px);
    background-color: var(--black10);

    & svg {
      display: block;
      width: 100%;
      height: auto;
      fill: var(--darkGrey);
    }

    @media (--normal-and-large-viewport) {
      width: 56px;
      height: 56px;
      padding: 24px 24px;
    }

    &:hover {
      background-color: var(--black20);
    }

    &:focus {
      background-color: var(--black20);
      outline: var(--white50) dotted 1px;
      outline-offset: 5px;
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
    background: var(--offWhite);

    .touch & {
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
    }

    @media (--normal-and-large-viewport) {
      height: 100vh;
    }
  }

  &.var-dark {
    background: var(--black10);
    .close-icon {
      background-color: var(--darkGrey60);
      svg {
        fill: var(--offWhite);
      }
      &:hover, &:focus {
        background-color: var(--darkGrey70);
      }
    }
    .content {
      background-color: var(--darkGrey);
    }
  }

  .ie &.var-dark {
      background: var(--black90);
  }
  .firefox &.var-dark {
      background: var(--black90);
  }

  .preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .spinner {
      width: 60px;
      height: 60px;
      animation:spin 1s linear infinite;
    }
  }

}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
