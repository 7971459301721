
.header__logo {
  z-index: 1002;
  pointer-events: all;
}

.header {
  z-index: 1002;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  transition: background .25s linear;

  .header-nav, .js-mobile-menu {
    pointer-events: all;
  }

  svg.hamburger {
    width: 71px;
    height: 17px;

    rect {
      transition: transform .4s .3s cubic-bezier(0, .8, .2, 1);
      @media (--nav-tablet-max-width) {
        transition: all .3s ease-out;
      }
    }
  }

  &.disable {

    &:not(.header--subnavigation-active) {
      height: var(--headerHeight);
      transform: translateY(-65px);

      .header__logo {
        top: 50%;
        z-index: 1002;
        opacity: 1;
        pointer-events: all;
      }
    }

    & + nav.primary {
      height: 0;
      pointer-events: none;

      .parallax-container {
        opacity: 0;
      }
    }

    &:not(.header--subnavigation-active) {
      & + nav.primary {
        height: var(--headerHeight);
        
        .parallax-container {
          transform: translateY(-65px);
        }
      }
    }
  }
}

.left-toggle,
.right-toggle {

  > .icon {
    position: absolute;
    width: 71px;
    height: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--white);

    .header--dark & {
      color: var(--black);
    }

    .expose_nav .header--dark & {
      color: white;
    }

  }

  @media (--nav-desktop-min-width) {
    width: 50%;
    height: var(--headerHeight);
    float: left;
  }

  &.hidden {
    display: none;
  }

}

.left-toggle {

  > .icon {
    left: 20px;

    @media (min-width: 1300px) {
      left: 40px;
    }

    svg {
      @media (--nav-tablet-max-width) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.right-toggle > .icon {
  right: 20px;

  @media (min-width: 1300px) {
    right: 40px;
  }

  svg {
    cursor: pointer;
    transform: rotate(180deg);
    @media (--nav-tablet-max-width) {
      pointer-events: all;
    }

  }
}

html,
body {

  &.expose_nav {
    @media (--nav-tablet-max-width) {
      height: 100%;
      overflow: hidden;
    }
  }

  &.left_nav,
  &.right_nav {
    height: 100%;
    overflow: hidden;
  }
}

body.expose_nav header {

  svg.hamburger rect {
    @media (--nav-desktop-min-width) {
      opacity: 0;
      transition: all .25s cubic-bezier(.8, 0, 1, .2);

      &:nth-child(1) {
        transform: translate3d(0, 0, 0);
        transition-delay: .1s;
      }

      &:nth-child(2) {
        transform: translate3d(8px, 0, 0);
        transition-delay: .05s;
      }

      &:nth-child(3) {
        transform: translate3d(16px, 0, 0);
      }
    }

    @media (--nav-tablet-max-width) {
      height: 25px;
      opacity: 1;
      transition: all .3s ease-out;

      &:nth-child(1) {
        transform: translate3d(1px, 1px, 0) rotate(-45deg);
        transform-origin: top left;
        transition-delay: 0s;
      }

      &:nth-child(2) {
        height: 0;
        transform: translate3d(0, 0, 0);
        transition-delay: 0s;
      }

      &:nth-child(3) {
        transform: translate3d(4px, -13px, 0) rotate(45deg);
        transform-origin: top left;
        transition-delay: 0s;
      }
    }
  }
}

body.left_nav {
  @media (--nav-desktop-min-width) {
    header {
      svg.hamburger {
        rect {
          transition: all .4s .3s cubic-bezier(0, .8, .2, 1);

          @media (--nav-tablet-max-width) {
            transition: all .3s ease-out;
          }
        }
      }

      svg.hamburger.right {
        rect {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

body.right_nav {
  @media (--nav-desktop-min-width) {
    header {
      svg.hamburger {
        rect {
          transition: all .4s .3s cubic-bezier(0, .8, .2, 1);

          @media (--nav-tablet-max-width) {
            transition: all .3s ease-out;
          }
        }
      }

      svg.hamburger.left {
        rect {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

body header.left {
  svg.hamburger.left {
    rect {
      @media (--nav-desktop-min-width) {
        opacity: 0;
        transition: all .25s cubic-bezier(.8, 0, 1, .2);

        &:nth-child(1) {
          transform: translate3d(0, 0, 0);
          transition-delay: .1s;
        }

        &:nth-child(2) {
          transform: translate3d(8px, 0, 0);
          transition-delay: .05s;
        }

        &:nth-child(3) {
          transform: translate3d(16px, 0, 0);
        }
      }

      @media (--nav-tablet-max-width) {
        height: 25px;
        opacity: 1;
        transition: all .3s ease-out;

        &:nth-child(1) {
          transform: translate3d(1px, 1px, 0) rotate(-45deg);
          transform-origin: top left;
          transition-delay: 0s;
        }

        &:nth-child(2) {
          height: 0;
          transform: translate3d(0, 0, 0);
          transition-delay: 0s;
        }

        &:nth-child(3) {
          transform: translate3d(4px, -13px, 0) rotate(45deg);
          transform-origin: top left;
          transition-delay: 0s;
        }
      }
    }
  }
}

body header.right {
  svg.hamburger.right {
    rect {
      @media (--nav-desktop-min-width) {
        opacity: 0;
        transition: all .25s cubic-bezier(.8, 0, 1, .2);

        &:nth-child(1) {
          transform: translate3d(0, 0, 0);
          transition-delay: .1s;
        }

        &:nth-child(2) {
          transform: translate3d(8px, 0, 0);
          transition-delay: .05s;
        }

        &:nth-child(3) {
          transform: translate3d(16px, 0, 0);
        }
      }

      @media (--nav-tablet-max-width) {
        height: 25px;
        opacity: 1;
        transition: all .3s ease-out;

        &:nth-child(1) {
          transform: translate3d(1px, 1px, 0) rotate(-45deg);
          transform-origin: top left;
          transition-delay: 0s;
        }

        &:nth-child(2) {
          height: 0;
          transform: translate3d(0, 0, 0);
          transition-delay: 0s;
        }

        &:nth-child(3) {
          transform: translate3d(4px, -13px, 0) rotate(45deg);
          transform-origin: top left;
          transition-delay: 0s;
        }
      }
    }
  }
}

.modelBlackText {
  @media (--nav-tablet-max-width) {
    .header {
      svg.hamburger rect {
        fill: var(--black);
      }

      .header__logo svg {
        fill: var(--black);
      }
    }
  }
}

body[pagemode-edit],
body.pagemode-edit,
body.pagemode-edit-related,
body.pagemode-preview {
  overflow-x: hidden;

  header.header {
    display: none;
  }

  &.expose_nav {
    nav.primary {
      display: none;
    }
  }
}
