/* http://easings.net */

:root {
  --ease-in-sine: cubic-bezier(.47, 0, .745, .715);
  --ease-out-sine: cubic-bezier(.39, .575, .565, 1);
  --ease-in-out-sine: cubic-bezier(.445, .05, .55, .95);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-out-cubic: cubic-bezier(.215, .61, .355, 1);
  --ease-out-expo: cubic-bezier(.19, 1, .22, 1);
  --ease-out-quint: cubic-bezier(.23, 1, .32, 1);
  --menu-easing: cubic-bezier(.165, .84, .44, 1);
}
