.small-image-block {
  padding-bottom: 120px;
  color: var(--black);
  background-color: var(--offWhite);

  @media (--up-to-large-viewport) {
    padding-bottom: 55px;
  }

  &__txt-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    line-height: 186%;
  }

  & [fid='heading'] {
    max-width: 100%;
    margin-bottom: 16px;
    font-size: 1rem;
    font-weight: 600;
    transition: .3s;
  }

  & [fid='paragraph'] {
    max-width: 100%;
    margin-bottom: 22px;
    transition: .3s;

    @media (--up-to-large-viewport) {
      margin-bottom: 14px;
    }
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .6s;

    &.active {
      opacity: 1;
    }

    &-cont {
      position: relative;
      display: block;
      max-width: 100%;
      height: 75vh;
      overflow: hidden;
  
      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        max-width: calc(100% + 32px);
        margin-left: -16px;
      }
  
      @media (--up-to-large-viewport) {
        margin-bottom: 36px;
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--hidden &__txt-cont {

    & p,
    & h3 {
      opacity: 0;
    }
  }

  /* dark theme */
  &.var-dark {
    background-color: var(--darkGrey);

    & [fid='heading'],
    & [fid='paragraph'] {
      color: var(--offWhite);
    }

    & .icon-link {
      color: var(--offWhite);

      &__icon {
        background-color: var(--white10);
      }

      &:hover .icon-link__icon {
        background-color: var(--black);

        & svg {
          fill: var(--white);
        }
      }
    }
  }
}

.small-image-block.var-overlap {
  @media (--large-viewport) {
    padding-bottom: 60px;

    & .small-image-block {

      &__picture-cont,
      &__txt-cont {
        top: -60px;
      }
    }
  }
}
