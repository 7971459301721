
.logo__pic {
    display: block;
    padding: 15% 15%;
    
    picture {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 33%;

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;

            &.lazyloading {
            }

            &.lazyloaded {
            }
        }
    }
}


