/* stylelint-disable color-no-hex, color-named, max-line-length */

:root {
  --black: #000;
  --lightgrey: #E5E6E9;
  --white: #fff;
  --grey: #BABFC9;
  --gray: #CCC;
  --darkgrey: #161A21;
  --gold: #B58C67;
  --darkgrey5: #222935;
  --text-light-grey: #f9f9f9;
  --text-dark: #161718;
  --text-grey: rgba(22,23,24,0.6);
  --text-white-transparent: rgba(255,255,255,0.6);
  --text-grey-breadcrumbs: rgba(255,255,255,0.6);
  --body-bacground-color: #161A21;
  --filters-background-color: #0E1116;
  --input-border-grey: rgba(22,23,24,0.2);
  --input-error: #A47B58; 
  --input-error-text: #815F42;
  --button-border-primary: rgba(255,255,255,0.4);
  --event-itinerary-border: rgba(255,255,255,0.2);
  --event-calendar-border: rgba(255,255,255,0.2);
  --button-border-submit: #161718;
  --black20: #0E1116;
  --blackFooter: #0E1116;
  --whiteFooter: var(--text-light-grey);
  --colorNames: black, lightgrey, white, grey, darkgrey, gold, darkgrey5, text, black20;
  --colorValues: var(--black), var(--lightgrey), var(--white), var(--grey), var(--darkgrey), var(--gold), var(--darkgrey5), var(--text-light-grey), var(--text-dark), var(--text-grey), var(--input-border-grey), vra(--button-border-primary), var(--button-border-submit), var(--black20);
  --black-pearl: #161718;
}
