:root {
  --zi-home-header-slide-active: 1;
  --zi-home-header-text: 4;
  --zi-home-header-indicators: 5;
  --zi-home-header-blur: 6;
  --zi-home-slide-button: 5;
  --home-header-mobile-nav-offset: 80px;
  --home-header-desktop-nav-offset: 100px;
  --home-header-mobile-heading-offset: 127px;
  --home-header-desktop-heading-offset: 200px;
}


.home-header {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .home-slider {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    will-change: opacity;
  }

  .home-slide {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 400ms ease-in-out 300ms;
    will-change: opacity;

    &.active {
      opacity: 1;
      transition: all 600ms ease-in-out;
      z-index: var(--zi-home-header-slide-active);
    }

    &:not(.active) {
      user-select: none;
      pointer-events: none;
    }

    &--links__mobile {
      display: none;

      @media (--up-to-large-viewport) {
        display: flex;
        margin-top: 24px;
        opacity: 0;
        transition: opacity .3s ease-in-out 1.2s;
        pointer-events: none;

        .icon-link {
          margin-right: 32px;

          &:last-of-type {
            margin-right: 0;
          }

          &:hover {
            .icon-link__icon svg {
              fill: var(--offWhite);
            }
          }
          &__icon {
            background: rgba(255, 255, 255, 0.1);
          }

          &__content {
            font-family: Optima; /* font-family: GTAmerica; */
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--offWhite);
          }
        }
      }
    }
  }

  .home-header-indicators {
    display: inline-block;

    &__container {
      position: relative;
      top: 0;
      left: 0;
      z-index: var(--zi-home-header-indicators);
      margin-top: var(--home-header-desktop-nav-offset);

      @media (--up-to-large-viewport) {
        margin-top: var(--home-header-mobile-nav-offset);

        .home-header-indicators {
          display: flex;
          width: 100%;
        }

        .slide-indicator {
          flex: 0 0 42px;
          transition: all .4s ease-in-out;

          &.active {
            flex: 1; 

            .slide-progress {
              width: 100%;
            }
          }
          .slide-progress {
            transition: none;
            width: 100%;
          }
        }
      }
    }

    .slide-indicator {
      display: block;
      float: left;
      margin-right: 1px;

      &.no-outline {
        outline: none;
      }
    }

    .slide {
      &-name {
        display: inline-block;
        margin-bottom: 8px;
      }
      &-title, &-index {
        display: inline-block;
        float: left;
        font-family: Optima;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        height: 32px;
        color: var(--offWhite);
        transition: all .4s ease-in-out;
      }

      &-title {
        max-width: 0;
        opacity: 0;
        overflow: hidden;
      }

      &-index {
        opacity: 0.2;
      }

      &-progress {
        display: block;
        width: 40px;
        height: 2px;
        background-color: rgba(248, 247, 244, .2);
        transition: all .4s ease-in-out;

        &--current {
          display: block;
          width: 0%;
          height: 2px;
          background: linear-gradient(270deg, #F8F7F4 0%, rgba(248, 247, 244, 0) 100%);
          /* transition: width 250ms; */
        }
      }
    }
    
    .slide-indicator.active {
      .slide {
        &-title {
          max-width: 160px;
          opacity: 1;
        }
        &-index {
          opacity: 0;
        }
        &-progress {
          width: 160px;
        }
      }
    }
  }

  .blur-backdrop {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: var(--zi-home-header-blur);
    background: rgba(22, 26, 33, 0.8);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    opacity: 0;
    transform: translateZ(0);
    will-change: opacity;
    user-select: none;
    pointer-events: none;
  }

  .home-background {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%);

      @media (--up-to-large-viewport) {
        background: linear-gradient(180deg,rgba(0,0,0,.3) 0,rgba(0,0,0,.4) 60%, rgba(0,0,0,0) 80%);
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }
  }

  .home-slide.text-show {
    .home-background:after {
      opacity: 1;
    }
    .home-slide--links__mobile {
      opacity: 1;
      pointer-events: all;
    }
  }

  .home-background,
  .picture-cover,
  .full-bleed-background,
  .video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .home-background,
  .video {
    position: absolute;
    z-index: 0;
  }

  .picture-cover {
    position: absolute;
    opacity: 0;
    transition: opacity 400ms ease-in-out 300ms;
    will-change: opacity;
    transform: translateZ(0);
    
    &.active {
      opacity: 1;
      transition: all 600ms ease-in-out;
    }

    &:not(.active) {
      user-select: none;
      pointer-events: none;
    }
  }

  .full-bleed-background {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .header-content {
    position: relative;
    height: 100%;
    padding-top: var(--home-header-desktop-heading-offset);

    @media (--up-to-large-viewport) {
      padding-top: var(--home-header-mobile-heading-offset);
    }

    .home-slide--button {
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: .3s;
      z-index: var(--zi-home-slide-button);

      @media (--up-to-large-viewport) {
        display: none;
      }
    }
  }

  &.hide-button {
    .home-slide--button {
      opacity: 0;
      pointer-events: none;
    }
  }
  

  .header-text {
    display: inline-block;
    position: relative;
    /* height: 100%; */
    width: 100%;
    font-family: Optima; /* font-family: GTAmerica; */
    color: var(--lightgrey);
    z-index: var(--zi-home-header-text);
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    max-height: 0;

    &.no-outline {
      outline: none;
    }

    .big-header-text {
      font-family: Optima;
      font-style: normal;
      font-weight: normal;
      font-size: 80px;
      line-height: 96px;
      margin-top: 24px;
      height: calc(100vh - var(--home-header-desktop-heading-offset));

      @media (--up-to-large-viewport) {
        font-size: 58px;
        line-height: 70px;
        margin-top: 25px;
        height: calc(100vh - var(--home-header-mobile-heading-offset));
      }
      & > span {
        display: block;
        overflow: hidden;
        & > span {
          display: block;
          transform: translateY(100%);
          transition: transform 500ms ease-in-out 0.5s;
        }
        &:nth-child(2) {
          & > span {
            transition: transform 500ms ease-in-out 0.7s;
          }
        }
        &:nth-child(3) {
          & > span {
            transition: transform 500ms ease-in-out 0.9s;
          }
        }
      }
    }

    &.active {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      max-height: inherit;

      .big-header-text {
        & > span > span {
          transform: translate(0);
        }
      }
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    user-select: none;
    pointer-events: none;
  }
}
