.news-block {
  background-color: var(--offWhite);
  color: var(--darkGrey);
  padding: 60px 0;

  &__title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.86;
    margin-bottom: 16px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 8px;
    }
  }
  &__heading {
    font-size: 36px;
    line-height: 1.4;
    letter-spacing: 0.4px;
    margin-bottom: 56px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 40px;
    }
  }

  .news-item {
    &__link {
      display: block;
      height: 20vh;
      min-height: 205px;
      max-height: 500px;
      margin-bottom: 36px;
      @media (--medium-and-small-viewport) {
        height: 180px;
        min-height: 180px;
        margin-bottom: 32px;
      }
      @media (--normal-and-large-viewport) and (max-width: 1800px) {
        height: 205px;
      }
    }
    &__img-box {
      display: block;
      height: 100%;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__heading {
      display: block;
      font-size: 24px;
      line-height: 1.67;
      margin-bottom: 24px;
      color: inherit;
      @media (--medium-and-small-viewport) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    &__read-more {
      display: inline-block;
      .read-more {
        &-content {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--darkGrey);
          font-size: 14px;
          line-height: 18px;
        }
        &-icon {
          width: 14px;
          height: 14px;
          display: inline-block;
          margin-left: 8px;
          & svg {
            height: 100%;
            fill: var(--darkGrey);
          }
        }
      }
    }
    &__date {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.86;
      margin-bottom: 9px;
      @media (--medium-and-small-viewport) {
        margin-bottom: 12px;
      }
    }
  }

  .swiper-container {
    margin-bottom: 54px;
  }
}
