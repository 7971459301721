.lifestyle-block {
  padding-top: 60px;
  padding-bottom: 65px;
  background-color: var(--offWhite);

  @media (--large-viewport) {
    padding-top: 95px;
    padding-bottom: 90px;
  }

  &__subtitle {
    display: block;
    margin-bottom: 8px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;

    @media (--large-viewport) {
      margin-bottom: 33px;
    }
  }

  &__title {
    margin-bottom: 55px;
    font-size: 2.25rem;
    line-height: 3.125rem;
    letter-spacing: .4px;

    @media (--large-viewport) {
      margin-bottom: 120px;
      font-size: 3.625rem;
      line-height: 3.625rem;
      letter-spacing: 0;
    }
  }

  &__slider {
    padding-left: 16px;
    padding-right: 16px;

    @media (--normal-and-large-viewport) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  &__slide {

    &-title {
      margin-bottom: 10px;
      font-size: 1.375rem;
      line-height: 2rem;
      padding-right: 15px;

      @media (--large-viewport) {
        margin-bottom: 16px;
        font-size: 2.25rem;
        line-height: 3.125rem;
        letter-spacing: .4px;
        padding-right: 0;
      }
    }

    &-content {
      display: -webkit-box;
      padding-right: 15px;
      margin-bottom: 25px;
      overflow: hidden;
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 1rem;
      line-height: 1.875rem;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      @media (--large-viewport) {
        display: block;
        width: 80%;
        padding-right: 0;
      }
    }

    &-link {
      position: relative;
      margin-bottom: 5px;

      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--darkGrey);
        bottom: -5px;
        left: 0;
        opacity: 0;
        transition: .3s
      }

      &:hover {
        &:after {
          opacity: 0.3
        }
      }
    }
  }

  &__picture {
    position: relative;
    display: block;
    height: 45vh;
    margin-bottom: 25px;

    @media (--large-viewport) {
      height: 70vh;
      margin-bottom: 50px;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
