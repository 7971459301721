main {
    display: block;
}

nav.primary {
    display: block;
}

.header {
    display: block;
}

.footer {
    display: block;
}

.sub-navigation {
    display: block;
    visibility: hidden;

    &.active {
        visibility: visible;
    }

}

div.overlay {
    display: block;
}

div.cookies {
    display: block;
}