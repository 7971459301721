.body-copy {

    *, div, p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.85;
    }

    h1, h2, h3 {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 1.8;
        margin-bottom: 18px;
        @media (--large-viewport) {
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 0.4px;
            line-height: 1.4;
            margin-bottom: 42px;
        }
    }

    h4, h5, h6 {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 32px;
        margin-bottom: 18px;
        @media (--large-viewport) {
            font-size: 24px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.4;
            margin-bottom: 18px;
        }
    }

    strong {
        font-weight: 700;
    }

    ul li {
        position: relative;
        padding-left: 25px;
        margin-bottom: 1rem;

        &::before {
            content: '';
            border-radius: 100%;
            background: currentColor;
            @util size(5px);
            @util position(absolute, 12px, null, null, 0);
        }

    }
}