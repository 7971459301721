.sp-main-nav-header {

  padding-top: 80px;
  padding-bottom: 32px;
  
  @media (--large-viewport) {  
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &.var-dark {
    background-color: var(--darkGrey);
    color: var(--white);
  }

  &.var-small {
    .sp-main-nav-header__title {
      font-size: 16px;
      line-height: 1.8;
      letter-spacing: .4px;
      @media (--large-viewport) {
        font-size: 36px;
        line-height: 47px;
        letter-spacing: 0;
      }
    }
  }

  &__subtitle {
    display: block;
    margin-bottom: 8px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;

    @media (--large-viewport) {
      margin-bottom: 33px;
    }
  }

  &__title {
    font-size: 2.25rem;
    line-height: 3.125rem;
    letter-spacing: .4px;

    @media (--large-viewport) {
      font-size: 3.625rem;
      line-height: 3.625rem;
      letter-spacing: 0;
    }
  }

  &__cta {
    margin-top: 32px;
  }

  &.var-dark .icon-link {
    color: var(--offWhite);

    &__icon {
      background-color: var(--white10);
    }

    &:hover .icon-link__icon, &:focus .icon-link__icon {
      background-color: var(--black);

      & svg {
        fill: var(--white);
      }
    }
  }

}
