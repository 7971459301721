.sp-q-panel {
  padding-bottom: 60px;
  color: var(--black);
  background-color: var(--white);
  @media (--up-to-large-viewport) {
    padding-bottom: 55px;
  }

  .container {
      height: 100%;
  }

  .row {
      height: 100%;
  }

  .logo-header {
    margin-bottom: 30px;
    max-width: 100%;

    img {
      width: 50%;
    }
  }

  &__txt-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    font-size: 1rem;
    line-height: 186%;

    .icon-btn {
      margin-bottom: 0;
    }
  }

  & [fid='heading'] {
    max-width: 100%;
    margin-bottom: 16px;
    font-size: 36px;
    font-weight: 500;
    transition: .3s;
    display: flex;
    align-items: baseline;

    @media (--up-to-large-viewport) {
      font-size: 16px;
      font-weight: bold;
    }

    .icon-btn__icon {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      line-height: 2;
      svg {
        fill: white;
      }
    }
  }

  & [fid='paragraph'] {
    max-width: 100%;
    margin-bottom: 22px;
    transition: .3s;

    @media (--up-to-large-viewport) {
      margin-bottom: 14px;
    }
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .6s;

    &.active {
      opacity: 1;
    }

    &-cont {
      position: relative;
      display: block;
      max-width: 100%;
      padding-top: 56.25%;
      overflow: hidden;
  
      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        max-width: calc(100% + 32px);
        margin-left: -16px;
      }
  
      @media (--up-to-large-viewport) {
        margin-bottom: 36px;
      }
    }
    &__content {
      position: absolute;
      bottom: 32px;
      left: 40px;
      right: 40px;
      color: var(--offWhite);
      @media (--medium-and-small-viewport) {
        left: 20px;
        right: 20px;
      }
      .content {
        &-subtitle {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 12px;
          line-height: 1.46;
        }
        &-title {
          font-size: 36px;
          letter-spacing: 0.4px;
          line-height: 1.4;
        }
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--hidden &__txt-cont {

    & p,
    & h3 {
      opacity: 0;
    }
  }

  &__video-cont {
    position: relative;
    display: block;
    max-width: 100%;
    padding-top: 56.25%;
    overflow: hidden;

    @media (--medium-and-small-viewport) {
      width: calc(100% + 32px);
      max-width: calc(100% + 32px);
      margin-left: -16px;
    }

    @media (--up-to-large-viewport) {
      margin-bottom: 36px;
    }
  }

  /* dark theme */
  &.var-dark {
    background-color: var(--darkGrey);

    & [fid='heading'],
    & [fid='paragraph'] {
      color: var(--offWhite);
    }

    & .icon-link {
      color: var(--offWhite);

      &__icon {
        background-color: var(--white10);
      }

      &:hover .icon-link__icon {
        background-color: var(--black);

        & svg {
          fill: var(--white);
        }
      }
    }
  }

  .video__inner-media {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;

    .cover-image,
    .video {
      @util position(
        absolute,
        0,
        0,
        null,
        0
      );
      @util size(auto, 100%);
    }

    .icon-play {
      opacity: 1;
      pointer-events: none;
      @util center;
      display: block;
      width: 76px;
      height: 76px;
      padding: 30px;
      border-radius: 50%;
      background-color: var(--black10);
      backdrop-filter: blur(20px);

      svg {
        position: static;
        stroke: var(--offWhite);
        stroke-width: 10px;
        height: auto;
      }
    }

    .touch & .icon {
      width: 52px;
      height: 52px;
      padding: 18px;
    }

    &[tabindex]:focus {
      outline: none;
      .icon {
        outline: color(var(--white) a(50%)) dotted 1px;
        outline-offset: 5px;
      }
    }


    @media (--medium-and-small-viewport) {
      padding-top: 16px;

      .video__inner-media {
        padding: 0 var(--baseSize);

        .cover-image,
        .video {
          @util position(
            absolute,
            0,
            16px,
            null,
            16px
          );
          @util size(auto, 100%);
        }

      }

    }

    .video__cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fullscreen-video {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: 10000;
      opacity: 0;
      transition: opacity .3s ease, width 0s linear .3s, height 0s linear .3s;

      &.fullscreen-video--active {
        opacity: 1;
        transition: opacity .3s ease, width 0s linear, height 0s linear;
        @util size(100%);
      }

      &.no-outline {
              outline: none;
            }
  }

}
  
.sp-q-panel.var-overlap {
  @media (--large-viewport) {
    padding-bottom: 60px;

    & .sp-q-panel {

      &__picture-cont,
      &__txt-cont {
        top: -60px;
      }
    }
  }
}
  