.accessories-block {
  background-color: var(--offWhite);
  color: var(--darkGrey);
  padding: 80px 0 160px;
  @media (--medium-and-small-viewport) {
    padding: 65px 0 128px;
  }

  &__title {
    font-family: Optima; /* font-family: GTAmerica; */
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 14px;
    @media (--medium-and-small-viewport) {
      font-size: 16px;
      margin-bottom: 11px;
      line-height: 1.86;
    }
  }
  &__heading {
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 16px;
    line-height: 1.86;
    margin-bottom: 22px;
    @media (--normal-and-large-viewport) {
      margin-bottom: 30px;
      max-width: 290px;
    }
  }

  .accessories-item {
    &__img-link {
      display: block;
      height: 33.8vh;
      min-height: 278px;
      max-height: 1000px;
      margin-bottom: 32px;
      @media (--medium-and-small-viewport) {
        height: 220px;
        min-height: 220px;
      }
      @media (--normal-and-large-viewport) and (max-width: 1800px) {
        height: 278px;
      }
    }
    &__img-box {
      display: block;
      height: 100%;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__title {
      display: block;
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: 600;
      font-size: 16px;
      line-height: 1.86;
      margin-bottom: 8px;
      color: inherit;
    }
    &__desc {
      display: block;
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 16px;
      line-height: 1.46;
      color: inherit;
      @media (--medium-and-small-viewport) {
        line-height: 1.86;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      @media (--normal-and-large-viewport) {
        max-width: 68%;
      }
    }
    &__view-link {
      display: none;
      @media (--medium-and-small-viewport) {
        padding-top: 24px;
        display: block;
      }
    }
  }

  .swiper-container {
    padding: 10px;
    margin: 0px -10px 34px;
  }
}
