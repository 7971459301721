body {
  font-family: Optima;
  font-style: normal;
  font-weight: normal;
  color: var(--black);
}

html {
  font-family: Optima, Times New Roman, Times, sans-serif;
}

a {
  color: var(--text-light-grey);
}


a {
  &,
  .no-touch &:hover,
  &:visited {
    text-decoration: none;
  }

  &:active {
    outline: none;
  }

  &:focus {
    outline: color(var(--white) a(50%)) dotted 1px;
    outline-offset: 5px;
  }

  .page--light &:focus {
    outline-color: color(var(--gray) a(70%));
  }

}


h1, h2, h3, h4, h5 {
  font-weight: normal;
  margin: 0;
}

ul,
ol {
  list-style-position: inside;
  padding: 0;
}

h1 {
  font-size: 74px;
  line-height: 74px;

  @media (--up-to-large-viewport) {
    font-size: 34px;
    line-height: 40px;
  }
}

h2 {
  font-size: 54px;
  line-height: 54px;

  @media (--up-to-large-viewport) {
    font-size: 24px;
    line-height: 36px;
  }
}

h3 {
  font-size: 34px;
  line-height: 46px;

  @media (--up-to-large-viewport) {
    font-size: 18px;
    line-height: 18px;
  }
}

h4 {
  font-size: 22px;
  line-height: 22px;

  @media (--up-to-large-viewport) {
    font-size: 14px;
    line-height: 18px;
  }
}

h5 {
  font-size: 16px;
  line-height: 16px;

  @media (--up-to-large-viewport) {
    font-size: 14px;
    line-height: 16px;
  }
}

.rte {
  font-size: 16px;
  line-height: 32px;

  h1, h2, h3, h4, h5 {
    margin-top: 48px;
    margin-bottom: 20px;

    @media (--up-to-large-viewport) {
      margin-top: 26px;
      margin-bottom: 16px;
    }
  }

  p, li {
    margin-bottom: 20px;
  }

  ul, ol {
    margin: 0;
  }
}

.text--center {
  text-align: center;
}