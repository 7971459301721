.article-block {

  color: var(--darkGrey);

  .article__title {
    font-size: 58px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 70px;
    @media (--small-viewport) {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.4px;
      margin-bottom: 32px;
    }
    @media (--medium-viewport) {
      font-size: 36px;
      line-height: 50px;
    }
  }

  .article__heading {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.8;
    margin-bottom: 35px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 18px;
    }
  }

  .article__subheading {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.86;
    margin-bottom: 9px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 8px;
    }
  }

  .body-copy {
    line-height: 1.85;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media (--small-viewport) {
    .px-0 {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  picture {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 61%;

      > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;

          &.lazyloading {
          }

          &.lazyloaded {
          }
      }
  }

}
