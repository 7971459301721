
body {
  &.fullscreen-active,
  &.overlay-active {
    overflow: hidden;
  }
}

main, footer {
  position: relative;
}


body, main {

  &.page--whisper {
    background-color: var(--whisperGrey);
  }

}
