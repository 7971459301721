.cookie-bar {
  display: flex;
  align-items: center;
  background: var(--darkGrey20);
  backdrop-filter: blur(40px);
  padding: 24px 40px;
  max-width: 580px;
  visibility: hidden;
  position: fixed;
  z-index: 1001;
  top: 113px;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.525s ease-in-out, visibility 0.525s, background 0.2s;

  @media (--medium-and-small-viewport) {
    padding: 16px 20px;
    max-width: 300px;
    top: auto;
    bottom: 124px;
    background: var(--darkGrey90);
    backdrop-filter: none;
  }
  @media (max-height: 479px) {
    top: auto;
    bottom: 70px;
  }

  &--visible {
    visibility: visible;
    transform: translateX(0);
  }

  &__close {
    flex: 1 0 auto;
    width: 25px;
    height: 25px;
    margin-right: 40px;
    display: inline-block;
    @media (--medium-and-small-viewport) {
      margin-right: 18px;
    }
    & svg {
      height: 100%;
      fill: var(--white);
    }
  }
  &__desc {
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 12px;
    line-height: 140%;
    color: var(--white);
    a {
      text-decoration: underline;
    }
    @media (--medium-and-small-viewport) {
      font-weight: 300;
    }
  }

  &.var-dark {
    background: rgba(0, 0, 0, 0.5);
  }
}
