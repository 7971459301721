.icon-btn {
  display: inline-flex;
  padding: 20px 44px;
  margin: 20px 0;
  font-family: Optima; /* font-family: GTAmerica; */
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: var(--black);
  cursor: pointer;
  background-color: rgba(22, 26, 33, 0.05);
  transition: all 0.3s ease-in-out;
  backdrop-filter: blur(40px);
  border-left: 1px solid var(--black);

  &:hover, &:focus {
    background-color: var(--offWhite);
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    line-height: 2;

    & svg {
      height: 100%;
      fill: var(--black);
    }
  }

  &--dark {
    color: var(--offWhite);
    background-color: rgba(248, 247, 244, 0.05);
    border-left: 1px solid var(--offWhite);

    &:hover, &:focus {
      background-color: rgba(248, 247, 244, 0.15);
    }
  }

  &--dark &__icon svg {
    fill: var(--offWhite);
  }

  &--light {
    border-color: var(--darkGrey);
    color: var(--darkGrey);
    &:hover, &:focus {
      background-color: var(--darkGrey15);
    }
  }
  &--light &__icon svg {
    fill: var(--darkGrey);
  }

  &--dark-grey {
    border-color: var(--offWhite);
    color: var(--offWhite1);
    background-color: var(--darkGrey20);
    &:hover, &:focus {
      background-color: var(--darkGrey30);
    }
  }
  &--dark-grey &__icon svg {
    fill: var(--white);
  }
}
