.news-article-block {
  color: var(--darkGrey);
  padding: 60px 0;

  @media (--medium-and-small-viewport) {
    padding: 40px 0;
  }

  &__title {
    font-size: 58px;
    line-height: 1;
    letter-spacing: 0;
    margin-bottom: 70px;
    @media (--small-viewport) {
      font-size: 22px;
      line-height: 32px;
      letter-spacing: 0.4px;
      margin-bottom: 32px;
    }
    @media (--medium-viewport) {
      font-size: 36px;
      line-height: 50px;
    }
  }

  &__heading {
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.8;
    margin-bottom: 35px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 18px;
    }
  }

  &__subheading {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.86;
    margin-bottom: 9px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 8px;
    }
  }

  &__share {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.86;
    padding-top: 20px;
    margin-bottom: 9px;
    @media (--small-viewport) {
      font-size: 24px;
      font-weight: normal;
      line-height: 40px;
      margin-bottom: 8px;
    }
  }

  &__content.row {
    @media (--small-viewport) {
      flex-direction: column-reverse;
    }

    .body-copy {
      margin-top: 20px;
      line-height: 1.85;
      margin-bottom: 30px;
      @media (--medium-and-small-viewport) {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

  }

  .news-item {
    margin-bottom: 55px;
    @media (--large-viewport) {
      margin-bottom: 80px;
    }

    &__link {
      display: block;
      height: 77vw;
      margin-bottom: 40px;
      @media (max-width: 1024px) and (min-width: 768px) {
        height: 42vw;
        margin-bottom: 80px;
      }
      @media (--large-viewport) {
        height: 42vw;
        margin-bottom: 80px;
      }
    }
    &__img-box {
      display: block;
      height: 100%;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__heading {
      display: block;
      font-size: 22px;
      line-height: 1.8;
      margin-bottom: 26px;
      color: inherit;
      @media (--large-viewport) {
        font-size: 24px;
        line-height: 1.67;
        margin-bottom: 24px;
      }
    }
    &__read-more {
      display: inline-block;
      .read-more {
        &-content {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: var(--darkGrey);
          font-size: 14px;
          line-height: 18px;
        }
        &-icon {
          width: 14px;
          height: 14px;
          display: inline-block;
          margin-left: 8px;
          & svg {
            vertical-align: top;
            height: 100%;
            fill: var(--darkGrey);
          }
        }
      }
      .go-back-icon {
        transform: scaleX(-1);
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 8px;
        & svg {
          vertical-align: top;
          height: 100%;
          fill: var(--darkGrey);
        }
      }
    }

  }

  @media (--small-viewport) {
    .px-0 {
      padding: 0 !important;
      max-width: 100% !important;
    }
  }

  .news-full__social-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 1em;

    @media (--small-viewport) {
      padding-bottom: 32px;
      border-bottom: 1px solid var(--darkGrey20);
    }

    @media (min-width: 768px) {
      writing-mode: vertical-lr;
    }
  }

  .news-full__social-link {
    color: var(--black);
    border-bottom: 0 solid var(--black) !important;

    &:hover,
    &:focus {
      opacity: .8;
    }

    .icon {
      @media (--normal-viewport) {
        margin-right: 1em;
      }
      width: 20px;
      height: 20px;
      margin-bottom: 1em;
      margin-right: 1.5em;
    }
  }

}
