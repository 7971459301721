/* version 2 is dedicated to Home&Model redesign */

/* Critical */
@import 'shared/modules/__critical.pcss';

/* Custom normalize */
@import 'shared/base/_normalize.pcss';

/* Base styles */
@import 'astonmartin/base/_color.pcss';
@import "artofliving/base/_color.pcss";
@import 'astonmartin/base/v2/_layout.pcss';
@import 'astonmartin/base/v2/_color.pcss';
@import 'astonmartin/base/v2/_font-family.pcss';
@import 'astonmartin/base/v2/_z-indexes.pcss';
@import 'astonmartin/base/v2/_typography.pcss';
@import 'shared/base/_sizes.pcss';
@import 'astonmartin/base/v2/_media.pcss';
@import 'shared/partials/_icon.pcss';
@import "astonmartin/base/v2/_icon.pcss";
@import 'shared/modules/_video.pcss';
@import 'shared/base/_easings.pcss';

/* Enquire overaly missing styles */
@import 'astonmartin/modules/v2/_enquire-overlay-addons.pcss';
@import 'shared/partials/_block.pcss';
@import 'shared/modules/_form.pcss';

/* AOL grid */
@import "artofliving/base/_bootstrap-grid.pcss";
@import "astonmartin/base/_grid-v2.pcss";

/* Partial styles */
@import 'astonmartin/partials/v2/_icon-link.pcss';
@import 'astonmartin/partials/v2/_icon-btn.pcss';
@import 'shared/modules/_swiper.pcss';
@import 'shared/partials/_a11y.pcss';

/* Overlays */
@import 'shared/modules/_overlay.pcss';
@import 'shared/modules/overlays/_enquire.pcss';
@import 'shared/modules/_enquire2.pcss';
@import 'shared/modules/_enquire2step2.pcss';

/* AM Navigation */
@import 'shared/modules/_header.pcss';
@import 'astonmartin/modules/_header.pcss';
@import 'artofliving/modules/_header.pcss';
@import 'astonmartin/modules/_main-menu.pcss';
@import 'astonmartin/modules/_sub-nav.pcss';
@import 'astonmartin/modules/v2/_explore-cursor.pcss';
@import 'astonmartin/modules/_models-nav.pcss';

/* AoL Footer */
@import "artofliving/modules/_footer.pcss";
@import "artofliving/modules/_location-list.pcss";

/* New modules */
@import 'astonmartin/modules/v2/_home-header.pcss';
@import 'astonmartin/modules/v2/_models-header.pcss';
@import 'astonmartin/modules/v2/_page-sticky-buttons.pcss';
@import 'astonmartin/modules/v2/_small-image-block.pcss';
@import 'astonmartin/modules/v2/_large-image-block.pcss';
@import 'astonmartin/modules/v2/_model-highlight.pcss';
@import 'astonmartin/modules/v2/_model-carousel.pcss';
@import 'astonmartin/modules/v2/_model-sticky-nav.pcss';
@import 'astonmartin/modules/v2/_engine-stats.pcss';
@import 'astonmartin/modules/v2/_model-configurator.pcss';
@import 'astonmartin/modules/v2/_promo-block.pcss';
@import 'astonmartin/modules/v2/_main-navigation-block.pcss';
@import 'astonmartin/modules/v2/_news-block.pcss';
@import 'astonmartin/modules/v2/_financial-services-block.pcss';
@import 'astonmartin/modules/v2/_transition-panel.pcss';
@import 'astonmartin/modules/v2/_utility-bar-block.pcss';
@import 'astonmartin/modules/v2/_overlay.pcss';
@import 'astonmartin/modules/v2/_cookie-bar.pcss';
@import 'astonmartin/modules/v2/_model-promo.pcss';
@import 'astonmartin/modules/v2/_promo-video.pcss';
@import 'astonmartin/modules/v2/_accessories-block.pcss';
@import 'astonmartin/modules/v2/_lifestyle-block.pcss';
@import 'astonmartin/modules/v2/_overlay-exterior-block.pcss';
@import 'astonmartin/modules/v2/_overlay-interior-block.pcss';
@import 'astonmartin/modules/v2/_overlay-model-specs-block.pcss';
@import 'astonmartin/modules/v2/_overlay-conditions.pcss';
@import 'astonmartin/modules/v2/_sp-main-nav-header.pcss';
@import 'astonmartin/modules/v2/_sp-q-panel.pcss';
@import 'astonmartin/modules/v2/_sp-promo-block-short.pcss';
@import 'astonmartin/modules/v2/_sp-header.pcss';
@import 'astonmartin/modules/v2/_news-list-block.pcss';
@import 'astonmartin/modules/v2/_news-article-block.pcss';
@import 'astonmartin/modules/v2/_body-copy.pcss';
@import 'astonmartin/modules/v2/_blocks.pcss';
@import 'astonmartin/modules/v2/_sp-partners.pcss';
@import 'astonmartin/modules/v2/_promo-swiper.pcss';
@import 'astonmartin/modules/v2/_sp-teams.pcss';
@import 'astonmartin/modules/v2/_generic-article.pcss';
@import 'astonmartin/modules/v2/_owner-guides.pcss';
