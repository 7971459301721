:root {
  --z-index-sticky-buttons: 15;
}

body.enquire-menu-active {
  @media (--up-to-large-viewport) {
    overflow: hidden;
  }
}

.page-sticky-buttons {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transition: .6s;
  transition-delay: 1s;
  transform: translateY(100%);
  z-index: var(--z-index-sticky-buttons);
  pointer-events: none;
  user-select: none;

  &.lazyloaded {
    transform: translateY(0);
  }

  &.menu-active {
    z-index: 1002;

    .page-sticky-buttons--container {
      &.container.gutter { 
        backdrop-filter: none;
      }
    }
  }
  
  &--container {
    display: flex;
    justify-content: space-between;

    &.container.gutter { 
      @media (--up-to-large-viewport) {
        width: 100%;
        max-width: 100%;
        padding: 0;
        /* backdrop-filter: blur(40px); */
        background: var(--darkGrey90);
      }
    }
  }

  .page-sticky-button {
    pointer-events: all;

    .icon-btn {
      background-color: var(--darkGrey20);

      html.ie &, html.firefox & {
        background: var(--darkGrey90);
      }

      .icon-btn__icon svg {
        transition: fill .3s ease-in-out 0s;
      }

      &:hover, &:focus {
        background-color: var(--darkGrey30);
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }

    @media (--up-to-large-viewport) {
      flex-grow: 1;
      flex-basis: 0;

      &.hidden-mobile {
        display: none;
      }

      .icon-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-left: none;
        padding: 20px 10px;
        margin: 0;
        backdrop-filter: none;
      }

      &:last-of-type {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          height: calc(100% - 20px);
          width: 1px;
          background-color: rgba(255, 255, 255, 0.2);
          z-index: 1;
        }
      }
    }

  }

  .enquire-button {
    position: relative;
    cursor: default;

    @media (--up-to-large-viewport) {
      width: 100%;
    }

    .icon-btn {
      position: relative;
      opacity: 1;
      transition: all 0.3s ease-in-out;
      z-index: 3;

      .icon-btn__icon svg {
        transform: scaleY(-1);
        transition: transform .2s ease-in-out .2s, fill .3s;
        @media (--up-to-large-viewport) {
          transition: transform .2s ease-in-out;
        }
      }
    }

    &.active {
      & + div.page-sticky-button:before {
        @media (--up-to-large-viewport) {
          opacity: 0;
        }
      }

      .icon-btn {
        transition: opacity 0.3s ease-in-out 0.3s, background-color 0.3s ease-in-out;
        opacity: 0;
        pointer-events: none;
        user-select: none;

        @media (--up-to-large-viewport) {
          opacity: 1;
          z-index: 1;
        }

        @media (--large-viewport) {
          html.touch & {
            display: none;
          }
        }

        .icon-btn__icon svg {
          transform: scaleY(1);
        }
      }
    }

    .close-icon {
      display: block;
      width: 56px;
      height: 56px;
      padding: 19px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      margin-bottom: 70px;

      & svg {
        width: 100%;
        fill: var(--white);
      }

      &:focus {
        outline: var(--white50) dotted 1px;
        outline-offset: 5px;
      }
    }

    .enquire-menu {
      position: absolute;
 
      @media (--up-to-large-viewport) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100vw;
        height: 100vh;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        backdrop-filter: none;
        z-index: 1;
        opacity: 0;
        user-select: none;
        pointer-events: none;
        transition: all 300ms ease-in-out;

        &--list {
          margin-bottom: 30px;

          &--item {
            margin-bottom: 50px;
            text-align: center;
            opacity: 0;
            transition: opacity .1s ease-in-out;
          }

          &--link {
            font-family: Optima; /* font-family: GTAmerica; */
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--offWhite1);
            background: none; 
            appearance: none;
            cursor: pointer;
          }
        }
        
        &.active {
          background: rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(40px);
          opacity: 1;
          pointer-events: all;

          .enquire-menu--list--item {
            opacity: 1;
            transition: opacity .3s ease-in-out 1.1s, background-color .2s ease-in-out;

            &:nth-of-type(4) {
              transition: opacity .3s ease-in-out 0.5s, background-color .2s ease-in-out;
            }

            &:nth-of-type(3) {
              transition: opacity .3s ease-in-out 0.7s, background-color .2s ease-in-out;
            }

            &:nth-of-type(2) {
              transition: opacity .3s ease-in-out 0.9s, background-color .2s ease-in-out;
            }
          }
        }
      }

      @media (--large-viewport) {
        left: -1px;
        bottom: 20px;
        padding: 0px;

        html.touch & {
          display: none;
        }

        .close-icon {
          display: none;
        }

        &--list {
          opacity: 0;
          max-width: 180px;
          max-height: 60px;

          overflow: hidden;
          transition: .5s;
          background-color: var(--darkGrey20);
          backdrop-filter: blur(40px);

          html.ie &, html.firefox & {
            background: var(--darkGrey90);
          }

          &--item {
            opacity: 0;
            transition: opacity .3s ease-in-out, background-color .2s ease-in-out;
          }

          &--link {
            display: inline-flex;
            padding: 20px 44px;
            margin: 0;
            width: 100%;
            white-space: nowrap;
            font-family: Optima; /* font-family: GTAmerica; */
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.25rem;
            cursor: pointer;
            color: var(--offWhite);
            background-color: transparent;
            border-left: 1px solid var(--offWhite);
            transition: background-color .2s ease-in-out;

        
            &:hover {
              background-color: var(--darkgrey);
            }
          }
        }

        &.active {

          html.touch & {
            display: block;
          }

          .enquire-menu {
            &--list {
              opacity: 1;
              max-height: 400px;
              max-width: 400px;
    
              transition: max-height .5s ease-in-out .3s, max-width 1s ease-in-out .3s, opacity .3s ease-in-out;

              &--item {
                opacity: 1;
                transition: opacity .3s ease-in-out 1.1s, background-color .2s ease-in-out;

                &:nth-of-type(4) {
                  transition: opacity .3s ease-in-out 0.5s, background-color .2s ease-in-out;
                }

                &:nth-of-type(3) {
                  transition: opacity .3s ease-in-out 0.7s, background-color .2s ease-in-out;
                }

                &:nth-of-type(2) {
                  transition: opacity .3s ease-in-out 0.9s, background-color .2s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }

  &.var-dark {
    .page-sticky-button {
      .icon-btn {
        background-color: var(--offWhite95);
        color: var(--darkGrey);
        border-left-color: var(--darkGrey);

        html.ie &, html.firefox & {
          background: var(--white90);
        }

        &:hover, &:focus {
          background-color: var(--offWhite);
        }

      }
      .icon-btn__icon svg {
        fill: var(--darkGrey);
      }
    }

    .enquire-button {
      .enquire-menu {
        @media (--large-viewport) {
          .enquire-menu--list {
            background-color: var(--offWhite20);

            html.ie &, html.firefox & {
              background: var(--white90);
            }        
          }
          .enquire-menu--list--link {
            color: var(--darkGrey);
            border-left-color: var(--darkGrey);

            &:hover {
              background-color: var(--offWhite);
            }
          }
        }
      }
    }
  }
}