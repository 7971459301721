
/* --- page sections padding toolkit ------------------ */
section, div {

    /* top and bottom paddings */
    &.pad-sm {
        padding-top: 16px;
        padding-bottom: 16px;
        @media (--large-viewport) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
    &.pad-md {
        padding-top: 55px;
        padding-bottom: 55px;
        @media (--large-viewport) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
    &.pad-lg {
        padding-top: 60px;
        padding-bottom: 60px;
        @media (--large-viewport) {
            padding-top: 80px;
            padding-bottom: 80px;
        }
    }

    &.pad-xl {
        padding-top: 50px;
        padding-bottom: 50px;
        @media (--large-viewport) {
            padding-top: 120px;
            padding-bottom: 120px;
        }
    }

    /* top paddings only */
    &.padt-md {
        padding-top: 30px;
        @media (--large-viewport) {
            padding-top: 60px;
        }
    }
    &.padt-lg {
        padding-top: 60px;
        @media (--large-viewport) {
            padding-top: 80px;
        }
    }

    /* bottom paddings only */
    &.padb-lg {
        padding-bottom: 60px;

        @media (--large-viewport) {
            padding-bottom: 80px;
        }
    }
}

/* --- block's color variants ------------------ */
.block > section {

    &.var-light {
        background-color: white;
    }

    &.var-dark {
        background-color: #161a21;
    }

}


