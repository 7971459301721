

.team__title {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    letter-spacing: 0.4px;
    line-height: 140%;
    margin-bottom: 16px;

    @media (--medium-and-small-viewport) {
        font-size: 22px;
        letter-spacing: 0;
    }
}

.team-item {
    
    &__pic {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 69.5%;
        margin-bottom: 16px;

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.lazyloading {
            }

            &.lazyloaded {
            }
        }
    }

    &__title {
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 16px;
    }

    &__copy {
        line-height: 30px;
        padding-right: 36px;
        margin-bottom: 16px;

        strong {
            font-weight: 700;
        }
    }
}


