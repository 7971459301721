.large-image-block {
  padding-top: 80px;
  padding-bottom: 80px;
  color: var(--darkGrey);
  background-color: var(--offWhite);

  @media (--large-viewport) {
    padding-top: 120px;
    padding-bottom: 0px;
  }
   
  &__subtitle {
    display: block;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.86rem;
    transition: .3s;

    @media (--large-viewport) {
      margin-bottom: 30px;
    }
  }

  &__title {
    margin-bottom: 55px;
    font-family: Optima;
    font-size: 2.25rem;
    line-height: 3.15rem;
    transition: .3s;

    @media (--large-viewport) {
      margin-bottom: 120px;
      font-size: 3.625rem;
      line-height: 3.625rem;
    }
  }

  &__cont {
    position: relative;
  }

  &__quote {
    margin-bottom: 25px;
    font-family: Optima;
    font-size: 1.375rem;
    line-height: 2rem;

    @media (--large-viewport) {
      position: relative;
      margin-bottom: 40px;
      z-index: 1;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }

    &-cont {
      @media (--large-viewport) {
        position: relative;
        top: -60px;
      }
    }

    &-cont * {
      transition: .3s;
    }

    &-icon {
      display: block;
      width: 48px;
      height: 48px;
      padding: 18px;
      margin-bottom: 15px;
      border-radius: 50%;
      background-color: var(--white);

      & svg {
        width: 100%;
        height: 100%;
      }

      @media (--large-viewport) {
        position: relative;
        width: 78px;
        height: 78px;
        top: 0;
        left: -30px;
        padding: 32px;
        margin-bottom: -20px;

        & svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  &__author {
    display: flex;
    margin-bottom: 60px;

    @media (--large-viewport) {
      margin-bottom: 120px;
    }

    &-img-cont {
      position: relative;
      width: 64px;
      height: 84px;
    }

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: .6s;

      &.active {
        opacity: 1;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-txt {
      margin-left: 30px;
    }

    &-name,
    &-title {
      display: block;
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 1rem;
      line-height: 1rem;
    }

    &-name {
      margin-bottom: 15px;
      font-weight: 600;
    }

    &-title {
      font-weight: 300;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .6s;

    &.active {
      opacity: 1;
    }

    &-cont {
      position: relative;
      display: block;
      width: calc(100% + 32px);
      max-width: calc(100% + 32px);
      height: 100vh;
      margin-bottom: 4px;
      margin-left: -16px;
      transition: .3s;

      &.ie {
        background-size: cover;
        background-position: 50% 50%;
      }

      @media (--normal-and-large-viewport) {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }

      @media (--large-viewport) {
        height: 75vh;
        min-height: 520px;
        margin-bottom: 4px;

        &.large {
          margin-bottom: 4px;
        }

      }

      &.large {
        margin-bottom: 35px;
        height: 150vh;
      }

    }
  }

  &__paragraph {
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    line-height: 1.875rem;
    transition: .3s;
  }

  &__content {
    @media (--large-viewport) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &--hidden & {

    &__title {
      opacity: 0;
    }

    &__quote-cont * {
      opacity: 0;
    }

    &__subtitle {
      opacity: 0;
    }

    &__paragraph {
      opacity: 0;
    }
  }

  /* dark mode */
  &.var-dark {
    color: var(--offWhite);
    background-color: var(--darkGrey);
  }

  &.var-dark &__quote-icon {
    background-color: #080B11;

    & svg {
      fill: var(--offWhite);
    }
  }

  &.var-dark .icon-link {
    color: var(--offWhite);

    &__icon {
      background-color: var(--white10);
    }

    &:hover .icon-link__icon, &:focus .icon-link__icon {
      background-color: var(--black);

      & svg {
        fill: var(--white);
      }
    }
  }

  .hidden {
    transform: translateX(-10000px);
  }

}
