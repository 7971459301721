.interior-block {
  padding: 40px 0;

  &__picture {
    min-height: 300px;
    height: 76vh;
    display: block;
    margin-bottom: 32px;

    @media (--medium-and-small-viewport) {
      width: calc(100% + 32px);
      margin-left: -16px;
      height: 65vh;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__details {
    display: flex;
    font-family: Optima; /* font-family: GTAmerica; */
    color: var(--darkGrey);

    @media (--medium-and-small-viewport) {
      flex-direction: column;
    }
    .detail-item {
      min-width: 200px;
      margin-bottom: 20px;

      .heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.86;
        margin-bottom: 7px;
        @media (--normal-and-large-viewport) {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .sub-text {
        font-size: 16px;
        line-height: 1.86;
      }
      & + .detail-item {
        @media (--normal-and-large-viewport) {
          margin-left: 100px;
        }
      }
    }
  }
}

.var-dark {
  .interior-block__details {
    color: var(--offWhite1);
  }
}
