.sub-navigation .models {
  padding: 0;
  color: var(--white);
  background-color: var(--black-pearl);
  transition: all .3s, color 0s;
  
  @media (--nav-tablet-max-width) {
    height: 100%;
    padding: 0;
    overflow-y: auto;
    z-index: 999;

    .models-wrapper {
      position: relative;
      min-height: 100%;
      padding: 80px 0 0;
    }
  }

  .models-wrapper {
    background-color: var(--transparent);
    transition: background .3s linear;
  }

  &.models-nav-open {

    .models-wrapper {
      @media (--nav-tablet-max-width) {
        background-color: var(--black-pearl);
      }
    }

    .gradient-top {
      background: linear-gradient(to bottom, rgba(22, 23, 24, 1) 60%, rgba(22, 23, 24, 0) 100%);
    }
  }

  &__header {
    position: relative;
    padding: 0 20px;
    z-index: 10;

    @media (--nav-desktop-min-width) {
      display: none;
    }
  }

  a.close-sub-nav {
    display: none;
    margin-bottom: 50px;
    color: inherit;
  }

  .active-model {
    position: relative;
    display: none;
    width: 100%;
    padding-right: 35px;
    font-size: 24px;
    text-transform: uppercase;
    color: inherit;
    word-break: break-all;
    word-break: break-word;

    svg {
      position: absolute;
      display: block;
      width: 25px;
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-90deg);
    }

    @media (--nav-tablet-max-width) {
      display: inline-block;
    }

    &.active {
      color: var(--white);

      svg {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

   nav.model-nav {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 120px 60px 80px;
    z-index: 1;
    pointer-events: none;
    box-sizing: border-box;

    @media (--nav-tablet-max-width) {
      position: absolute;
      height: auto;
      top: 230px;
      padding: 0 20px;
      opacity: 0;
      pointer-events: none;
      transition: opacity .1s cubic-bezier(.6, 0, 1, .4);

      > .sub-nav-container {
        padding: 40px 0 0;
      }
    }

    @media (--nav-desktop-min-width) {
      transition-delay: 0s;
    }

    ul {
      padding: 0 425px 0 95px;
      list-style: none;
      box-sizing: border-box;
      margin: 0;

      @media (--nav-tablet-max-width) {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        line-height: .5em;
        transition: transform .1s cubic-bezier(.6, 0, 1, .4);
      }

      li {
        width: 100%;
        opacity: 1;
        transition: all .3s cubic-bezier(.6, 0, 1, .4), opacity .3s linear, color 0s 0s;
        line-height: normal;

        &:not(:last-of-type) a {
          margin-bottom: 4.5vh;
        }

        @media (--nav-tablet-max-width) {
          display: block;
          
          a {
            margin-bottom: 30px;
          }
        }
        

        a {
          display: inline-block;
          opacity: .6;
          text-transform: uppercase;
          text-decoration: none;
          line-height: 23.45px;
          color: inherit;
          cursor: pointer;
          pointer-events: none;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          
          @media (--nav-desktop-min-width) {
            font-size: 5vh;
          }

          @media (--nav-tablet-max-width) {
            opacity: 1;
            font-size: 18px;
            line-height: 1.2222em;
            letter-spacing: .015em;

            &.active {
              display: none;
            }
          }

          &:hover,
          &.active {
            opacity: 1;
          }
          
        }
      }
    }

    &.active {

      @media (--nav-tablet-max-width) {
        position: static;
        height: 100%;
        opacity: 1;
        color: var(--white);
        pointer-events: all;
        transition: opacity .3s cubic-bezier(.6, 0, 1, .4);

        & + div + .models-videos {
          height: 0;
          max-height: 0;
          opacity: 0;
          transition: opacity .3s cubic-bezier(.6, 0, 1, .4);

          video,
          img {
            opacity: 0;
          }
        }
        
        & + div + div + .models-controls {
          height: 0;
          margin: 0;
          opacity: 0;
          pointer-events: none;
        }

        & + div + div + div + .model-sub-navigation.active {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  .stats {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 120px;
    right: 140px;
    z-index: 30;
    opacity: 1;
    transition: opacity .3s cubic-bezier(.6, 0, 1, .4);

    @media (--nav-tablet-max-width) {
      display: none;
    }

    &.hidden {
      opacity: 0;
    }

    & > div {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
    }

    label {
      font-size: 11px;
      text-transform: uppercase;
      line-height: 2.18em;
      letter-spacing: .083em;
    }

    .value {
      position: relative;
      display: flex;
      flex-direction: row;
      align-self: center;
      min-width: 105px;
      max-width: 130px;
      top: 5px;
      margin-left: 18px;
      font-size: 24px;

      .number {
        &.noValue {
          font-size: 12px;
          letter-spacing: .15em;
          word-break: break-all;
          word-break: break-word;

          & + .type {
            display: none;
          }
        }
      }

      .type {
        margin-left: 7px;
        opacity: .5;
      }
    }

    .progress {
      height: 38px;

      progress {
        display: block;
        width: 194px;
        height: 2px;
        color: var(--white);
        border: none;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, .2);
        appearance: none;

        &::-moz-progress-bar {
          background-color: var(--white);
        }

        &::-webkit-progress-inner-element,
        &::-webkit-progress-bar,
        &::-webkit-progress-value {
          overflow: hidden;
          border-radius: 4px;
        }

        &::-webkit-progress-bar {
          background-color: rgba(255, 255, 255, .2);
        }

        &::-webkit-progress-value {
          opacity: 1;
          background-color: var(--white);
        }
      }
    }
  }

  .models-videos {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
    transform-style: preserve-3d;
    transition: opacity .3s .1s cubic-bezier(.6, 0, 1, .4);
    
    @media (--nav-tablet-max-width) {
      position: relative;
    }

    .model-video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .3s 0s;

      @media (--nav-tablet-max-width) {
        height: 75vw;
        top: 50%;
        transform: translate3d(0, -60%, 0);
      }

      &.active {
        opacity: 1;
        transition: opacity .4s 0s;
        z-index: 10;

        video,
        img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 11;
        }
      }
    }

    video {
      opacity: 0;
      transition: opacity .2s .2s;

      &.ready {
        opacity: 1;
      }
      
      @media (--nav-desktop-min-width) {
        display: block;
        &.ie11 {
          display: none;
        }
      }
    }

    img {
      @media (--nav-desktop-min-width) {
        display: none;

        &.ie11 {
          display: block;
        }
      }
    }

    video, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
      pointer-events: none;
      transform: translateZ(0);

      @media (--nav-tablet-max-width) {
        opacity: 1;
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 2%, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 75%);
        width: 100vw;
      }
    }

    &::after {
      position: absolute;
      content: '';
      display: none;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      background-color: color(var(--black-pearl) a(40%));
      z-index: 10;

      @media (--nav-desktop-min-width) {
        display: block;
      }
    }
  }

  .model-view-link {
    position: absolute;
    bottom: 0;
    left: 50%;
    font-size: 1rem;
    text-transform: uppercase;
    color: inherit;
    transform: translateX(-50%);
    z-index: 11;

    @media (--nav-desktop-min-width) {
      width: 80%;
      height: 50%;
      top: 59%;
      left: 50%;
      overflow: hidden;
      opacity: .4;
      font-size: 0;
      text-align: center;
      color: var(--transparent);
      border-radius: 50%;
      transform: translate3d(-50%, -50%, 0);
      user-select: none;
    }
  }

  .models-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin: 20px auto;
    opacity: 1;
    text-align: center;
    user-select: none;

    @media (--nav-desktop-min-width) {
      display: none;
    }

    .prev,
    .next {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 32px;
      padding: 14px;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      pointer-events: auto;
      border: 2px solid color(var(--white) a(20%));
      border-radius: 50%;
      background: none;
      transition: all .3s;
      appearance: none;

      svg {
        position: absolute;
        width: 7.5px;
        top: 50%;
        left: 15px;
        transform: translateY(-50%) translateX(-50%);
      }

      &.prev {
        transform: scaleX(-1);
      }
    }

    .models-indicators {
      position: relative;
      display: inline-block;
      width: 100%;

      button {
        position: relative;
        padding: 8px;
        margin-left: 1px;
        cursor: pointer;
        background: var(--transparent);
        appearance: none;

        &:first-child {
          margin-left: 0;
        }

        &::before {
          content: '';
          display: inline-block;
          width: 3px;
          height: 3px;
          border: 2px solid var(--white);
          border-radius: 6px;
          transition: width .3s;
          appearance: none;
        }

        &.active::before {
          width: 24px;
        }
      }
    }
  }

  &.active {
    .close-sub-nav {
      display: block;
    }
    nav.model-nav {
      ul li a {
        @media(--nav-desktop-min-width) {
          pointer-events: all;
        }
      }
      &.active {
        ul li a {
          @media(--nav-tablet-max-width) {
            pointer-events: all;
          }
        }
      }
    }
  }

  .gradient-top {
    position: fixed;
    content: '';
    display: block;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1000;
    opacity: 0;
  }

  &.active .gradient-top {
    @media (--nav-tablet-max-width) {
      opacity: 1;
      pointer-events: all;
    }
  }
}

body.modelBlackText {
  @media (--nav-tablet-max-width) {
    .sub-navigation .models {
      color: var(--black-pearl);
      
      .close-sub-nav,
      .active-model {
        svg path {
          stroke: var(--black-pearl) !important;
        }
      }

      .stats .progress progress {
        color: var(--black-pearl);
        background-color: rgba(22, 23, 24, .2);

        &::-moz-progress-bar {
          background-color: var(--black-pearl);
        }

        &::-webkit-progress-bar {
          background-color: rgba(22, 23, 24, .2);
        }

        &::-webkit-progress-value {
          background-color: var(--black-pearl);
        }
      }

      .models-controls button {
        border-color: color(var(--black-pearl) a(20%));

        &::before {
          border-color: var(--black-pearl);
        }

        polygon {
          fill: var(--black-pearl);
        }
      }
    }
    .model-sub-navigation nav {
      border-color: rgba(22, 23, 24, .2);

      a {
        color: var(--black-pearl);

        &::after {
          border-color: rgba(22, 23, 24, .4);
        }
      }
    }
  }
}

.model-sub-navigation {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 155px;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transform: translateY(24px);
  transition: transform .5s cubic-bezier(.8, 0, .2, 1), opacity .15s linear;
  box-sizing: border-box;

  &.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
    transition: transform .5s cubic-bezier(.8, 0, .2, 1), opacity .25s linear;
  }

  @media (--nav-tablet-max-width) {
    position: static;
    bottom: 8px;
    padding: 0 20px;
  }

  nav {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-top: 1px solid rgba(255, 255, 255, .2);

    @media (--nav-tablet-max-width) {
      width: 100%;
    }

    ul {
      display: flex;
      align-self: center;
      width: auto;
      list-style: none;
      margin: 0;

      @media (--nav-tablet-max-width) {
        flex-direction: column;
        align-self: flex-start;
        width: 100%;
      }

      li {
        margin-left: 48px;

        &:first-child {
          margin-left: 0;
        }

        @media (--nav-tablet-max-width) {
          align-self: flex-start;
          margin-bottom: .8em;
          margin-left: 0;
        }
      }
    }

    a {
      position: relative;
      font-size: 24px;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1.75em;
      color: var(--white);
      white-space: nowrap;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        left: -1px;
        margin-top: 2px;
        opacity: 0;
        transition: opacity .2s .2s;
        border-bottom: 2px solid rgba(255, 255, 255, .4);
      }

      &:hover::after {
        opacity: 1;

        @media (pointer: fine) {
          transition: opacity .2s;
        }
      }
    }
  }
}
