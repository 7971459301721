.footer.footer--location-active .location-list {
  display: block;
  position: absolute;
  top: 0;
  z-index: var(--zi-footer-locations-list);
  max-width: 100%;

  /* IE */
  top:0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.footer--location-active .footer-primary {
  position: relative;
}

.footer .location-switch {
  cursor: pointer;
}

.footer .location-switch .icon {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.footer-primary .location-list {
  display: none;
  flex-direction: row;
  padding: 50px 0;
  background-color: var(--blackFooter);

  &__header {
    position: relative;
    display: block;
    margin-bottom: 35px;
    text-align: center;
    color: var(--whiteFooter);

    h5 {
      font-size: 16px;
      line-height: 16px;
    }

    .close {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      right: 2%;

      &:hover .icon {
        color: var(--white);
      }

      .icon {
        position: absolute;
        width: 100%;
        height: 100%;
        color: color(var(--white) alpha(50%));
        cursor: pointer;
        transform: translateY(-50%);
        transition: color 0.3s var(--ease-in-out-sine);
      }
    }
  }

  &__body {
    text-align: left;
    background-color: var(--blackFooter);
    padding-bottom: 80px;

    li {
      position: relative;
      display: inline-block;
      width: 50%;
      padding: 21px 0 20px;
      vertical-align: top;
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;
      color: color(var(--white) alpha(50%));

      @media (--medium-and-small-viewport) {
        width: 100%;
      }

      .icon {
        position: absolute;
        display: none;
        width: 15px;
        height: 15px;
        top: 50%;
        right: 25px;
        color: color(var(--white) alpha(50%));
        cursor: pointer;
        transform: translateY(-50%);
        transition: color 0.3s var(--ease-in-out-sine);
      }

      &.active {
        .icon {
          display: block;
        }

        .icon,
        a {
          color: var(--white);
        }
      }

      a {
        display: block;
        color: color(var(--white) alpha(50%));
        cursor: pointer;
        transition: color 0.3s var(--ease-in-out-sine);
      }

      &:hover a {
        color: var(--white);
      }

      &:nth-child(odd) {
        padding-right: 10px;

        &::after {
          left: 0;
          right: 10px;
        }
      }

      &:nth-child(even) {
        padding-left: 10px;

        @media (--medium-and-small-viewport) {
          padding-left: 0;
        }

        .icon {
          right: 15px;
        }

        &::after {
          left: 10px;
          right: 0;

          @media (--medium-and-small-viewport) {
            left: 0;
          }
        }
      }

      &::after {
        position: absolute;
        content: "";
        height: 1px;
        bottom: 0;
        background: color(var(--white) alpha(20%));
      }
    }
  }
}
