.conditions-block {
  padding-top: 80px;
  padding-bottom: 80px;
  color: var(--darkGrey);
  
  &__subtitle {
    display: block;
    margin-bottom: 25px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;

    @media (--large-viewport) {
      margin-bottom: 32px;
    }
  }

  &__title {
    display: block;
    margin-bottom: 50px;
    font-size: 2.25rem;
    line-height: 3.125rem;
    letter-spacing: .4px;

    @media (--large-viewport) {
      margin-bottom: 56px;
      font-size: 3.625rem;
      line-height: 3.625rem;
    }
  }

  &__content {
    display: block;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    line-height: 1.875rem;
  }
}

[ol-container].var-dark .conditions-block {
  color: var(--offWhite);
}
