
symbol {
    &#icon-arrow-right,
    &#icon-chevron-left,
    &#icon-chevron-left-bold,
    &#icon-chevron-right,
    &#icon-comment,
    &#icon-favourite,
    &#icon-plus {
        fill: none;
        stroke: currentColor;
    }
}

symbol {
    &#icon-pin-marker {
        fill: none;
        stroke: none;
        circle {
            stroke: currentColor;
        } 
        path {
            fill: currentColor;
        }
    }
}