.promo-swiper {
    &.swiper-container {
        padding-left: 15px;
        padding-right: 15px;
        @media (--normal-and-large-viewport) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}



.promo__item {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-bottom: 1px solid var(--white20);
    transition: border-bottom .3s;

    @media (--large-viewport) {
        width: calc(100% / 3);
    }

    &.active,
    &:hover,
    &:focus {
        border-bottom: 1px solid var(--lightgrey);
        .promo__pic::after {
            opacity: 0;
            transition: .3s;
        }
        .promo__label {
            color: var(--lightgrey);
        }
    }

    .promo__pic {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 100%;

        @media (--up-to-large-viewport) {
            padding-top: 50%;
        }

        > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: .4;
            background-color: var(--black);
            transition: .3s;
        }
    }

    .promo__label {
        font-family: Optima; /* font-family: GTAmerica; */
        color: var(--white20);
        transition: color .3s;
        margin-top: 24px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: normal;
        line-height: 29.76px;
        min-height: 60px;

        @media (--large-viewport) {
            font-size: 24px;
            font-weight: 600;
            line-height: 40px;
            min-height: 80px;
        }

        .icon {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-left: 8px;
            & svg {
                height: 100%;
                fill: currentColor;
            }
            @media (--large-viewport) {
                display: none;
            }
        }
    }

}

