.promo-video {
    @util aspect-ratio(1280:478);
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;

    .video__inner-media {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;

      .cover-image,
      .video {
        @util position(
          absolute,
          0,
          30px,
          null,
          30px
        );
        @util size(auto, 100%);
      }

      .icon-play {
        opacity: 0;
        pointer-events: none;
        transition: opacity .225s;
        @util center;
        display: block;
        width: 76px;
        height: 76px;
        padding: 30px;
        border-radius: 50%;
        background-color: var(--black10);
        backdrop-filter: blur(20px);

        svg {
          position: static;
          stroke: var(--offWhite);
          stroke-width: 10px;
          height: auto;
        }
      }

      .video--loaded + .icon,
      .video--playing + .icon {
        opacity: 1;
      }

      .no-touch & .video--loaded + .icon,
      .no-touch & .video--playing + .icon {
        opacity: 1;
      }

      .touch & .icon {
        width: 52px;
        height: 52px;
        padding: 18px;
      }

      &[tabindex]:focus {
        outline: none;

        .icon {
          outline: color(var(--white) a(50%)) dotted 1px;
          outline-offset: 5px;
        }

      }

    }

    @media (--medium-and-small-viewport) {
      margin-top: 16px;
      margin-bottom: 16px;

      .video__inner-media {
        padding: 0 var(--baseSize);

        .cover-image,
        .video {
          @util position(
            absolute,
            0,
            16px,
            null,
            16px
          );
          @util size(auto, 100%);
        }

      }

    }

    .video__cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.var-dark {
      background: none !important;
    }
}
