/**
 * @license
 * MyFonts Webfont Build ID 3418707, 2017-07-10T08:42:49-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: OptimaLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/optima/pro-roman/
 * Licensed pageviews: 5,000,000
 *
 * Webfont: OptimaLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/optima/pro-bold/
 * Licensed pageviews: 2,500,000
 *
 * Webfont: OptimaLTPro-Italic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/optima/pro-italic/
 * Licensed pageviews: 5,000,000
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3418707
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing
 * the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com.
 * All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 *
 * © 2017 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
/* @import url('https://hello.myfonts.net/count/342a53'); */

@font-face {
  font-family: Optima;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url('../fonts/342A53_0_0.woff2') format('woff2'),
    url('../fonts/342A53_0_0.woff') format('woff');
}

@font-face {
  font-family: Optima;
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src:
    url('../fonts/342A53_2_0.woff2') format('woff2'),
    url('../fonts/342A53_2_0.woff') format('woff');
}

@font-face {
  font-family: Optima;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    url('../fonts/342A53_1_0.woff2') format('woff2'),
    url('../fonts/342A53_1_0.woff') format('woff');
}

@font-face {
  font-family: LocalSimHei;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local('SimHei');
}

@font-face {
  font-family: GTAmerica;
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/GT-America-Standard-Regular.woff') format('woff');
}

@font-face {
  font-family: GTAmerica;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/GT-America-Standard-Medium.woff') format('woff');
}

@font-face {
  font-family: GTAmerica;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/GT-America-Standard-Light.woff') format('woff');
}

@font-face {
  font-family: GTAmerica;
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/GT-America-Standard-Ultra-Light.woff') format('woff');
}