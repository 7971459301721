.exterior-block {
  color: var(--darkGrey);
  @media (--up-to-large-viewport) {
    padding: 80px 0;
  }
  &__title-section {
    @media (--large-viewport) {
      position: absolute;
      top: 120px;
      z-index: 3;
      max-width: 400px;
    }
    @media (--nav-desktop-min-width) {
      max-width: 500px;
    }
  }
  &__title {
    font-family: Optima;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 140%;
    letter-spacing: 0.4px;
    @media (--up-to-large-viewport) {
      margin-bottom: 54px;
    }
  }
  &__sub-title {
    font-family: Optima; /* font-family: GTAmerica; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 186%;
    margin-bottom: 32px;
    @media (--medium-and-small-viewport) {
      margin-bottom: 24px;
    }
  }
  &__item {
    @media (--large-viewport) {
      width: calc(100% + 40px);
    }

    &-pic {
      display: block;
      height: 75vh;
      min-height: 400px;
      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        margin-left: -16px;
        height: 45vh;
        min-height: 300px;
      }
      @media (--up-to-large-viewport) {
        margin-bottom: 48px;
      }
      @media (--large-viewport) {
        position: fixed;
        width: 45%;
        height: 100vh;
        min-height: 100%;
        right: 0;
        top: 0;
        opacity: 0;
        transition: 0.5s;

        &.active {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-text {
      @media (--large-viewport) {
        position: relative;
        z-index: 2;
        padding-right: 70%;
      }
      .heading {
        font-family: Optima; /* font-family: GTAmerica; */
        font-weight: 600;
        font-size: 16px;
        line-height: 186%;
        margin-bottom: 16px;
      }
      .desc {
        font-family: Optima; /* font-family: GTAmerica; */
        font-size: 16px;
        line-height: 186%;
        @media (--large-viewport) {
          padding-bottom: 60px;
        }
      }
      strong {
        font-weight: 700;
      }
    }
    &:first-child {
      .exterior-block__item-text {
        @media (--large-viewport) {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          min-height: 100vh;
          padding-top: 450px;
        }
      }
    }
    &:not(:first-child) {
      .exterior-block__item-text {
        @media (--large-viewport) {
          padding-top: calc(100vh - 200px);
        }
      }
    }
    &:not(:last-child) {
      @media (--up-to-large-viewport) {
        margin-bottom: 120px;
      }
    }
  }
}

.var-dark {
  .exterior-block {
    color: var(--offWhite);
  }
}
