:root {
  --zi-models-header-blur: 1;
  --zi-models-header-text: 5;
  --zi-models-header-box: 6;
  --models-header-desktop-nav-offset: 100px;
}

.models-header-banner {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--models-header-desktop-nav-offset);

  .blur-backdrop {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: var(--zi-models-header-blur);
    background: rgba(22, 26, 33, 0.8);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    opacity: 0;
    transform: translateZ(0);
    will-change: opacity;

    @media (--large-viewport) {
      background: rgba(22, 26, 33, 0.8);
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);
    }
  }

  .picture-cover,
  .full-bleed-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .model-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 0;
    will-change: opacity;
  }

  .picture-cover {
    position: absolute;
    opacity: 0;
    transition: opacity 400ms ease-in-out 300ms;
    will-change: opacity;
    transform: translateZ(0);

    &.active {
      opacity: 1;
      transition: all 600ms ease-in-out;
    }
  }

  .full-bleed-background {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .header-content {
    height: 100%;
  }


  .header-text {
    position: relative;
    height: 100%;
    width: 100%;
    font-family: Optima; /* font-family: GTAmerica; */
    color: var(--lightgrey);
    z-index: var(--zi-models-header-text);
    overflow: hidden;

    .small-header-text {
      font-size: 20px;
      line-height: 25px;
      font-weight: 600;

      @media (--up-to-large-viewport) {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.8px;
      }
    }

    .big-header-text {
      font-family: Optima;
      font-style: normal;
      font-weight: normal;
      font-size: 80px;
      line-height: normal;

      @media (--up-to-large-viewport) {
        font-size: 58px;
        line-height: normal;
      }
      & > span {
        display: block;
        overflow: hidden;
        & > span {
          display: block;
          transform: translateY(100%);
          transition: transform 500ms ease-in-out 0.5s;
        }
        &:nth-child(2) {
          & > span {
            transition: transform 500ms ease-in-out 0.7s;
          }
        }
      }

      &.active {
        & > span > span {
          transform: translate(0);
        }
      }
    }
  }
  
  .header-model-box {
    position: relative;
    display: block;
    float: right;
    min-width: 340px;
    background: rgba(22, 26, 33, 0.1);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    z-index: var(--zi-models-header-box);

    max-height: 52px;
    overflow: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in-out .9s, max-height 1200ms ease-in-out .9s;

    @media (--medium-and-small-viewport) {
      display: none;
    }

    html.ie &, html.firefox & {
      background: var(--darkGrey90);
    }

    &.active {
      opacity: 1;
      max-height: 600px;

      .model-stats {
        .model-stat {
          opacity: 1;
        }
      }
    }

    &__nav {
      position: relative;
      white-space: nowrap;

      button {
        padding: 16px 20px;
        background: none;
        appearance: none;
        font-family: Optima; /* font-family: GTAmerica; */
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--white);
        cursor: pointer;
        user-select: none;

        &.no-outline {
          outline: none;
        }
      }
      .active-line {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        background-color: white;
      }
    }

    .model-image {
      position: relative;
      padding-top: 46.1538%;
    }
  }

  .model-stats {
    padding: 24px 120px 24px 24px;

    .model-stat {
      position: relative;
      opacity: 0;
      transition: opacity 300ms ease-in-out 1.6s;

      &:nth-child(2) {
        transition: opacity 300ms ease-in-out 1.75s;
      }

      &:nth-child(3) {
        transition: opacity 300ms ease-in-out 1.9s;
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &__info {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 1.15px;
        color: var(--white);
        &--name {
          font-family: Optima; /* font-family: GTAmerica; */
          font-weight: 400;
        }
        &--unit {
          margin-left: 6px;
          font-family: Optima; /* font-family: GTAmerica; */
          font-weight: 200;
          opacity: .6;
        }
      }
      &__line {
        position: relative;
        display: block;
        width: 100%;
        height: 2px;
        /* padding-right: 96px; */
        background: rgba(255, 255, 255, 0.2);

        &--val {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: var(--white);
          transition: width 200ms ease-in-out;
        }
      }
      &__value {
        position: absolute;
        width: 96px;
        right: -96px;
        bottom: -5px;
        text-align: left;
        padding-left: 20px;
        font-family: Optima;
        font-size: 26px;
        line-height: 24px;
        color: var(--white);
        opacity: 1;
        transition: opacity 100ms ease-in-out;

        &.blink {
          opacity: 0;
          transition: opacity 100ms ease-in-out 350ms;
        }
      }
    }
  }
}
