.promo-block {
  padding-bottom: 60px;

  &__container {
    transform: translateY(60px);
    opacity: 0;
    position: relative;
    margin: 0 40px;
    height: 80vh;
    min-height: 420px;
    @media (--up-to-large-viewport) {
      margin: 0;
    }
    &--animate {
      transform: translateY(0px);
      opacity: 1;
      transition: opacity 0.5s linear, transform 0.4s linear;
    }
    &--reset {
       {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__text-box {
    position: absolute;
    top: 60px;
    left: 50px;
    right: 50px;
    @media (--medium-and-small-viewport) {
      top: 40px;
      left: 20px;
      right: 20px;
    }
    .promo {
      &-subtitle {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
        line-height: 1.86;
        color: var(--white);
        &__separator {
          color: var(--white60);
          text-align: center;
          font-size: 12px;
        }
        & span:not(:last-child) {
          margin-right: 10px;
        }
      }
      &-title {
        font-size: 66px;
        max-width: 500px;
        line-height: 1;
        color: var(--lightgrey);
        @media (--medium-and-small-viewport) {
          font-size: 36px;
          max-width: 300px;
          line-height: 1.4;
          letter-spacing: 0.4px;
        }
      }
    }
  }
  &__button-box {
    position: absolute;
    bottom: 30px;
    left: 50px;
    right: 50px;
    @media (--medium-and-small-viewport) {
      bottom: 24px;
      left: 20px;
      right: 20px;
    }

    .promo-button {
      padding: 20px 45px;
      color: var(--offWhite1);
      text-decoration: none;
      border-left: 1px solid var(--offWhite);
      background-color: var(--darkGrey20);
      backdrop-filter: blur(40px);
      font-size: 16px;
      font-weight: bold;
      display: inline-flex;
      margin-top: 10px;
      min-width: 200px;
      text-align: center;
      margin-right: 10px;
      & span {
        vertical-align: middle;
      }
      &__icon {
        position: relative;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 8px;
        & svg {
          height: 100%;
          fill: var(--white);
        }
      }
      &:hover, &:focus {
        background-color: var(--darkGrey30);
      }
    }
  }
}
