.model-promo {
  display: flex;
  flex-direction: column;

  @media (--large-viewport) {
    flex-direction: row;
  }

  &__item {
    position: relative;
    display: block;
    flex: 1 0 100%;
    height: 50vh;
    min-height: 50vh;
    padding: 30px;

    @media (--large-viewport) {
      flex-basis: 50%;
      height: 60vh;
      min-height: 60vh;
    }
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: .2;
      background-color: var(--black);
      transition: .3s;
    }
  }

  &__item:hover & {

    &__picture:after {
      opacity: 0;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    position: relative;
    color: var(--white);
  }

  &__subtitle {
    margin-bottom: 5px;
    font-family: Optima; /* font-family: GTAmerica; */
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;
  }

  &__title {
    font-size: 3.625rem;
    line-height: 3.625rem;
  }
}
