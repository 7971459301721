.promo-media {
  padding-top: 70px;
  padding-bottom: 40px;
  background-color: var(--darkGrey00);

  @media (--large-viewport) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &-cont {
      position: relative;
      padding-bottom: 60%;

      @media (--large-viewport) {
        padding-bottom: 45%;
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
