
.overlay.enquire2--overlay  {

    .form {
        .select {
            border-bottom: 1px solid #D0D1D1;
            flex-basis: 36px;
            height: 36px;
            &:focus {
                border-bottom: 2px solid #A47B58;
            }
        }
        .select select {
            color: #737475;
            padding: 0; 
            font-size: 16px;
            opacity: 1;
        }
        .select__mimic {
            padding-left: 0;
            line-height: 40px;
            opacity: 0;
        }
        input[type='text'] {
            color: #161718;
            padding: 0;
            font-size: 16px;
            border-bottom: 1px solid #D0D1D1;
            &:active, &:focus {
                border-bottom: 2px solid #A47B58;
            }
            &::placeholder {
                color: #737475;
            }
        }
        label {
            color: #737475;
            position: absolute;
            top: -14px;
            text-transform: uppercase;
        }
        [data-form-element='AdditionalComments'] {
            label {
                margin-bottom: 12px;
            }
            textarea {
                color: #161718;
                border: 1px solid #D0D1D1;
                margin-top: 12px;
                font-size: 16px;
                resize: none;
                text-transform: none;

                &:active, &:focus {
                    border: 2px solid #A47B58;
                }
                &::placeholder {
                    color: #161718 !important;
                    line-height: 1 !important;
                }
            }
        }
        button[type="submit"] {
            color: white;
        }
        .error-msg {
            left: 0;
            color: #C79668;
        }

        .error {
            .select {
                border-bottom: 3px solid #A47B58;
            }
            input[type='text'] {
                border-bottom: 3px solid #A47B58;
            }
        }
        .checkbox {
            .icon {
                color: var(--gold);
                border: 1px solid var(--gray);
            }
            label {
                position: static;
            }
        }
        
    }

    .enquire-step[data-enquire-step='2'] {

        padding-top: 44px;

    }

    .enquire-step[data-enquire-step='3'] {

        padding-top: 44px;

        .body-copy__small-text {
            p {
                font-size: 12px;
                line-height: 18px;
                margin-bottom: 12px;
            }
            a {
                border-bottom: 1px solid #161718;
                background: none;
                color: #161718;
                letter-spacing: 2px;
                &:hover, &:focus {
                    border-bottom: 1px solid #161718;
                }
            }
        }

        .body-copy ul li {
            font-size: 12px;
            line-height: 18px;
            margin-bottom: 12px;
        }
    }
    
    .enquire-step[data-enquire-step='4'] {

        padding-top: 44px;

        .role--confirm, [data-role='confirm'] {
            font-size: 14px;
            line-height: 28px;
            color: #161718;
            display: none;
            &.active {
                display: block;
            }

            @media (--normal-viewport) {
                .grid--wide .col--4-12 {
                    flex: 1;
                    max-width: none;
                }
            }

            .dealership-card {
                width: 100%;
                box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.1);
                margin-bottom: 58px;

                @media (--normal-and-large-viewport) {
                    max-width: 430px;
                }

                .map-box {
                    position: relative;
                    
                    [data-enquire-map] {
                        width: 100%;
                        height: auto;
                    }
                    [data-enquire-mappin] {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }


                .paper {
                    padding: 24px 24px 32px 24px;
                }

                .big-heading {
                    font-size: 25px;
                    line-height: 30px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: 24px;
                    @media (--normal-and-large-viewport) {
                        font-size: 40px;
                        line-height: 52px;
                    }
                }

                .small-heading {
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin-bottom: 8px;
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                p {
                    margin-bottom: 16px;
                }

                .hours {
                    margin-bottom: 32px;
                }

                .directions {
                    font-size: 12px;
                    line-height: 16px;
                    margin-bottom: 16px;
                }

                .visit {
                    font-size: 12px;
                    line-height: 16px;
                }

            }

            .data-card {
                .small-heading {
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #E5E5E5;
                    padding-bottom: 16px;
                    @media (--normal-and-large-viewport) {
                        margin-bottom: 24px;
                    }
                }

                p {
                    font-size: 14px;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @media (--normal-and-large-viewport) {
                        font-size: 25px;
                        line-height: 30px;
                        margin-bottom: 16px;
                    }

                    .hidden {
                        display: none;
                    }
                }

                .details-box {
                    margin-bottom: 48px;
                }
                
            }
            
        }

        .role--thank-you, [data-role='thank-you'] {
            display: none;
            &.active {
                display: block;
            }
            h3 {
                margin-bottom: calc(26px + 14 * ((100vw - 375px) / 1650));
            }
        }

        .flex-start {
            align-self: flex-start;
        }

    }

}
