.video {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &[data-video-trigger='click'] {
    cursor: pointer;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    color: color(var(--white));
    transform: translate(-50%, -50%);
    @util size(32px);

    .loader-icon {
      position: relative;
      display: block;
      animation-name: loader-animation;
      animation-duration: 2s;
      animation-timing-function: var(--ease-in-out-sine);
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: forward;
      animation-fill-mode: both;
      @util size(100%);
    }

    .icon {
      @util size(100%);
    }

  }

  &__player {
    position: relative;
    overflow: hidden;
    @util size(100%);

    video {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      object-fit: cover;
      @util size(100%);

      .ie & {
        height: auto;
      }
    }

  }

  &__cover,
  &__controls,
  &__hidden-layer {
    @util position(absolute, 0, 0, 0, 0);
  }

  &__cover {
    opacity: 1;
    background-color: var(--black);
    transition: opacity;
    transition-duration: .35s;
    transition-timing-function: linear;

    .landscape,
    .portrait {
      opacity: 0;
      background: center / cover no-repeat;
      transition: opacity .6s var(--ease-out-sine);
      @util position(absolute, 0, 0, 0, 0);
    }

    &--hidden {
      display: none;
    }

    @media (orientation: landscape) {

      .landscape {
        opacity: 1;
      }

    }

    @media (orientation: portrait) {

      .portrait {
        opacity: 1;
      }

    }

  }

  &__controls {
    display: none;
    color: var(--white);

    .control-buttons {
      position: absolute;
      display: flex;
      color: var(--white);
      cursor: pointer;

      [dir='rtl'] & {
        direction: ltr;
      }

      .icon {
        position: absolute;
        top: 0;
        left: 0;
        @util size(100%);
      }

      .close-button {
        outline: none;

        &:focus {

          .icon {
            outline: color(var(--white) a(50%)) dotted 1px;
            outline-offset: 5px;
          }

        }

        .icon {
          top: 0;
          left: auto;
          right: 0;
          @util size(16px);
        }

      }

      &.control-buttons--bottom-left {
        bottom: 28px;
        left: 28px;
      }

      &.control-buttons--bottom-right {
        bottom: 28px;
        right: 28px;
      }

      &.control-buttons--top-left {
        top: 28px;
        left: 28px;
      }

      &.control-buttons--top-right {
        top: 28px;
        right: 28px;
      }

      .control-button {
        position: relative;
        display: none;
        @util size(45px);

        &[data-video-control='menu'] {
          margin-right: 5px;
        }

        &.control-button--active {
          display: block;
        }

      }

    }

    .progress {
      width: 100%;
      height: 4px;
      background: currentColor;
      transform: scaleX(0);
      transform-origin: left top;
      @util position(absolute, null, null, 1px, 0);
    }

  }

  &--loaded {

    .video__loader {
      display: none;
    }

    .video__player {
      visibility: visible;
    }

  }

  &--hide-poster {

    .no-touch & .video__cover {
      display: none;
      filter: none;
    }

  }

  &[data-video-controls-enabled='true'] .video__controls {
    display: block;

    @media (--medium-and-small-viewport) {
      display: none;
    }

  }

  &[data-video-controls-enabled='true'] .video__hidden-layer {
    display: none;
  }

  &[data-video-trigger='in-view'].video--loaded:not([data-video-mobile]),
  &--playing {

    .video__cover {
      opacity: 0;
    }

  }

}

.fullscreen-video {
  position: fixed;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10000;
  opacity: 0;
  transition: opacity .3s ease, width 0s linear .3s, height 0s linear .3s;

  &.fullscreen-video--active {
    opacity: 1;
    transition: opacity .3s ease, width 0s linear, height 0s linear;
    @util size(100%);
  }

}

@supports (-ms-ime-align:auto) {

  .content .video video {
    width: auto!important;
  }

}
