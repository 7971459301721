
.sticky-nav {
  position: fixed;
  color: white;
  background: var(--darkGrey20);
  backdrop-filter: blur(40px);
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 21px 0;
  overflow: hidden;
  transition: .6s;
  transform: translateY(-100%);
  opacity: 0;

  &.sticky {
    transform: translateY(0);
    opacity: 1;
  }

  html.ie & {
    overflow: visible;
  }
  
  html.ie &, html.firefox & {
    background: var(--darkGrey90);
  }

  @media (--up-to-large-viewport) {
    padding: 0;
    backdrop-filter: none;
    background: var(--darkGrey95);
    overflow: visible;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 57px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--white);
      opacity: 0.2;
    }

    &.mobile-active {
      height: 100%;
      .sticky-nav__container, .body-type-switch {
        height: 100%;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    
    html.ie & {
      overflow: hidden;
    }

    @media (--up-to-large-viewport) {
      flex-direction: column;
      overflow: visible;
    }
  }

  html.ie &.active &__container {
    overflow: visible;
  }

  &__mobile-top {
    display: none;
    @media (--up-to-large-viewport) {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
    }

    .back-icon {
      height: 20px;
      width: 20px;

      svg {
        height: 20px;
        width: 20px;
        fill: var(--white);
        transition: .2s;
        transform: rotate(90deg);
      }
    }

    .body-type__current {
      position: relative;
      padding-right: 16px;
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: var(white);
      transition: .3s;

      &__name {
        font-weight: 600;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid var(--white);
      }
    }
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    /* padding: 21px 0; */


    & > li {
      position: relative;
      padding-right: 37px;

      &.section-switch {
        padding-right: 0;
      }

      a {
        font-family: Optima; /* font-family: GTAmerica; */
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: var(--white);
      }

      &:after {
        content: '';
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 0; 
        height: 0; 
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 5px solid var(--white);
      }

      @media (--up-to-large-viewport) {
        &:not(.section-switch) {
          display: none;
        }
      }
    }
  }

  .section-switch {
    display: flex;
    align-items: center;
    padding-right: 0;

    &:after {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -21px;
      width: 100%;
      height: 78px;
    }

    &__area {
      content: '';
      position: fixed;
      display: block;
      width: 100vw;
      height: 0;
      left: 0;
      top: 78px;
      z-index: 0;
    }

    &__icon {
      position: relative;
      padding: 18px;
      margin-left: 16px;
      background: gray;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
      transition: opacity 300ms ease-in-out;

      span {
        position: absolute;
        top: 50%;
        left: 9px;
        transform: translateY(-50%);
        display: block;
        background-color: var(--white);
        width: 3px;
        height: 3px;
        border-radius: 50%;
    
        &:nth-of-type(2) {
          left: 17px;
        }
        &:nth-of-type(3) {
          left: 25px;
        }
      }

      @media (--up-to-large-viewport) {
        display: none;
      }
    }

    &.active .section-switch__icon {
      opacity: 0;
    }

    &__current {
      opacity: 0;
      user-select: none;
      pointer-events: none;
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;

      @media (--up-to-large-viewport) {
        display: none;
      }
    }

    @media (--up-to-large-viewport) {
      max-width: 100%;
    }

    &__menu {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -2px;

      @media (--up-to-large-viewport) {
        position: relative;
        display: flex;
        overflow-x: auto;
        padding: 18px 0;

        li {
          padding: 0;
          margin: 0;
          margin-right: 32px;
          opacity: 1;

          a {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: inherit;
          }

          &:hover {
            a {
              opacity: 0.6;
            }
            &.active a {
              opacity: 1;
            }
          }
        }
      }

      li {
        position: relative;
        display: block;
        padding: 10px 0;
        margin-bottom: 39px;
        white-space: nowrap;

        
        transition: opacity 300ms ease-in-out;
        opacity: 0;
        a {
          padding: 10px 0;
          opacity: 0.6;
        }
        &.active {
          opacity: 1;
          a {
            opacity: 1;
          }
        }

        &:before {
          content: '';
          position: absolute;
          display: none;
          left: -22px;
          top: 0;
          height: 100%;
          width: 1px;
          background: rgba(255, 255, 255, 0.4);
          transition: opacity 300ms ease-in-out;
          opacity: 0;
        }

        &:hover {
          a {
            opacity: 1;
          }
        }
      }

      &.active {
        li {
          opacity: 1;
          transition: opacity 500ms ease-in-out 0.1s;

          &:nth-child(2) {
            transition: opacity 500ms ease-in-out 0.2s;
          }
          &:nth-child(3) {
            transition: opacity 500ms ease-in-out 0.3s;
          }
          &:nth-child(4) {
            transition: opacity 500ms ease-in-out 0.4s;
          }
          &:nth-child(5) {
            transition: opacity 500ms ease-in-out 0.5s;
          }
          &:nth-child(6) {
            transition: opacity 500ms ease-in-out 0.6s;
          }

          &:before {
            display: block;
          }

          &:hover {
            &:before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .body-type-switch {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background-color: var(--offWhite20);
    }

    button {
      padding: 8px 60px 8px 0;
      background: none;
      appearance: none;
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--white);
      cursor: pointer;
      user-select: none;

      &.no-outline {
        outline: none;
      }
    }
    .active-line {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: white;
    }

    .close-icon {
      display: none;
      width: 56px;
      height: 56px;
      padding: 19px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      cursor: pointer;
      margin-top: 38px;
      margin-bottom: 70px;
      
      & svg {
        width: 100%;
        fill: var(--white);
      }

      &:focus {
        outline: var(--white50) dotted 1px;
        outline-offset: 5px;
      }
    }

    @media (--up-to-large-viewport) {
      position: fixed;
      display: flex;
      left: 0;
      top: 0;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      width: 100vw;
      height: 100vh;
      background: var(--darkGrey20);
      backdrop-filter: blur(40px);
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      user-select: none;

      transition: 0.3s;

      &.mobile-active {
        opacity: 1;
        pointer-events: all;
      }

      button {
        padding: 8px 4px;
        margin-bottom: 42px;

        &.active {
          border-bottom: 2px solid var(--white);
        }
      }

      .close-icon {
        display: block;
      }

      .active-line {
        display: none;
      }
    }
  }

  &.var-dark {
    background: var(--offWhite20);
    color: var(--darkGrey);

    html.ie &, html.firefox & {
      background: var(--white90);
    }

    &:after {
      background: var(--darkGrey);
    }

    .breadcrumbs>li {
      &:after {
        border-left-color: var(--darkGrey);
      }

      a {
        color: var(--darkGrey);
      }
    }

    .section-switch__icon { 
      background-color: rgba(0,0,0,0.1);
      span {
        background-color: var(--darkGrey);
      }
    }

    .section-switch__menu li:before {
      background: rgba(0,0,0, 0.4);
    }

    @media (--up-to-large-viewport) {
      background: var(--offWhite95);
    }

    @media (--large-viewport) {
      .body-type-switch {
        button {
          color: var(--darkGrey);
        }

        &:after {
          background-color: rgba(22, 26, 33, 0.2);
        }

        .active-line {
          background-color: var(--darkGrey);
        }
      }
    }

    .sticky-nav__mobile-top {
      .back-icon svg {
        fill: var(--darkGrey);
      }
      .body-type__current {
        color: var(--darkGrey);

        &:after {
          border-top-color: var(--darkGrey);
        }
      }
    }
  }
}

body.body-type-switch-open {
  @media (--up-to-large-viewport) {
    height: 100%;
    overflow: hidden;
  }
}
