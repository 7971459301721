.utility-bar {
  background-color: var(--almostBlack);
  padding: 63px 0 25px;
  border-bottom: 1px solid var(--offWhite20);
  @media (--medium-and-small-viewport) {
    padding: 60px 0 20px;
  }
  &__item {
    font-family: Optima; /* font-family: GTAmerica; */
    color: var(--offWhite);
    margin-bottom: 48px;

    .item {
      &-title {
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 13px;
        @media (--medium-and-small-viewport) {
          font-size: 16px;
          line-height: 1.86;
          margin-bottom: 8px;
        }
      }
      &-desc {
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 24px;
        @media (--medium-and-small-viewport) {
          margin-bottom: 18px;
        }
        @media (--large-viewport) {
          max-width: 230px;
        }
      }
    }
  }
}
