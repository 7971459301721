.sub-navigation {
  > nav,
  > section {
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
    pointer-events:none;
    background-color: var(--black-pearl);
    z-index: 990;

    @media (--nav-tablet-max-width) {
      padding: 80px 20px 0;
    }

    &.active {
      z-index: 999;
      opacity: 1;
      pointer-events: all;
    }
  }

  > nav {
    padding: 60px 155px 0;
    transition: transform .5s cubic-bezier(.8, 0, .2, 1), opacity .3s linear;

    @media (--nav-tablet-max-width) {
      transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity .25s .25s linear;
    }

    .sub-nav-container {
      display: block;
      padding-top: 15vh;

      @media (--nav-tablet-max-width) {
        padding: 30px 0 0;
        opacity: 0;
        transform: translateX(60px);
        transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity .25s linear;
      }
    }

    &.active {
      max-height: 100%;
      transition: transform .5s cubic-bezier(0, .7, .3, 1), opacity .25s .15s linear;
      overflow-y: auto;

      .sub-nav-container {
        @media (--nav-tablet-max-width) {
          opacity: 1;
          transform: translateX(0);
        }
      }
      ul li a {
        pointer-events: all;
        transform: none;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      font-size: inherit;
      list-style: none;

      @media (--nav-tablet-max-width) {
        display: block;
      }

      &.hover {
        li a {
          @media (--nav-desktop-min-width) {
            opacity:0.08;
          }
        }
      }
      
      li {
        position: relative;
        width: 50%;
        font-size: 2.875em;
        transition: opacity .2s;

        @media (--nav-tablet-max-width) {
          width: 100%;
          font-size: 2em;
        }

        a:hover {
          z-index: 3;
          color: var(--white);
          cursor: pointer;

          .sub-nav-title {
            z-index: 4;
          }

          @media (--nav-desktop-min-width) {
            opacity:1;

            .sub-nav-title .description-dash {
              opacity: 1;
            }

            .description {
              z-index: 4;
              opacity: 1;
              pointer-events: all;
            }
          }
        }

        @media (--nav-desktop-min-width) {
          &:nth-of-type(odd) a {
            padding-right: 10px;
          }

          &:nth-of-type(even) a {
            padding-left: 10px;
          }
        }
      }

      a {
        display: inline-block;
        width: auto;
        margin-bottom: 15vh;
        font-size: 2.667rem;
        text-decoration: none;
        color: var(--white);
        pointer-events: none;
        transform: translateX(-1000%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media (--nav-tablet-max-width) {
          padding: 16px 0;
          margin-bottom: 0;
          line-height: 1;
        }

        @media (--nav-desktop-min-width) {
          font-size: 3.883rem;
        }

        .sub-nav-title {
          position: relative;
          display: inline;
          z-index: 2;
          font-size: 32px;
          font-weight: normal;
          text-transform: none;
          word-break: break-all;
          word-break: break-word;

          .description-dash {
            display: none;
            opacity: 0;
            transition: opacity .2s;
          }

          @media (--nav-desktop-min-width) {
            font-size: 46px;

            .description-dash {
              display: inline;
            }
          }
        }

        .description {
          position: absolute;
          width: calc(100% - 40px);
          z-index: 2;
          opacity: 0;
          font-size: 26px;
          line-height: 38px;
          pointer-events: none;
          transition: opacity .25s;

          @media (--nav-tablet-max-width) {
            display: none;
          }
        }
        
        &:hover + img {
          @media (--nav-desktop-min-width) {
            opacity: 1;
          }
        }
      }

      img {
        position: fixed;
        max-width: 30vw;
        width: 30vw;
        top: 50%;
        left: 50%;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        transform: translate3d(-50%, -50%, 0);
        transition: opacity .2s;
      }

    }

    &.search {
      display: flex;
      justify-content: center;
      padding-top: 200px;
      
      form {
        width: calc(100vw - 310px);
      }
    }

    &::before {
      position: fixed;
      content: '';
      display: block;
      width: 100%;
      height: 80px;
      top: 0;
      left: 0;
      z-index: 1000;
      opacity: 0;
      pointer-events: none;
      background: linear-gradient(to bottom, rgba(22, 23, 24, 1) 60%, rgba(22, 23, 24, 0) 100%);
      transition: opacity .3s linear;
    }

    &.active {
      &::before {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
