.header {
  position: absolute;
  width: auto;
  height: var(--headerHeight);
  left: 0;
  right: 0;
  z-index: var(--zHigh);
  transform: translate3d(0, 0, 0);
  transition: transform .6s var(--ease-in-sine), opacity .6s var(--ease-in-sine);
  user-select: none;

  nav {
    @util size(100%);
  }

  &--fixed {
    position: fixed;
  }

  &__logo {
    padding: 20px;
    margin-top: -2px;
    overflow: hidden;
    box-sizing: content-box;
    @util center;
    @util size(169px, 18px);

    @media (--medium-and-small-viewport) {
      transition: opacity .4s var(--ease-out-sine);
    }

    .icon {
      @util size(100%);

      &__wrapper {
        top: -75px;
        transform: none;
        @util size(169px);
      }

      .header--dark:not(.left_nav):not(.right_nav) & {
        @media (--nav-desktop-min-width) {
          color: var(--black);
        }
      }

      .header--dark:not(.expose_nav)  & {
        @media (--nav-tablet-max-width) {
          color: var(--black);
        }
      }

    }

  }

  &.disable {
    height: 0;

    .header__logo {
      top: 32px;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
    }

  }

  &--hide {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  &--subnavigation-active {
    transform: translateY(-65px);
    animation-name: header-subnavigation-slide-down;
    animation-duration: .6s;
    animation-timing-function: var(--ease-out-sine);
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: forward;
    animation-fill-mode: both;

    .collection-menu {
      max-width: 15px;

      .title {
        opacity: 0;
      }
    }

    @media (--medium-and-small-viewport) {

      .collection-menu,
      .main-menu {
        @util size(15px);
      }

    }

  }

}

.collection-menu,
.main-menu {
  padding: 25px;
  cursor: pointer;
  transition: opacity .4s var(--ease-out-sine);
  box-sizing: content-box;
  -webkit-tap-highlight-color: color(var(--black) a(0%));
  touch-action: manipulation;
  -webkit-touch-callout: none;
  @util size(15px);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .icon-close {
    opacity: 0;
  }

  @media (--medium-and-small-viewport) {
    @util size(15px, 18px);
  }

  &[data-toggle-state='visible'] {

    .icon-close {
      opacity: 1;
      transition: opacity .35s linear .3s;
    }

  }

}

.collection-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: auto;
  max-width: 200px;
  left: 0;
  color: var(--white);
  @util position(absolute, 0, null, null, 0);

  @media (--medium-and-small-viewport) {
    max-width: 50px;
    padding: var(--baseSize) var(--baseSize) var(--baseSize) calc(var(--baseSize) / 2);
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    @util size(15px);

    @media (--medium-and-small-viewport) {
      left: calc(var(--baseSize) / 2);
    }

  }

  .icon-collection {
    opacity: 1;
    transition: opacity .35s linear .3s;
  }

  .title {
    padding-top: 2px;
    margin-left: 25px;
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;

    @media (--medium-and-small-viewport) {
      display: none;
    }

  }

  &[data-toggle-state='visible'] {

    .icon-collection {
      opacity: 0;
      transition: opacity .35s;
    }

  }

}

.main-menu {
  right: 0;
  color: var(--white);
  @util position(absolute, 0, 0, null, null);

  @media (--medium-and-small-viewport) {
    padding: var(--baseSize) calc(var(--baseSize) / 2) var(--baseSize) var(--baseSize);
  }

  .icon-main-menu {
    opacity: 1;
    transition: opacity .35s linear .3s;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    @util size(15px);

    @media (--medium-and-small-viewport) {
      right: calc(var(--baseSize) / 2);
    }

  }

  &[data-toggle-state='visible'] {

    .icon-main-menu {
      opacity: 0;
      transition: opacity .35s;
    }

  }

}

@keyframes header-subnavigation-slide-down {

  0% {
    transform: translateY(-65px);
  }

  100% {
    transform: translateY(0);
  }

}

