/* stylelint-disable color-no-hex, color-named, max-line-length */

:root {
  --white: #fff;
  --black: #000;
  --transparent: transparent;
  --yellow: #ff0;
  --red: #f00;
  --gray: #ccc;
  --dimgray: #8e8b87;
  --midgray: #535353;
  --mediumgray: #2f2f2f;
  --darkGray: #262626;
  --valkyriegray: #9b9fa2;
  --gold: #ae8057;
  --lightstone: #f9f9f9;
  --silver: #77736e;
  --asphalt: #5c6169;
  --sunshine: #b87811;
  --burgundy: #7d4e55;
  --caramel: #ae8057;
  --ashgrey: #5b5549;
  --teal: #485556;
  --cobalt: #657974;
  --smoke: #48434d;
  --aubergine: #27191f;
  --cmsblue: #74bdf3;
  --abbay: #4d4e53;
  --black-pearl: #161718;
  --input-border-grey: rgba(22,23,24,0.2);
  --colorNames: white, black, yellow, red, gray, dimgray, midgray, mediumgray, valkyriegray, gold, lightstone, silver, asphalt, sunshine, burgundy, caramel, ashgrey, teal, cobalt, smoke, aubergine, abbay;
  --colorValues: var(--white), var(--black), var(--yellow), var(--red), var(--gray), var(--dimgray), var(--midgray), var(--mediumgray), var(--valkyriegray), var(--midgray), var(--gold), var(--lightstone), var(--silver), var(--asphalt), var(--sunshine), var(--burgundy), var(--caramel), var(--ashgrey), var(--teal), var(--cobalt), var(--smoke), var(--aubergine), var(--abbay);
  --btnColorNames: white, black, yellow, red, gray, dimgray, midgray, mediumgray, valkyriegray, gold, lightstone, silver, asphalt, sunshine, burgundy, caramel, ashgrey, teal, cobalt, smoke, aubergine, abbay;
  --btnColorValues: var(--white), var(--black), var(--yellow), var(--red), var(--gray), var(--dimgray), var(--midgray), var(--mediumgray), var(--valkyriegray), var(--midgray), var(--gold), var(--lightstone), var(--silver), var(--asphalt), var(--sunshine), var(--burgundy), var(--caramel), var(--ashgrey), var(--teal), var(--cobalt), var(--smoke), var(--aubergine), var(--abbay);
  --form-main-color: var(--white);
  --form-text: var(--white);
  --text-dark: #161718;
  --blackFooter: #0E1116;
  --text-white-transparent: rgba(255,255,255,0.6);
  --almostBlack: #080B11;
  --offWhite: #f8f7f4;
  --offWhite20: rgba(248, 247, 244, 0.2);
  --whiteFooter: var(--white);
}
