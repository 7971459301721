
.enquire2--section {
    background: hsl(0, 0%, 95%);
    color: #000000;
    padding-top: calc(74px + 14 * ((100vw - 375px) / 1650));
    padding-bottom: calc(74px + 14 * ((100vw - 375px) / 1650));

    .body-copy__text {
        color: #000000;
        margin-bottom: calc(52px + 14 * ((100vw - 375px) / 1650));
    }

    .cta__buttons {
        display: flex;
        margin-bottom: 0;
        @media (--normal-and-large-viewport) {
            width: 50%;
        }
        @media (--medium-viewport) {
            width: 50%;
        }

        button {
            flex: 1 0 auto;
            margin-bottom: 20px;
            margin-right: 20px;
            color: #000000;

            @media (--medium-and-small-viewport) {
                margin-right: 0;
            }
        }

    }

    &.variant--dark {
        background: rgb(66, 62, 56);
        color: #ffffff;

        .cta__buttons {
            button {
                border-color: #686560;
                color: #ffffff;
            }
        }

        .body-copy__text {
            color: #ffffff;
        }

    }

}

.overlay.enquire2--overlay  {
    .close-button {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 0;
        z-index: 10;
        color: #fff;
        cursor: pointer;
        background: none;
        touch-action: manipulation;
        width: 17px;
        height: 17px;
        color: black;
    }

    a:focus, button:focus {
        outline-offset: 5px;
        outline: rgba(0, 0, 0, 0.5) dotted 1px;
    }

    .hidden {
        display: none;
    }

    #mappin-icon1, #mappin-icon2, #mappin-icon3 {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -10000px;
    }

    .overlay__header {
        transition: height .2s ease;
        position: absolute;
        z-index: 1;
        top: 0;
        width: 100%;
        height: 180px;
        background: #FFFFFF;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateZ(0);

        @media (--medium-and-small-viewport) {
            height: 80px;
            position: fixed;
            z-index: 1003;
        }

        .title {
            transition: opacity, height .3s ease;
            width: 100%;
            flex-grow: 1; 
            text-align: center;
            padding: 0 45px 0 45px;

            @media (--medium-and-small-viewport) {
                height: 0px;
                opacity: 0;
            }

            h1 {
                color: #161718;
                font-size: 40px;
                line-height: 100px;
                @media (--medium-and-small-viewport) {
                    font-size: 22px;
                    line-height: 22px;
                }

            }
        }
        .subtitle {
            display: none;
            text-align: center;
            padding: 0 45px;
            @media (--medium-and-small-viewport) {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 100%;
                h2 {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .steps-nav {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 32px;
            @media (--medium-and-small-viewport) {
                height: 35px;
                padding-bottom: 0px;
            }
            .step {
                padding: 10px 20px;
                margin: 0 20px;
                font-size: 15px;
                line-height: 23px;
                letter-spacing: 2px;
                text-transform: uppercase;
                cursor: pointer;
                border-bottom: 2px solid rgba(0,0,0,0.1);
                background: none;
                @media (--medium-and-small-viewport) {
                    padding: 0 20px;
                    flex: 1;
                    text-align: center;
                    margin: 0;
                    height: 100%;
                }
                .name {
                    @media (--medium-and-small-viewport) {
                        display: none;
                    }
                }
                &:disabled {
                    cursor: default;
                }
                &:not([disabled]) {
                    &.active, &:hover {
                        border-bottom: 2px solid #A47B58;
                    }
                }
                /* &:focus {
                    border-bottom: 2px solid #A47B58;
                } */
            }
        }
    }

    .overlay__header {
        &.submitted {
            box-shadow: none;
            .title, .subtitle, .steps-nav {
                display: none !important;
            }
        }
    }

    &.top-scrolled:not(.dealer-active) {
        & {
            .overlay__header {
                @media (--medium-and-small-viewport) {
                    height: 132px;
                    .title {
                        opacity: 1;
                        height: 62px;
                        padding: 32px 15px 14px 15px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .subtitle {
                        height: 37px;
                    }
                }

            }
        }
    }

    &.dealer-active {
        .overlay__body {
            @media (--medium-and-small-viewport) {
                padding-top: 80px;
            }
        }
    }

    .overlay__content {
        position: relative;
        overflow-y: auto;
        height: 100vh;
    }

    .overlay__body {
        height: 100vh;
        padding-top: 180px;
        
        @media (--medium-and-small-viewport) {
            height: auto;
            min-height: 100vh;
            padding-top: 132px;
        }

        .body-copy__text {
            color: #161718;
            margin-bottom: calc(26px + 14 * ((100vw - 375px) / 1650));
        }

        .body__buttons {
            display: flex;
            justify-content: center;

            .button {
                color: white;
                width: auto;
            }
        }

        .enquire-step {
            display: none;
            &.active {
                display: flex;
                @media (--medium-and-small-viewport) {
                    flex-direction: column;
                }
            }
        }

        .enquire-step[data-enquire-step='1'] {
            position: relative;
            @media (--medium-and-small-viewport) {
                padding-bottom: 54px;
            }
            
            .dealer-map {
                width: calc(100vw - 500px);
                height: calc(100vh - 180px);
                position: relative;

                .map-shadow-t {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 8px;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 58.33%);
                }

                @media (--normal-and-large-viewport) {
                    .map-shadow-l {
                        display: block;
                        position: absolute;
                        width: 8px;
                        height: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 58.33%);
                    }
                }

                #dealer-map {
                    height: 100%;
                    width: 100%;
                }

                * {
                    overflow:visible;
                }

                @media (--medium-and-small-viewport) {
                    width: 100vw;
                    height: 50vh;
                    order: 1;
                    &.dealer-active {
                        height: 25vh;
                    }
                }

            }

            .dealer-list {
                width: 500px;
                height: calc(100vh - 180px);
                color: #161718;
                @media (--normal-and-large-viewport) {
                    overflow-y: scroll;
                    scroll-behavior: smooth;
                }

                @media (--medium-and-small-viewport) {
                    width: 100vw;
                    height: auto;
                    order: 2;
                    z-index: 2;
                    position: relative;
                }

                .row-search {
                    display: flex;
                    width: 100%;
                    height: 70px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    @media (--medium-and-small-viewport) {
                        position: absolute;
                        top: -50vh;
                        z-index: 1002;
                        background: white;
                        width: calc(100vw - 30px);
                        margin: 15px;
                        height: auto;
                        border: 1px solid #CCCCCC;
                        box-sizing: border-box;
                        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
                        border-radius: 2px;
                            
                        .col1 {
                            width: 50px;
                            padding: 0;
                        }
                        .col2 {
                            padding: 0;
                        }
                    }

                    .col1 {
                        width: 80px;
                        padding: 17px 27px 17px 27px;
                        font-size: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .col2 {
                        width: 100%;
                        padding: 17px 27px 17px 27px;
                    }
                    .form {
                        position: relative;
                        .search-icon {
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            top: 50%;
                            transform: translateY(-50%);
                            @media (--medium-and-small-viewport) {
                                left: 10px;
                            }
                        }
                        /* Clearable text inputs */
                        .clearable{
                            display: inline-flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                        }
                        .clearable input[type=text]{
                            width: 100%;
                            box-sizing: border-box;
                        }
                        .clearable__clear {
                            display: block;
                            padding: 10px 6px;
                            font-style: normal;
                            font-size: 15px;
                            line-height: 15px;
                            user-select: none;
                            cursor: pointer;
                            background: none;
                            @media (--medium-and-small-viewport) {
                                padding: 10px 10px;
                            }
                        }
                        .clearable input::-ms-clear {  /* Remove IE default X */
                            display: none;
                        }
                        .input-search {
                            flex: 1;
                            padding: 0 0 0 50px;
                            overflow: hidden;
                            font-size: 14px;
                            text-transform: uppercase;
                            color: var(--black);
                            width: 100%;
                            font-weight: bold;

                            &::placeholder {
                                color: var(--black);
                                font-weight: bold;
                            }

                            @media (--medium-and-small-viewport) {
                                font-size: 16px;
                                font-weight: normal;
                                padding: 0 0 0 38px;
                                &::placeholder {
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                            }

                        }
                    }
                }

                .row-result {
                    transition: height .3s ease;
                    height: 98px;
                    overflow: hidden;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    .paper {
                        background: white;
                        width: 100%;
                        height: auto;
                        display: flex;
                    }

                    .col1 {
                        width: 80px;
                        padding: 27px;
                        font-size: 18px;
                        text-align: center;
                        @media (--medium-and-small-viewport) {
                            width: 55px;
                            padding: 27px 15px;
                        }
                    }
                    .col2 {
                        width: 100%;
                        padding: 27px 27px 27px 0;
                        font-size: 14px;
                        @media (--medium-and-small-viewport) {
                            padding: 27px 15px 27px 0;
                        }
                        .col2-pad {
                            padding-right: 48px;
                            @media (--medium-and-small-viewport) {
                                padding-right: 36px;
                            }
                        }
                        .title {
                            display: flex;
                            width: 100%;
                            font-size: 18px;
                            margin-bottom: 4px;
                            text-transform: uppercase;
                            position: relative;
                            color: #161718;
                            cursor: pointer;
                            
                            @media (--medium-and-small-viewport) {
                                font-size: 16px;
                            }
                            .left {
                                flex: 1 1 auto;
                            }
                            .right {
                                flex: 0 0 auto;
                                margin-left: 16px;
                            }
                            .tick {
                                background: none;
                                cursor: pointer;
                            }
                            .unit {
                                text-transform: uppercase;
                            }
                        }
                        .address {
                            margin-bottom: 12px;
                        }
                        .phone {
                            margin-bottom: 12px;
                            a {
                                color: #161718;
                            }
                        }
                        .desc {
                            display: block;
                            font-size: 14px;
                            margin-bottom: 28px;
                        }
                        .directions {
                            font-size: 12px;
                            margin-bottom: 16px; 
                        }
                        .visit {
                            font-size: 12px;
                            margin-bottom: 34px; 
                        }
                        .ctas {
                            /* display: flex;
                            align-items: stretch;
                            align-content: stretch; */
                            @media (--medium-and-small-viewport) {
                                display: block;
                            }
                        }
                        .send-enquiry {
                            flex: 1;
                            font-size: 10px;
                            line-height: 12px;
                            text-align: center;
                            letter-spacing: 1.16667px;
                            text-transform: uppercase;
                            background: #878787;
                            color: #FFFFFF;
                            border-radius: 4px;
                            padding: 14px 20px;
                            margin-right: 16px;
                            cursor: pointer;
                            transition: background-color .3s var(--ease-in-out-sine);
                            @media (--medium-and-small-viewport) {
                                width: 100%;
                                margin: 0 0 10px 0;
                            }
                            &:hover {
                                background: #A1A1A1;
                            }
                        }
                        .view-dealer {
                            flex: 1;
                            font-size: 10px;
                            line-height: 12px;
                            text-align: center;
                            letter-spacing: 1.16667px;
                            text-transform: uppercase;
                            background: none;
                            color: #161718;
                            border-radius: 4px;
                            padding: 12px 20px;
                            border: 2px solid #878787;
                            box-sizing: border-box;
                            display: inline-block;
                            transition: color, border .3s var(--ease-in-out-sine);
                            @media (--medium-and-small-viewport) {
                                width: 100%;
                                margin: 0;
                            }
                            &:hover, &:focus {
                                color: #161718D0;
                                border: 2px solid #A1A1A1;
                            }
                        }
                        .opening-hours {
                            transition: opacity, height .3s ease;
                            margin-bottom: 32px;
                            cursor: pointer;
                            height: 18px;
                            overflow: hidden;
                            position: relative;
                            display: inline-block;
                            width: 100%;
                            @media (--normal-and-large-viewport) {
                                /* min-width: 75%; */
                            }
                            .tick {
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                transform: scaleY(-1);
                                background: none;
                            }
                            .paper {
                                height: auto;
                            }
                            .folded {
                                display: block;
                            }
                            .unfolded {
                                display: none;
                            }
                            &.active {
                                .tick {
                                    transform: scaleY(1);
                                }
                                .folded {
                                    display: none;
                                }
                                .unfolded {
                                    display: block;
                                }
                            }
                            &.hidden {
                                display: none;
                            }
                        }
                    }

                    /* &.active {
                        height: 380px;
                        @media (--medium-and-small-viewport) {
                            height: auto;
                            padding-top: 25vh;
                        }
                        .col2 {
                            .title .tick svg {
                                transform: scaleY(-1);
                            }
                        }
                    } */

                    &.selected {
                        .col1 {
                            color: #A77F5A;
                        }
                    }

                }

                .row-loadmore {
                    display: flex;
                    width: 100%;
                    height: 70px;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    padding: 18px;
                    button {
                        cursor: pointer;
                    }
                }

                &.empty, &.all-loaded {
                    .row-loadmore {
                        display: none;
                    }
                }

                &.dealer-active {
                    @media (--normal-and-large-viewport) {
                        .row-result {
                            &.active {
                                transition: height .3s ease;
                                /*height: auto;*/
                                .col2 {
                                    .title .tick svg {
                                        transform: scaleY(-1);
                                    }
                                }
                            }
                        }
                    }
                    @media (--medium-and-small-viewport) {
                        margin-top: 0;
                        .row-search {
                            display: none;
                        }
                        .row-result {
                            display: none;
                            &.active {
                                border-bottom: none;
                                display: block;
                                /*height: auto;*/
                                .col2 {
                                    .title .tick svg {
                                        transform: scaleY(-1);
                                    }
                                }
                            }
                        }
                        .row-loadmore {
                            display: none;
                        }
                    }
                }

            }
        }
        [data-enquire-step='2'] {
            padding-bottom: 54px;
        }
        [data-enquire-step='3'] {
            padding-bottom: 54px;
        }
        [data-enquire-step='4'] {
            padding-top: 108px;
            padding-bottom: 54px;
        }
        
        .link {
            line-height: 26px;
            border-bottom: 2px solid #A1A1A1D0;
            background: none;
            text-transform: uppercase;
            color: #161718;
            letter-spacing: 2px;
            padding-bottom: 3px;
            transition: border-bottom .3s var(--ease-in-out-sine);
            &:hover, &:focus {
                color: #161718D0;
                border-bottom: 2px solid #A1A1A1A0;
                svg path {
                    stroke: #161718D0;
                }
            }

            svg path {
                stroke: #161718;
            }
        }

    }

}

.overlay-active {
    .header {
        opacity: 0;
    }
}

/* google autosuggest layer */
.pac-container {
    z-index: 1001;
}

/* bing autosuggest layer */
.overlay.enquire2--overlay,
.enquire2--findadealer {
  .MicrosoftMap {
    .as_container {
      &_search {
        width: 100%;
        top: 100%;
        left: 0;

        .asOuterContainer {
          border: 0;
        }
      }

      .suggestLink {
            padding: 8px 12px 8px 10px;
      }

      ul {
        padding: 0;

        li {
          border-bottom: 1px solid color(var(--lightstone) lightness(-5%));

          &:last-of-type {
            border-bottom: none;
          }
        }
      }

      .bingLogoContainer {
        opacity: .15;
      }
    }

    .as_lines_root {
      padding: 5px 10px;
      text-align: left;
    }

    .as_img.address {
      display: none;
    }

    .suggestLink:hover {
      background: color(var(--lightstone) lightness(-5%));
    }

    .line1, .line2 {
      display: inline-block;
      width: auto;
      line-height: 1;
    }

    .line1 {
      &::after {
        content: ', ';
      }
    }
  }
}


@media (--normal-and-large-viewport) {
    .amscrollbar::-webkit-scrollbar-track
    {
        background-color: #F5F5F5;
    }
    .amscrollbar::-webkit-scrollbar
    {
        width: 8px;
        background-color: #F5F5F5;
    }
    .amscrollbar::-webkit-scrollbar-thumb
    {
        background-color: rgba(0,0,0,0.1);
    }
}

body.noscroll {
    overflow: hidden;
    position: fixed;
}
