.form {

  @media (--medium-and-small-viewport) {

    .form__col.col {
      flex: 1 0 auto;
      width: 180px;
      margin: 0;

      .form__element.error {
        margin: 0 0 50px;
      }
    }
  }
  width: 100%;
  outline: none;

  &__row.row {

    @media (--medium-and-small-viewport) {
      flex-direction: column;
      margin-bottom: 1rem;

      .col {
        flex: 1 0 auto;

        .safari & {
          flex: 0 1 auto;
        }
      }
    }
    flex-wrap: nowrap;
    margin-bottom: 45px;
    text-align: left;

    &.hide {
      display: none;
    }
  }

  [dir='rtl'] &__row {

    .col {
      margin-left: 60px;
      margin-right: 0;

      &.col--12-12 {
        margin-left: 0;
      }

      &:nth-child(2n) {
        margin-left: 0;
      }
    }


    .body-copy__small-text,
    input {
      text-align: right;

    }

    .select {

      select {
        padding: 0 15px 0 0;
        direction: rtl;

      }

      .icon {
        left: 15px;
        right: initial;
      }
    }

    .checkbox {
      direction: ltr;
    }
  }

  fieldset {

    @media (--medium-and-small-viewport) {

      &.fieldset--no-spacing {
        padding-top: 0;
        margin-bottom: 0;
      }
    }
    padding-top: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid color(var(--form-main-color) a(20%));

    &:last-of-type {
      border: 0;
    }
  }

  &__element,
  &__element-group {

    @media (--medium-and-small-viewport) {
      margin-bottom: var(--baseSize);
    }
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--form-main-color);
    border-radius: 2px;

    &.form__element--no-border {
      border: 0;
    }

    .error-msg {

      @util position(absolute, calc(100% + 10px), null, null, 15px);

      @media (--medium-and-small-viewport) {
        top: calc(100% + 10px);
      }
      display: none;
      z-index: 10;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;

      [dir='rtl'] & {
        left: auto;
        text-align: right;
      }
    }
  }

  &__element {

    &.error {

      @media (--medium-and-small-viewport) {
        margin-bottom: 30px;
      }
      border: 2px solid var(--form-main-color);

      .error-msg {
        display: block;
      }
    }

    &.hide {
      display: none;
    }

    &[data-form-element='ContactViaEmail'].error,
    &[data-form-element='ContactViaSms'].error,
    &[data-form-element='ContactViaPhone'].error {
      border: 0;

      .icon {
        border: 2px solid var(--form-main-color);
      }

      .error-msg {
        @util position(absolute, calc(100% + 10px), null, null, 0);

        @media (--medium-and-small-viewport) {
          top: calc(100% + 10px);
        }
      }
    }
  }

  .select {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    &__mimic {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-start;
      padding: 0 45px 0 15px;
      opacity: 0;
      letter-spacing: 1px;
      text-transform: uppercase;
      
      .touch & {

        @media (--medium-and-small-viewport) {
          display: block;
          max-width: calc(100vw - (100vw * 2 / 12 - (10px - 10px / 12)));
          overflow: hidden;
          font-size: 16px;
          text-overflow: ellipsis;
          line-height: 40px;
          white-space: nowrap;
        }
        opacity: 1;
      }

      .touch &--inactive {
        opacity: .4;
      }

      .touch[dir='rtl'] & {
        opacity: 0;
      }

    }

    &__list {
      display: none;

      &.active {

        @util position(absolute, 100%, null, null, 0);
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        z-index: 2;
        border: 1px solid var(--form-main-color);
        border-radius: 0 0 4px 4px;
        background: var(--midgray);
      }

      li {

        @util size(100%, 40px);
        padding: 15px;
        color: var(--form-text);

        &:hover {
          background-color: color(var(--form-main-color) a(20%));
        }
      }
    }

    .icon {

      @util size(18px);
      position: absolute;
      top: calc(50% + 2px);
      right: 15px;
      opacity: 1;
      transform: translateY(-50%);
      transition: opacity .3s;

      &.hidden {
        opacity: 0;
      }
    }

    select {

      @util size(100%);
      position: absolute;
      display: block;
      height: 36px;
      top: 0;
      left: 0;
      padding: 0 0 0 15px;
      text-transform: uppercase;
      line-height: 40px;
      color: var(--form-text);
      appearance: none;

      .touch:not(.safari) & {
        padding-top: 5px;
      }

      .touch & {
        @media (--medium-and-small-viewport) {
          font-size: 16px;
          color: var(--form-text);
          appearance: normal;
        }
      }

      .mac-os & {
        /* stylelint-disable */
        padding-top: 0 !important;
        /* stylelint-enable */
      }
    }

    option {
      font-size: 16px;
      color: var(--midgray);
      background: var(--form-main-color);
    }

    .touch & {

      select,
      option {
        display: flex;
        opacity: 0;
        appearance: initial;
      }
    }

    .touch[dir='rtl'] & {

      select {
        display: flex;
        padding-top: 0;
        opacity: 1;
        appearance: initial;
      }
    }
  }

  select,
  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='search'] {
    display: block;
    flex: 1 0 auto;
    width: 100%;
    height: 36px;
    padding: 0 15px;
    margin: 0;
    font-size: 10px;
    line-height: 1;
    color: var(--form-text);
    letter-spacing: 1px;
    cursor: pointer;
    border: none;
    border-radius: 0;
    background: transparent;
    box-sizing: border-box;

    &::placeholder {
      line-height: 36px;
      color: color(var(--form-text) a(40%));
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid color(var(--form-main-color) a(80%));
    }

    &::-ms-clear,
    &::-ms-expand {
      display: none;
    }

    .touch &,
    .touch &::placeholder {
      padding-top: 5px;
      font-size: 16px;
    }

    .mac-os &,
    .mac-os &::placeholder {
      padding-top: 4px;
      line-height: 40px;
    }

    .safari &,
    .safari &::placeholder {
      padding-top: 0;
      line-height: 40px;
    }

    .mac-os.ff &,
    .mac-os.ff &::placeholder {
      padding-top: 0;
      line-height: 40px;
    }
  }

  .checkbox {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: 40px;
    cursor: pointer;

    label {

      @media (--medium-and-small-viewport) {
        flex-basis: 80%;
      }
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 14px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
      flex-shrink: 1;
      order: 3;
      user-select: none;
    }

    .icon {

      @util size(40px);
      display: flex;
      flex: 0 0 40px;
      margin-left: -40px;
      border: 1px solid var(--form-main-color);
      border-radius: 2px;
      order: 2;
    }

    .icon__wrapper {

      @util center;

      @util size(16px);
      display: none;
    }

    input[type='checkbox'] {
      position: absolute;
      top: 50%;
      left: 0;
      margin-right: 0;
      transform: translate(0, -50%);
    }
  }

  input[type='checkbox'] {

    @util size(40px);
    display: flex;
    margin-right: -40px;
    z-index: 10;
    opacity: .01;
    cursor: pointer;
    appearance: none;
    order: 1;
    -webkit-tap-highlight-color: color(var(--form-main-color) a(0%));

    &:checked ~ .icon .icon__wrapper {
      display: block;
    }

    &:focus ~ span {
      border-bottom: 3px solid var(--form-main-color);
    }
  }

  .button {
    @media (--medium-and-small-viewport) {
      width: 100%;

      .mobile-safari & {
        display: block;
        margin: 0 auto;
      }
    }

    @media (--medium-viewport) {
      max-width: 340px;
    }
  }

  &--login {

    @media (--medium-and-small-viewport) {

      .checkbox.row {
        justify-content: center;

        label {
          flex-basis: auto;
        }
      }
    }
    max-width: 760px;

    button.button {
      min-width: 160px;
      color: var(--form-text);
    }

    input[type='text'],
      input[type='password'] {
        &::placeholder {
          text-transform: none;
        }
    }
  }

  &__textarea {
    display: block;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
    height: 180px;
    min-height: 180px;
    max-height: 180px;
    padding: 15px;
    margin: 0;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1;
    color: var(--form-text);
    letter-spacing: 1px;
    border: none;
    border-radius: 0;
    background: transparent;
    box-sizing: border-box;

    &::placeholder {
      line-height: 36px;
      color: color(var(--form-main-color) a(40%));
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid color(var(--form-main-color) a(80%));
    }
  }
}
