/* stylelint-disable color-no-hex, color-named, max-line-length */

:root {
  --white: #fff;
  --black: #161a11;
  --black10: rgba(0, 0, 0, 0.1);
  --black20: rgba(0, 0, 0, 0.2);
  --black70: rgba(0, 0, 0, 0.7);
  --black90: rgba(0, 0, 0, 0.9);
  --blackFooter: #0E1116;
  --whiteFooter: var(--white);
  --almostBlack: #080B11;
  --lightgrey: #f9f9f9;
  --whisperGrey: #e5e5e5;
  --offWhite: #f8f7f4;
  --offWhite95: rgba(248, 247, 244, 0.95);
  --offWhite30: rgba(248, 247, 244, 0.3);
  --offWhite20: rgba(248, 247, 244, 0.2);
  --offWhite1: #f7f8f4;
  --darkGrey: #161a21;
  --darkGray: #262626;
  --darkGrey95: rgba(22, 26, 33, 0.95);
  --darkGrey90: rgba(22, 26, 33, 0.9);
  --darkGrey70: rgba(22, 26, 33, 0.7);
  --darkGrey60: rgba(22, 26, 33, 0.6);
  --darkGrey30: rgba(22, 26, 33, 0.3);
  --darkGrey20: rgba(22, 26, 33, 0.2);
  --darkGrey15: rgba(22, 26, 33, 0.15);  
  --darkGrey10: rgba(22, 26, 33, 0.10); 
  --darkGrey05: rgba(22, 26, 33, 0.05);
  --darkGrey00: rgba(22, 26, 33, 0);
  --white10: rgba(255, 255, 255, 0.1);
  --white20: rgba(255, 255, 255, 0.2);
  --white50: rgba(255, 255, 255, 0.5);
  --white60: rgba(255, 255, 255, 0.6);
  --white90: rgba(255, 255, 255, 0.9);
  --configuratorLightGray: #7b8086;
  --configuratorGray: #4c545e;
  --configuratorDarkenGray: #3c4049;
  --configuratorDarkGray: #161a21;
}
