.promo-block-short {
    &-block {
        /* dark theme */
      &.var-dark {
        background-color: var(--darkGrey);
      }
    }
    &-box {
      transform: translateY(40px);
      opacity: 0;
      display: inline-block;
      width: 100%;
      position: relative;
      height: 50vw;
      @media (--normal-viewport) {
        height: 50vw;
      }
      @media (--medium-and-small-viewport) {
        height: 50vw;
      }
      &--animate {
        transition: opacity 0.4s ease-in, transform 0.4s ease-in;
        opacity: 1;
        transform: translateY(0);
        &.with-delay {
          transition-delay: 0.7s;
          @media (--medium-and-small-viewport) {
            transition-delay: 0s;
          }
        }
      }
      &--reset {
        opacity: 1;
        transform: translateY(0);
      }
      &__img-box {
        display: block;
        height: 100%;
      }
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &__content {
        position: absolute;
        bottom: 32px;
        left: 40px;
        right: 40px;
        color: var(--offWhite);
        @media (--medium-and-small-viewport) {
          left: 20px;
          right: 20px;
        }
        .content {
          &-subtitle {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 12px;
            line-height: 1.46;
          }
          &-title {
            font-size: 36px;
            letter-spacing: 0.4px;
            margin-bottom: 12px;
            line-height: 1.4;
          }
        }
      }
    }
}
  