

.owner-guides.block-v2 {

  [results-container] {
    display: none;

    h4 {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .no-results {
      padding-top: 14px;
      padding-bottom: 14px;
      strong {
        font-weight: bold;
      }
    }
    .results-wrap {
      @media (--mobile-viewport) {
      overflow-x: scroll;
        &::-webkit-scrollbar {
          width: 0px;  /* Remove scrollbar space */
          background: transparent;  /* Optional: just make scrollbar invisible */
        }
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      thead td {
        font-weight: bold;
        border-bottom: 1px solid var(--darkGrey);
      }
      tr {
        border-bottom: 1px solid black;
      }
      td {
        line-height: 2;
        border-bottom: 1px solid var(--darkGrey20);
        padding-top: 14px;
        padding-bottom: 14px;
      }
      td:not(:first-child) {
        padding-left: 32px;
        min-width: 20%;
        @media (--mobile-viewport) {
          padding-left: 8px;
          text-align: right;
        }
      }
      td:last-child {
        text-align: right;
        @media (--mobile-viewport) {
          display: none;
        }
      }
    }
    .label {
      font-weight: bold;
      color: var(--darkGrey);
      line-height: 2;
    }
    a {
      color: var(--darkGrey);
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      margin-right: 0px;
      vertical-align: text-bottom;
      display: inline-block;
      &.rev {
        margin-left: 0px;
        margin-right: 10px;
        svg {
          transform: scaleX(-1);
        }
      }
    }
  }

  [search-container] {

    color: var(--darkGrey);
    
    h4 {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .body-copy {
      @media (--tablet-and-desktop-viewport) {
        padding-bottom: 200px;
      }
    }

    .form-element {
      margin-bottom: 32px;
      @media (--large-viewport) {
        max-width: 66%;
      }
    }

    .form-select {
      position: relative;
      select {
        height: 32px;
        width: 100%;
        padding-bottom: 5px;
        padding-right: 20px;
        border: 0;
        border-radius: 0;
        font-size: 16px;
        color: var(--text-dark);
        box-sizing: border-box;
        appearance: none;
        border-bottom: 1px solid var(--input-border-grey);
        background-color: transparent;
        /* IE fix */
        &::-ms-expand {
          display: none;
        }
      }
      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 32px;
        width: 14px;
        color: var(--text-dark);
        pointer-events: none;
      }
    }
    button span {
      font-weight: bold;
      line-height: 1.25;
    }
    label {
      font-weight: bold;
    }
    picture {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 61%;
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.lazyloading {
        }

        &.lazyloaded {
        }
      }
    }

  }

}
