.explore-cur {
    cursor:  url('../images/nav/explore-en.png') 40.5 40.5, url('../images/nav/explore-en.cur') 40.5 40.5, pointer;

    :root[lang=fr] & {
        cursor:  url('../images/nav/explore-fr.png') 40.5 40.5, url('../images/nav/explore-fr.cur') 40.5 40.5, pointer;
    }
    :root[lang=de] & {
        cursor:  url('../images/nav/explore-de.png') 40.5 40.5, url('../images/nav/explore-de.cur') 40.5 40.5, pointer;
    }
    :root[lang=ja] & {
        cursor:  url('../images/nav/explore-ja.png') 40.5 40.5, url('../images/nav/explore-ja.cur') 40.5 40.5, pointer;
    }
    :root[lang=zh-CN] & {
        cursor:  url('../images/nav/explore-zh-CN.png') 40.5 40.5, url('../images/nav/explore-zh-CN.cur') 40.5 40.5, pointer;
    }
}

