:root {
  --zi-models-header-blur: 1;
  --zi-models-header-text: 5;
  --zi-models-header-box: 6;
  --models-header-desktop-nav-offset: 100px;
  --zi-header-buttons: 15;
}

.sp-header-banner {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--models-header-desktop-nav-offset);

  .model-background,
  .picture-cover,
  .full-bleed-background {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .model-background {
    position: fixed;
    z-index: 0;
  }

  .picture-cover {
    position: absolute;
    opacity: 0;
    transition: opacity 400ms ease-in-out 300ms;
    will-change: opacity;
    transform: translateZ(0);

    &.active {
      opacity: 1;
      transition: all 600ms ease-in-out;
    }
  }

  .full-bleed-background {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .header-content {
    height: 100%;
  }


  .header-text {
    position: relative;
    height: 100%;
    width: 100%;
    color: var(--lightgrey);
    z-index: var(--zi-models-header-text);
    overflow: hidden;
    padding-bottom: 15px;
    
    .small-header-text {
      font-size: 16px;
      line-height: 30px;
      font-weight: 700;

      @media (--up-to-large-viewport) {
        font-size: 16px;
        line-height: 30px;
        font-weight: 700;
      }
    }

    .big-header-text {
      font-family: Optima;
      font-style: normal;
      font-weight: normal;
      font-size: 80px;
      line-height: 80px;
      margin-top: 24px;

      @media (--up-to-large-viewport) {
        font-size: 58px;
        line-height: 58px;
        margin-top: 5px;

        @media (--medium-and-small-viewport) {
          margin-top: 8px;
        }
      }
      & > span {
        display: block;
        overflow: hidden;
        & > span {
          display: block;
          transform: translateY(100%);
          transition: transform 500ms ease-in-out 0.5s;
        }
        &:nth-child(2) {
          & > span {
            transition: transform 500ms ease-in-out 0.7s;
          }
        }
      }

      &.active {
        & > span > span {
          transform: translate(0);
        }
      }
    }
  }

  .home-slide {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: opacity 400ms ease-in-out 300ms;
    will-change: opacity;

    &.active {
      opacity: 1;
      transition: all 600ms ease-in-out;
    }

    &:not(.active) {
      user-select: none;
      pointer-events: none;
    }

    &--links__mobile {
      display: none;

      @media (--up-to-large-viewport) {
        display: flex;
        margin-top: 24px;
        opacity: 0;
        transition: opacity .3s ease-in-out 1.2s;
        pointer-events: none;

        .icon-link {
          margin-right: 32px;

          &:last-of-type {
            margin-right: 0;
          }

          &:hover {
            .icon-link__icon svg {
              fill: var(--offWhite);
            }
          }
          &__icon {
            background: rgba(255, 255, 255, 0.1);
          }

          &__content {
            font-family: GTAmerica;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: var(--offWhite);
          }
        }
      }
    }
    .video__cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  .home-slide.text-show {
    .model-background:after {
      opacity: 1;
    }
    .home-slide--links__mobile {
      opacity: 1;
      pointer-events: all;
    }
  }

  .model-background,
  .picture-cover,
  .full-bleed-background,
  .video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .model-background,
  .video {
    position: absolute;
    z-index: 0;
  }

  &.var-small .big-header-text {
    font-size: 58px;
    line-height: 1;
    letter-spacing: 0px;
    @media (--up-to-large-viewport) {
      font-size: 36px;
      line-height: 1.4;
      letter-spacing: 0.2px;
    }
  }

  &.var-short {
    @media (--tablet-viewport) {
      max-height: 50vw;
    }
    @media (--large-viewport) {
      max-height: 37.73vw;
    }
  }

}



.header-buttons {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: var(--zi-header-buttons);
}