.model-specs-block {
  color: var(--darkGrey);
  padding: 120px 145px;
  min-height: 100vh;
  @media (--medium-and-small-viewport) {
    padding: 80px 16px;
  }
  @media (--normal-viewport) {
    padding: 120px 40px;
  }

  &__heading {
    font-family: Optima;
    font-size: 58px;
    line-height: 100%;
    margin-bottom: 120px;

    @media (--medium-and-small-viewport) {
      font-size: 36px;
      line-height: 1.4;
      letter-spacing: 0.4px;
      margin-bottom: 60px;
    }
  }

  .spec-accordion {
    border-bottom: 1px solid;
    border-bottom-color: var(--darkGrey);
    margin-bottom: 24px;
    font-family: Optima; /* font-family: GTAmerica; */

    &__tab {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;

      @media (--medium-and-small-viewport) {
        font-size: 18px;
      }

      .optional-label {
        font-weight: normal;
        opacity: 0.7;
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      width: 14px;
      height: 14px;

      & svg {
        height: 100%;
        fill: var(--darkGrey);
      }
    }
    &__content {
      height: 0;
      overflow: hidden;
      transition: height 0.6s cubic-bezier(.39,.575,.565,1);
      padding-bottom: 0;

      .content-item {
        opacity: 0;
        transition: opacity .2s cubic-bezier(.39,.575,.565,1);

        .heading {
          font-weight: 600;
          font-size: 16px;
          line-height: 1.86;
          margin-bottom: 16px;
          padding-top: 64px;
        }
        .list {
          font-size: 16px;

          &__item {
            line-height: 1.86;
            margin-bottom: 16px;
          }
        }
      }
    }
    &--open {
      .spec-accordion {
        &__icon {
          transform: rotate(180deg);
        }
        &__content {
          /* height: auto; */
          transition: height 0.6s cubic-bezier(.39,.575,.565,1);
          padding-bottom: 64px;
          .content-item {
            opacity: 1;
            transition: opacity .4s cubic-bezier(.39,.575,.565,1) .6s;
          }
        }
      }
    }
  }
}

.var-dark {
  .model-specs-block {
    color: var(--offWhite);

    .spec-accordion {
      border-bottom-color: var(--offWhite20);

      &__content {
        color: var(--lightgrey);
      }
      &__icon {
        & svg {
          fill: var(--white);
        }
      }
    }
  }
}
