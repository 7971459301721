:root {
  --overlayDelay: .3s;
  --itemDelay: .15s;
  --transitionDelay: .9s;
  --footerDelay: .9s;
  --overlayZi: 1001;
}

.overlay {
  display: none;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: var(--overlayZi);
  transform: translate3d(0, -100%, 0);
  transition: transform .26s var(--ease-in-sine);
  @util position(fixed, 0, null, null, 0);

  .ie & {
    transform: translateY(-100%);
  }

  @media (width > 540px) and (width < 1024px) {
    transition-duration: .225s;
  }

  @media (width <= 540px) {
    transition-duration: .185s;
  }

  &[data-overlay-visible='true'] {
    display: block;
    will-change: transform;
  }

  &[data-overlay-quicksearch='false'] {

    .close-overlay {

      .icon-circle-close {
        display: none;
      }

      .icon-circle-arrow-up {
        display: block;
      }
    }
  }

  &[data-overlay-quicksearch='true'] {

    .close-overlay {

      .icon-circle-close {
        display: block;
      }

      .icon-circle-arrow-up {
        display: none;
      }
    }
  }

  &[data-overlay-active='true'] {
    transform: translate3d(0, 0, 0);
    transition: transform .375s var(--ease-out-sine);

    .ie & {
      transform: translateY(0);
    }

    @media (width > 540px) and (width < 1024px) {
      transition-duration: .275s;
    }

    @media (width <= 540px) {
      transition-duration: .225s;
    }

  }

  @each $name, $color in (var(--colorNames)), (var(--colorValues)) {

    &[data-overlay-color='$(name)'] {
      background-color: $color;
    }

    &[data-overlay-color='$(name)-light'] {
      background-color: color($color lightness(+10%));
    }

  }

  &__outer-wrapper {
    height: 100vh;
    overflow: auto;

    .touch & {
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
    }

  }

  &__inner-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  &__header {
    position: relative;

    .close-overlay {
      position: absolute;
      top: var(--gridGutter);
      right: var(--gridGutter);
      padding: 0;
      z-index: 10;
      color: var(--white);
      cursor: pointer;
      background: none;
      touch-action: manipulation;
      @util size(44px);

      @media (--medium-and-small-viewport) {
        top: var(--gridGutterMobile);
        right: 50%;
        transform: translate3d(50%, 0, 0);
        @util size(36px);

        .ie & {
          transform: translateY(50%);
        }
      }

    }

    .icon {
      @util size(100%);
    }

  }

  /* stylelint-disable declaration-no-important */
  &--no-transition {
    transition: none !important;

    &[data-overlay-items][data-overlay-active='true'],
    &.overlay--menu-opened li {
      transition-delay: 0s !important;
    }

  }
  /* stylelint-enable declaration-no-important */

  &[data-overlay='main-menu'],
  &[data-overlay='collection-menu'],
  &[data-overlay='subnavigation-menu'],
  &[data-overlay='subnav-dealers-menu-mobile'] {
    z-index: 999;

    ul {
      position: relative;
      padding: 45px 0 35px;
      font-size: responsive 28px 40px;
      font-range: 320px 1064px;
      text-align: center;
      text-transform: uppercase;
      line-height: responsive 28px 40px;
      pointer-events: none;
      line-height-range: 320px 1064px;

      a {
        display: inline-block;
        padding: 10px 0;
        color: var(--white);
        pointer-events: auto;
        transform: scale(1);
        transform-origin: center;
      }

      li {
        display: flex;
        justify-content: center;
        opacity: 0;
        transform: translate3d(0, -10vh, 0);
        transition: opacity var(--transitionDelay), transform var(--transitionDelay);
        transition-timing-function: ease, var(--menu-easing);

        .ie & {
          transform: translateY(-100px);
        }
      }

      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 12px;
        height: 1px;
        left: 50%;
        opacity: 0;
        background-color: var(--white);
        transform: translate3d(-50%, -10vh, 0);
        transition: opacity var(--transitionDelay), transform var(--transitionDelay);
        transition-timing-function: ease, var(--menu-easing);

        .ie & {
          transform: translateX(-50%) translateY(-100px);
        }
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

    }

    &[data-overlay-active='true'],
    &.overlay--menu-opened li {
      /* stylelint-disable declaration-no-important */
      transition-delay: 0s !important;
      /* stylelint-enable declaration-no-important */
    }

    &.overlay--menu-opened {

      ul {

        .no-touch &:hover li {
          opacity: .3;
          transition-duration: .3s;
          transition-delay: 0s;
        }

        .no-touch &:hover li:hover {
          opacity: 1;
          transition-duration: .3s;
          transition-delay: 0s;
        }

      }

    }

  }

  &[data-overlay='subnavigation-menu'],
  &[data-overlay='subnav-dealers-menu-mobile'] {

    ul {
      padding: 10px 0;
      font-size: responsive 24px 40px;
      font-range: 320px 1064px;

      &::before,
      &::after {
        content: none;
      }

      a {
        padding: 20px 0;
        letter-spacing: 2px;

        &.active::after {
          content: '';
          width: 100%;
          height: 3px;
          background: color(var(--white) a(20%));
          transition: width .6s var(--ease-out-expo);
          @util position(absolute, null, null, 5px, 0);
        }

      }

    }

    .close-overlay {
      padding: 5px;

      .icon {
        transform: rotate(180deg);
      }

    }

  }

}

.scrollbar-measure {
  position: absolute;
  width: 100px;
  height: 100px;
  overflow: scroll;
  transform: translateX(-100vw);
}
