@import '../base/_sizes.pcss';
@import '../base/_media.pcss';

.block {
  position: relative;
  display: block;
  box-sizing: border-box;

  &--full-width {
    @util size(100%, auto);
  }

  &--full-height {
    @util size(auto, 100%);
  }

  &--full-size {
    @util size(100%);
  }

  &--fs {
    width: 100%;
    height: 100vh;
    min-height: 500px;

    @media (width < 737px) and (orientation: portrait) {
      min-height: 480px;
    }

    @media (width < 737px) and (orientation: landscape) {
      min-height: 320px;
    }

  }

  &--hidden {
    display: none;
  }

  .no-touch &--visible-on-touch {
    display: none;
  }

  @media (--normal-viewport) {

    &--hidden-medium {
      display: none;
    }

    &--visible-medium {
      display: block;
    }
  }

  @media (--medium-and-small-viewport) {

    &--hidden-small {
      display: none;
    }

    &--visible-small {
      display: block;
    }
  }

  &--centred {
    margin-left: auto;
    margin-right: auto;
  }

  &--padding {
    padding: var(--baseSize);
  }

  &--hpadding {
    padding-left: var(--baseSize);
    padding-right: var(--baseSize);
  }

  &--vpadding {
    padding-top: var(--baseSize);
    padding-bottom: var(--baseSize);
  }

  &--hpadding-double {
    padding-left: calc(var(--baseSize) * 2);
    padding-right: calc(var(--baseSize) * 2);
  }

  &--vpadding-double {
    padding-top: calc(var(--baseSize) * 2);
    padding-bottom: calc(var(--baseSize) * 2);
  }

  &--margin {
    margin: var(--baseSize);
  }

  &--hmargin {
    margin-left: var(--baseSize);
    margin-right: var(--baseSize);
  }

  &--vmargin {
    margin-top: var(--baseSize);
    margin-bottom: var(--baseSize);
  }

  &--hmargin-double {
    margin-left: calc(var(--baseSize) * 2);
    margin-right: calc(var(--baseSize) * 2);
  }

  &--vmargin-double {
    margin-top: calc(var(--baseSize) * 2);
    margin-bottom: calc(var(--baseSize) * 2);
  }

  &--padding-half {
    padding: calc(var(--baseSize) / 2);
  }

  &--padding-quarter {
    padding: calc(var(--baseSize) / 4);
  }

  &--hpadding-half {
    padding-left: calc(var(--baseSize) / 2);
    padding-right: calc(var(--baseSize) / 2);
  }

  &--vpadding-half {
    padding-top: calc(var(--baseSize) / 2);
    padding-bottom: calc(var(--baseSize) / 2);
  }

  &--hpadding-quarter {
    padding-left: calc(var(--baseSize) / 4);
    padding-right: calc(var(--baseSize) / 4);
  }

  &--vpadding-quarter {
    padding-top: calc(var(--baseSize) / 4);
    padding-bottom: calc(var(--baseSize) / 4);
  }

  &--margin-half {
    margin: calc(var(--baseSize) / 2);
  }

  &--margin-quarter {
    margin: calc(var(--baseSize) / 4);
  }

  &--hmargin-half {
    margin-left: calc(var(--baseSize) / 2);
    margin-right: calc(var(--baseSize) / 2);
  }

  &--vmargin-half {
    margin-top: calc(var(--baseSize) / 2);
    margin-bottom: calc(var(--baseSize) / 2);
  }

  &--hmargin-quarter {
    margin-left: calc(var(--baseSize) / 4);
    margin-right: calc(var(--baseSize) / 4);
  }

  &--vmargin-quarter {
    margin-top: calc(var(--baseSize) / 4);
    margin-bottom: calc(var(--baseSize) / 4);
  }

  &--padding-bottom {
    padding-bottom: var(--baseSize);
  }

  &--padding-top {
    padding-top: var(--baseSize);
  }

  &--padding-left {
    padding-left: var(--baseSize);
  }

  &--padding-right {
    padding-right: var(--baseSize);
  }

  &--margin-bottom {
    margin-bottom: var(--baseSize);
  }

  &--margin-top {
    margin-top: var(--baseSize);
  }

  &--margin-left {
    margin-left: var(--baseSize);
  }

  &--margin-right {
    margin-right: var(--baseSize);
  }

  &--inline-block {
    display: inline-block;
  }

  &--overflow-hidden {
    overflow: hidden;
  }

  &--va-top {
    vertical-align: top;
  }

  &--va-middle {
    vertical-align: middle;
  }

  &--va-bottom {
    vertical-align: bottom;
  }

  @media all and (max-width: 600px) {

    &--content {
      padding-top: 4.666vw;
      padding-bottom: 4.666vw;
    }

    &--content-top {
      padding-top: 4.666vw;
    }

    &--content-bottom {
      padding-bottom: 4.666vw;
    }

  }

  @media all and (min-width: 601px) and (max-width: 800px) {

    &--content {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
    }

    &--content-top {
      padding-top: 4.625vw;
    }

    &--content-bottom {
      padding-bottom: 4.625vw;
    }

  }

  @media all and (min-width: 801px) and (max-width: 1200px) {

    &--content {
      padding-top: 4.583vw;
      padding-bottom: 4.583vw;
    }

    &--content-top {
      padding-top: 4.583vw;
    }

    &--content-bottom {
      padding-bottom: 4.583vw;
    }

  }

  @media all and (min-width: 1201px) and (max-width: 2399px) {

    &--content {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
    }

    &--content-top {
      padding-top: 4.625vw;
    }

    &--content-bottom {
      padding-bottom: 4.625vw;
    }

  }

  @media all and (min-width: 2400px) {

    &--content {
      padding-top: 4.583vw;
      padding-bottom: 4.583vw;
    }

    &--content-top {
      padding-top: 4.583vw;
    }

    &--content-bottom {
      padding-bottom: 4.583vw;
    }

  }

  @each $name, $color in (var(--colorNames)), (var(--colorValues)) {
    &--bg-$(name) {
      background-color: $color;
    }
  }

}
