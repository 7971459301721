.financial-services-block {
  background-color: var(--offWhite);
  padding: 80px 0 80px;
  color: var(--darkGrey);
  @media (--medium-and-small-viewport) {
    padding: 40px 0 60px;
  }

  .financial-services-section {
    padding: 125px 0 30px;
    @media (--up-to-large-viewport) {
      padding: 0 0 78px;
    }
    &__title {
      font-family: Optima; /* font-family: GTAmerica; */
      font-weight: 600;
      font-size: 16px;
      line-height: 1.86;
      margin-bottom: 52px;
    }
    &__heading {
      font-size: 58px;
      line-height: 100%;
      margin-bottom: 24px;
      transition: .3s;
      @media (--medium-and-small-viewport) {
        font-size: 36px;
        line-height: 1.4;
        letter-spacing: 0.4px;
        margin-bottom: 16px;
      }
    }
    &__sub-text {
      font-family: Optima; /* font-family: GTAmerica; */
      font-size: 12px;
      line-height: 1.4;
      margin-bottom: 36px;
      transition: .3s;
      @media (--medium-and-small-viewport) {
        font-size: 16px;
        line-height: 1.86;
      }
    }

    &__details {
      padding: 28px 0 52px;
      @media (--medium-and-small-viewport) {
        padding: 36px 0 30px;
      }
      .details {
        &__title {
          font-family: Optima; /* font-family: GTAmerica; */
          font-weight: 600;
          font-size: 16px;
          line-height: 1.86;
          margin-bottom: 26px;
        }
        &__section {
          display: none;
          transition: .3s;
          font-family: Optima; /* font-family: GTAmerica; */
          font-size: 16px;
          &-row:not(:last-child) {
            margin-bottom: 26px;
            @media (--medium-and-small-viewport) {
              margin-bottom: 22px;
            }
          }
          .detail-label {
            line-height: 1.86;
            @media (--medium-and-small-viewport) {
              font-weight: 500;
            }
          }
          .detail-value {
            line-height: 1.86;
            @media (--normal-and-large-viewport) {
              text-align: right;
            }
          }
          &.active {
            display: block;
          }
        }
      }
    }
  }
  .promo-section {
    &__container {
      position: relative;
      height: 100vh;
      min-height: 600px;
      @media (--medium-and-small-viewport) {
        width: calc(100% + 32px);
        margin-left: -16px;
        max-height: 800px;
      }
      @media (--large-viewport) {
        width: calc(100% + 40px);
      }
    }
    &__images {
      width: 100%;
      height: 100%;

      &.ie {
        background-size: cover;
        background-position: 50% 50%;
      }

      .promo-picture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: 0.6s;
        &.active {
          opacity: 1;
        }
        &__img {
          position: relative;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    &__content {
      position: absolute;
      top: 125px;
      left: 100px;
      right: 100px;
      color: var(--white);
      @media (--medium-and-small-viewport) {
        top: 40px;
        left: 30px;
        right: 30px;
      }

      .label {
        font-family: Optima; /* font-family: GTAmerica; */
        font-weight: 600;
        font-size: 16px;
        line-height: 186%;
        margin-bottom: 32px;
        @media (--medium-and-small-viewport) {
          margin-bottom: 5px;
        }
      }
      .promo-text {
        font-size: 58px;
        line-height: 100%;
        margin-bottom: 28px;
        max-width: 450px;
        transition: .3s;
        @media (--medium-and-small-viewport) {
          font-size: 36px;
          line-height: 1.4;
          margin-bottom: 20px;
          max-width: 300px;
          letter-spacing: 0.4px;
        }
      }
    }
  }
  &--hidden {
    .financial-services-section {
      &__heading {
        opacity: 0;
      }
      &__sub-text {
        opacity: 0;
      }
      .details__section {
        opacity: 0;
      }
    }
    .promo-section {
      .promo-text {
        opacity: 0;
      }
    }
  }
}
