
.overlay { 
  * {
    font-family: Optima,Times New Roman,Times,sans-serif;
  }

  .pillarbox {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
    margin: 0 auto;
    box-sizing: border-box;
  
    @media (--medium-and-small-viewport) {
      padding: 0 calc(99.9% / 12 - (10px - 10px / 12));
    }
  }

  h1 {
    font-size: 40px;
    line-height: 100px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 40px;
    text-transform: uppercase;
    line-height: calc(30 / 40);
  }

  h3 {
    font-size: 25px;
    text-transform: uppercase;
    line-height: calc(30 / 25);
  }

  label {
    font-size:12px;
  }

  .grid--wide .col--8-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% * 8/12 - (60px - 60px * 8/12));
    max-width: calc(100% * 8/12 - (60px - 60px * 8/12));
    width: calc(100% * 8/12 - (60px - 60px * 8/12));
    padding: 0;
  }

  .grid--wide .col--6-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% * 6/12 - (60px - 60px * 6/12));
    max-width: calc(100% * 6/12 - (60px - 60px * 6/12));
    width: calc(100% * 6/12 - (60px - 60px * 6/12));
    padding: 0;
  }

  .grid--wide .col--4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% * 1/4 - (60px - 60px * 1/4));
    max-width: calc(100% * 1/4 - (60px - 60px * 1/4));
    width: calc(100% * 1/4 - (60px - 60px * 1/4));
    padding: 0;
  }

  .grid--wide .col--4-12:nth-child(1n) {
    margin-right: 60px;
    margin-left: 0;
    @media (--medium-and-small-viewport) {
      margin-right: 0;
    }
  }

  .grid--wide .col--4-12:last-child {
    margin-right: 0;
  }

  .grid--wide .col--4-12 {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% * 4/12 - (60px - 60px * 4/12));
      max-width: calc(100% * 4/12 - (60px - 60px * 4/12));
      width: calc(100% * 4/12 - (60px - 60px * 4/12));
      padding: 0;
  }

  .grid--wide .col--2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% * 1/2 - (60px - 60px * 1/2));
    max-width: calc(100% * 1/2 - (60px - 60px * 1/2));
    width: calc(100% * 1/2 - (60px - 60px * 1/2));
    padding: 0;
  }

  @media (--medium-and-small-viewport) {
    .grid--wide [class*='col--'], .grid--no-gutter [class*='col--'], [class*='col--'] {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% * 1/1 - (10px - 10px * 1/1));
      max-width: calc(100% * 1/1 - (10px - 10px * 1/1));
      width: calc(100% * 1/1 - (10px - 10px * 1/1));
    }

    .form__row.row .col {
        flex: 1 0 auto;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .grid .row--centred {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 20px;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    letter-spacing: .1em;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    background: none;
    transition: background .225s cubic-bezier(.39, .575, .565, 1);
    box-sizing: border-box;
    background: rgb(135, 135, 135);
    
    &:hover:not(.button--disabled) {
      background: rgb(161, 161, 161);
    }
  }
}