.overlay[data-overlay='enquire'] {
  transform: translate3d(0, 100%, 0);

  .ie & {
    transform: translateY(100%);
  }

  &[data-overlay-active='true'] {
    transform: translate3d(0, 0, 0);

    .ie & {
      transform: translateY(0);
    }
  }

  &[data-overlay-active='false'] {
    transition: transform .375s var(--ease-in-sine);
  }

  .overlay__header {
    flex: 0 0 calc(var(--gridGutter) * 2 + 44px);

    @media (--medium-and-small-viewport) {
      flex: 0 1 calc(var(--gridGutterMobile) * 2 + 44px);
    }

  }

  .overlay__body {
    display: flex;
    flex-direction: column;

    &.success {
      flex: 1;

      .pillarbox {
        display: flex;
        flex: 1;
        padding-bottom: calc(var(--gridGutter) * 2 + 44px);

        @media (--medium-and-small-viewport) {
          padding-bottom: calc(var(--gridGutterMobile) * 2 + 88px);
        }

      }

    }

    @media (--medium-and-small-viewport) {
      flex: 0 1 auto;
    }

  }

  .enquire__form,
  .enquire__success {
    display: none;
    opacity: 0;
    transition: opacity .6s var(--ease-out-sine);

    &.show {
      display: block;
    }

    &.visible {
      opacity: 1;
      transition: opacity .7s var(--ease-out-sine);
    }

  }

}

.enquire {
  padding: var(--baseSize) 0;
  text-align: center;
  color: var(--white);

  @media (--medium-and-small-viewport) {
    padding-bottom: calc(var(--baseSize) * 3);

    &.col {
      flex: 1 0 auto;
    }

  }

  &__text {
    padding: 0 var(--baseSize);
    margin-bottom: 45px;

    .body-copy__text {
      margin-bottom: 10px;
    }

    @media (--medium-and-small-viewport) {
      padding: var(--baseSize) calc(var(--baseSize) / 2) 0;
      margin-bottom: 30px;
    }

  }

  .form {

    &__element,
    &__element-group {

      &.disabled {
        border-color: color(var(--white) a(20%));

        span {
          color: color(var(--white) a(20%));
        }

      }

    }

    &__element-group {
      display: flex;
      flex-direction: row;

      .form__element:nth-child(2) {

        .error-msg {
          text-align: right;
          @util position(absolute, calc(100% + 10px), 15px, null, null);
            [dir='rtl'] & {
            right: 0;
            }
        }

      }

      @media (--medium-and-small-viewport) {
        flex-direction: column;
        border: 0;

        .form__element:nth-child(2) {

          .error-msg {
            text-align: left;
            @util position(absolute, calc(100% + 3px), null, null, 15px);
                        [dir='rtl'] & {
            @util position(absolute, calc(100% + 10px), null, null, 15px);
              left: auto;
            }
          }

        }

      }

    }

    &__element-group .form__element {
      width: 50%;
      border: 0;

      @media (--medium-and-small-viewport) {
        width: 100%;
        border: 1px solid var(--white);
      }

    }

    &__element-group .form__element:last-of-type input,
    &__element-group .form__element:last-of-type input::placeholder {
      text-align: right;
         

      @media (--medium-and-small-viewport) {
        text-align: left;
         
        [dir='rtl'] & { 
          text-align: right;
        }
      }

    }

    &__element-group .form__element:last-of-type {

      @media (--medium-and-small-viewport) {
        margin-bottom: 0;

        &.error {
          margin-bottom: 10px;
        }

      }

    }

    &__element-group.error {
      border: 2px solid var(--white);

      @media (--medium-and-small-viewport) {
        border: 0;
      }

    }

    &__element-group .form__element.error {
      border: 0;

      @media (--medium-and-small-viewport) {
        border: 2px solid var(--white);
      }

    }

  }

  fieldset {

    a {
      position: relative;
      color: var(--white);
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 3px;
        bottom: -10px;
        left: 0;
        z-index: 1;
        background-color: color(var(--white) a(50%));
        transition: width .225s var(--ease-out-sine);
      }

      &::after {
        width: 0;
        z-index: 10;
        opacity: 1;
        background-color: color(var(--white) a(100%));
      }

      .no-touch &:hover {

        &::after {
          width: 100%;
        }

      }

    }

    @media (--medium-and-small-viewport) {

      &:nth-last-of-type(2) {
        margin-bottom: 45px;
        border-bottom: 1px solid color(var(--white) a(20%));
      }

    }

  }

  .select {
    flex-basis: 36px;
    height: 36px;
    font-size: 10px;

    span {
      color: color(var(--white) a(100%));
    }

  }

  &__success {

    .body-copy__small-title {
      margin-bottom: 6.6vw;
    }

    .body-copy__large-title {
      margin-bottom: 6.6vw;
      line-height: responsive 26px 75px;
      line-height-range: 375px 2025px;
    }

    @media (--medium-viewport) {

      .button {
        max-width: 340px;
      }

    }

    @media (--medium-and-small-viewport) {

      .button {
        width: 100%;
      }

    }

  }

  &--simple {
    padding: 0;

    .button {
      min-width: 164px;
    }

    .enquire__form,
    .enquire__success {
      display: none;
      opacity: 0;
      transition: opacity .6s var(--ease-out-sine);

      &.show {
        display: block;
      }

      &.visible {
        opacity: 1;
        transition: opacity .7s var(--ease-out-sine);
      }
    }

    .enquire__form {
      
      fieldset:first-of-type {
        padding-top: 0;
      }
    }
  
    @media (--normal-viewport) {

      &__container {
        flex-basis: calc(100% * 10 / 12 - (20px - 20px * 10 / 12));
        width: calc(100% * 10 / 12 - (20px - 20px * 10 / 12));
        max-width: calc(100% * 10 / 12 - (20px - 20px * 10 / 12));
      }
    }

    @media (--large-viewport) {
      
      &__container {
        flex-basis: calc(100% * 8 / 12 - (20px - 20px * 8 / 12));
        width: calc(100% * 8 / 12 - (20px - 20px * 8 / 12));
        max-width: calc(100% * 8 / 12 - (20px - 20px * 8 / 12));
      }
    }
  }
  
}
